<template>
  <v-container fluid>
    <v-row justify="end">
      <v-dialog
        v-if="$vuetify.breakpoint.mobile"
        ref="dialog"
        v-model="isFilterModalOpened"
        width="500"
      >
        <template v-slot:activator="{ on, attrs }">        
          <v-btn 
            fab 
            small 
            depressed 
            outlined 
            class="ma-3" 
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-filter</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-container>
            <v-row dense v-for="header in headers" :key="header.value">
              <v-col dense>
                <template v-if="header.value === 'isCompleted'">
                  <v-select
                    v-model="filterParams['isCompleted']"
                    :hint="header.text"
                    dense
                    class="text-no-wrap"
                    :items="completedItems"
                    item-text="label"
                    item-value="value"
                    persistent-hint
                  ></v-select>
                </template>
                <template v-else-if="header.filterable">
                  <v-text-field
                    dense
                    v-model="filterParams[header.value]"
                    :hint="header.text"
                    single-line
                    persistent-hint
                  ></v-text-field>
                </template>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="isFilterModalOpened=false"
            >
              閉じる
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-spacer></v-spacer>
      <v-col class="text-right">
        <v-btn to="/case/registration" class="mb-3" dark>投稿する</v-btn>
      </v-col>
    </v-row>
    <v-data-table
      ref="attributeTable"
      dense
      :loading="isCasesLoading"
      :headers="headers"
      :items="filteredCases"
      :page.sync="currentPage"
      :itemsPerPage="itemsPerPage"
      :sort-by.sync="sortBy"
      class="elevation-1"
      @click:row="openCaseDetail"
    >
      <template v-slot:body.prepend>
        <tr v-if="!$vuetify.breakpoint.mobile">
          <td v-for="header in headers" :key="header.value">
            <template v-if="header.value === 'isCompleted'">
              <v-select
                v-model="filterParams['isCompleted']"
                :hint="header.text"
                dense
                class="text-no-wrap"
                :items="completedItems"
                item-text="label"
                item-value="value"
                persistent-hint
              ></v-select>
            </template>
            <template v-else-if="header.filterable">
              <v-text-field
                dense
                v-model="filterParams[header.value]"
                :hint="header.text"
                single-line
                persistent-hint
              ></v-text-field>
            </template>
          </td>
        </tr>
      </template>
      <template v-slot:item.answerers="props">
        {{ getAnswerersLabel(props.item.answerers) }}
      </template>
      <template v-slot:item.isCompleted="props">
        <span
          :class="{
            'red--text': !props.item.isCompleted
          }"
        >
          {{ props.item.isCompleted ? '対応済' : '未対応' }}
        </span>
      </template>
      <template v-slot:item.updatedTime="props">
        {{ getElapsedTimeLabel(props.item.updatedTime) }}
      </template>
      <template v-slot:item.requester.fullName="props">
        {{ props.item.requester.fullName }}
      </template>
      <template v-slot:item.requester.hospitals="props">
        {{ getHospitalsLabel(props.item.requester.hospitals) }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import moment from 'moment';

export default {
  components: {
	},
  data: function(){
    return {
      isCasesLoading: false,
      headers: [
        {
          text: "ID", 
          value: "id", 
          width: 80,
          filterable: true,
          filter: value => !this.filterParams['id']?.length || value == this.filterParams['id'],
        },
        {
          text: "宛先", 
          value: "answerers",
          filterable: true,
          filter: value => !this.filterParams['answerers']?.length || this.getAnswerersLabel(value).indexOf(this.filterParams['answerers']) >= 0,
        },
        {
          text: "質問タイトル", 
          value: "title",
          filterable: true,
          filter: value => !this.filterParams['title']?.length || new String(value).indexOf(this.filterParams['title']) >= 0,
        },
        {
          text: "対応状況", 
          value: "isCompleted", 
          width: 100,
          filterable: true,
          filter: value => this.filterParams['isCompleted']==null || value == this.filterParams['isCompleted'],
        },
        {
          text: "質問者名", 
          value: "requester.fullName",
          filterable: true,
          filter: value => !this.filterParams['requester.fullName']?.length || new String(value).indexOf(this.filterParams['requester.fullName']) >= 0,
        },
        {
          text: "質問者所属", 
          value: "requester.hospitals",
          filterable: true,
          filter: value => !this.filterParams['requester.hospitals']?.length || this.getHospitalsLabel(value).indexOf(this.filterParams['requester.hospitals']) >= 0,
        },
        {text: "更新経過時間", value: "updatedTime"},
      ],
      filterParams: {},
      currentPage: 1,
      itemsPerPage: 100,
      currentDate: null,
      cases: [],
      sortBy: 'isCompleted',
      completedItems: [
        {value: null, label: ''},
        {value: false, label: '未対応'},
        {value: true, label: '対応済'},
      ],
      isFilterModalOpened: false,
    }
  },
  computed: {
    ...mapState([
      'hospitalItems',
    ]),
    filteredCases: function(){
      return this.cases;
    },
    getHospitalsLabel: function(){
      return (hospitals) => {
        return hospitals.map(h => h.shortName?.length ? h.shortName : h.name).join(', ');
      }
    },
    getAnswerersLabel: function(){
      return (answerers) => {
        return [...new Set(answerers.map(a => {
          return a.user?.fullName ?? a.hospital?.shortName
        }))].join(', ')
      }
    },
    getElapsedTime: function(){
      return (updateDatetime) => this.currentDate ? this.currentDate - moment(updateDatetime).toDate() : null;
    },
    getElapsedTimeLabel: function(){
      return (updateDatetime) => {
        let elapsedTime = this.getElapsedTime(updateDatetime)
        if(!elapsedTime){
          return null;
        }

        if(elapsedTime > 60*60*24*1000){
          return parseInt(elapsedTime/(60*60*24*1000)) + "d"
        }
        let elapsedHour = parseInt(elapsedTime/(60*60*1000));
        let elapsedMinutes = parseInt((elapsedTime - elapsedHour*60*60*1000)/(60*1000));
        return elapsedHour > 0 ? `${elapsedHour}h ${elapsedMinutes}m` : `${elapsedMinutes}m`;
      };
    },
  },
  mounted: function(){
    this.currentDate = new Date();
    this.isCasesLoading = true;
    this.axios.get('/api/cases').then(response => {
      this.cases = response.data.cases;
    }).catch(error => {
      console.log(error);
    }).finally(() => {
      this.isCasesLoading = false;
    });
  },
  methods: {
    openCaseDetail: function(selectedCase){
      this.$router.push(`/case/detail/${selectedCase.id}`)
    },
  },
}
</script>

<style scoped>
</style>