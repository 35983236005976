<template>
  <v-container fluid>
    <v-container>
      <v-row align="start" justify="center" dense>
        <v-col cols=12 md=8 dense>
          <v-card class="mb-3">
            <v-card-title class="text-subtitle-2">
              緊急度の高い症例は、まずお電話にてご相談ください。
            </v-card-title>
            <v-card-text>
              <v-row dense>
                <v-col 
                  cols="12" 
                  md="6"
                  lg="4"
                  v-for="hospitalItem in hospitalItems"
                  :key="hospitalItem.id"
                  dense
                  class="px-5"
                >
                  <v-row dense align="start" class="pb-2">
                    <v-col dense>
                      {{ hospitalItem.shortName }}
                    </v-col>
                    <v-col dense cols="auto">
                      <v-row dense align="center" justify="end">
                        <a :href="`tel:${hospitalItem.telephoneNumber}`">
                          {{ hospitalItem.telephoneNumber }}
                        </a>
                      </v-row>
                      <v-row dense justify="end" v-if="hospitalItem.extensionNumber">
                        （内線：{{ hospitalItem.extensionNumber }}）
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                ※ 東京心臓血管・内科クリニックは、受付時間内の対応となります。受付時間は<a href="https://shinzo-kekkan.clinic/">こちら</a>で確認ください。
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-form ref="form" lazy-validation>
        <v-row align="start" justify="center" dense>
          <v-col dense v-bind="captionColParams" >
            宛先
          </v-col>
          <v-col dense v-bind="valueColParams">
            <v-select
              dense
              v-model="caseData.to"
              :items="hospitalItems"
              item-text="shortName"
              item-value="id"
              multiple
              :rules="[required, notEmpty]"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row align="start" justify="center" dense>
          <v-col dense v-bind="captionColParams" >
            回答希望医師<br/>
            <p class="text-caption text--gray">※施設内の他医師が回答する可能性あり</p>
          </v-col>
          <v-col dense v-bind="valueColParams">
            <v-row dense v-for="hospitalId in caseData.to" :key="hospitalId">
              <v-select
                dense
                :label="getHopsitalShortName(hospitalId)"
                v-model="caseData.requestedAnswerer[hospitalId]"
                :items="getRespondersByHospitalId(hospitalId)"
                item-text="fullName"
                item-value="userId"
                :ref="`responserSelect-${hospitalId}`"
              >
                <template v-slot:prepend-item>
                  <v-list-item
                    class="text-caption text--gray"
                    @click="deselectAnswerer(hospitalId)"
                  >
                    選択解除
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-select>
            </v-row>
          </v-col>
        </v-row>
        <v-row align="start" justify="center" dense>
          <v-col dense v-bind="captionColParams" >
            タイトル
          </v-col>
          <v-col dense v-bind="valueColParams">
            <v-text-field
              dense
              v-model="caseData.title"
              :rules="[required]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row align="start" justify="center" dense>
          <v-col dense v-bind="detailCaptionColParams" >
            内容詳細
          </v-col>
          <v-col dense v-bind="detailColParams">
            <v-textarea
              dense
              class="label-wrapped"
              v-model="caseData.detail"
              label="病歴情報、CT、超音波、採血データ、など400文字まで入力可。"
              counter
              maxlength="400"
              full-width
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row align="center" justify="center" dense>
          <v-col cols=12 md=8 dense>
            <v-row align="center" justify="center" dense>
              <img
                v-if="attachedImage"
                style="width: 100%; height: auto;"
                :src="attachedImage"
                class="mb-3"
              />
              <v-btn @click="openImageSelection" outlined v-if="isMobile" class="mx-2">
                <v-icon class="mr-2">mdi-camera</v-icon>
                写真を{{ !!attachedImage ? '変更' : '撮る' }}
              </v-btn>
              <v-btn @click="openImageSelection" outlined class="mx-2">
                <v-icon class="mr-2">mdi-image</v-icon>
                画像を{{ !!attachedImage ? '変更' : '追加' }}
              </v-btn>
              <input
                ref="fileInput"
                class="d-none"
                type="file"
                accept="image/*,video/mp4,video/quicktime"
                @change="onFileChanged"
              >
              <v-btn v-if="attachedImage" @click="attachedImage = null" outlined class="mx-2">
                <v-icon class="mr-2">mdi-trash-can</v-icon>
                添付削除
              </v-btn>

              <FileEditor
                ref="fileEditor"
                :file="selectedFile"
                @confirm="setImage"
                @confirm-video="setVideo"
                @close="clearFile"
              />
            </v-row>
          </v-col>
        </v-row>
        <v-row class="text-caption text--gray" align="center" justify="center" dense>
          ※ ファイルの最大容量は32MBです。<br />
          ※ 画像は投稿1ヶ月後に自動削除されます。
        </v-row>
        <v-row class="my-5" align="center" justify="center" dense>
          <v-btn color="primary" :disabled="!isValid" @click="sendCase">
            投稿
          </v-btn>
        </v-row>
      </v-form>
    </v-container>

    <v-overlay :value="isLoading">
      <v-row class="mb-12" dense align="center" justify="center">
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-row>
      <v-row class="mx-6" dense justify="center">
        サイズが大きいファイルをアップロードする場合、時間を要する可能性があります。<br />
        アップロードが完了するまで、画面を閉じたり、遷移したりしないでください。
      </v-row>
    </v-overlay>
  </v-container>
</template>

<script>
import isMobile from 'ismobilejs'
import { mapState } from "vuex";

import FileEditor from '@components/inputs/FileEditor.vue';

export default {
  components: {
    FileEditor,
	},
  data: function(){
    return {
      captionColParams: {
        cols: 5,
        sm: 3,
        md: 2,
      },
      valueColParams: {
        cols: 7,
        sm: 9,
        md: 6,
      },
      detailCaptionColParams: {
        cols: 12,
        sm: 12,
        md: 2,
      },
      detailColParams: {
        cols: 12,
        sm: 12,
        md: 6,
      },
      required: value => !!value || "※必須",
      notEmpty: value => !value || value.length > 0 || "※必須",
      caseData: {
        urgency: null,
        to: [],
        title: null,
        requestedAnswerer: {},
        detail: null,
        file: null,
      },
      isMobile: false,
      isValid: false,

      responders: [],

      isLoading: false,
      selectedFile: null,
      attachedImage: null,
      isVideo: false,
      masks: [],
    }
  },
  computed: {
    ...mapState([
      'hospitalItems',
    ]),
    getHopsitalShortName: function(){
      return (id) => this.hospitalItems.find(i => i.id === id)?.shortName
    },
    getRespondersByHospitalId: function(){
      return hospitalId => this.responders.filter(r => r.hospitalId === hospitalId);
    }
  },
  mounted: function(){
    this.isMobile = isMobile.any;
    this.$nextTick(() => {
      this.$refs.form?.validate();
    });

    this.axios.get('/api/responders').then(response => {
      this.responders = response.data.responders;
    }).catch(error => {
      console.log(error);
    })
  },
  watch: {
    caseData: {
      handler: function(){
        this.$nextTick(() => {
          this.isValid = this.$refs.form?.validate();
        })
      },
      deep: true,
    },
    hospitalItems: {
      handler(newVal){
        this.caseData.to = newVal.map(h => h.id);
      },
      immediate: true,
    },
  },
  methods: {
    openImageSelection: function(){
      if(isMobile.any){
        //TODO カメラを起動
        return;
      }
      this.$refs.fileInput.click()
    },
    onFileChanged: function(e){
      this.selectedFile = e.target.files[0];
      if(this.selectedFile.size > 32000000) {
        this.selectedFile = null;
        alert('ファイルサイズが32MBを超えているため、アップロードできません。')
        return;
      }
      if(this.selectedFile){
        this.$refs.fileEditor.open();
        return;
      }
      this.attachedImage = null;
    },
    deselectAnswerer: function(hospitalId){
      this.caseData.requestedAnswerer[hospitalId] = null;
      this.$refs[`responserSelect-${hospitalId}`][0]?.blur();
      this.$forceUpdate();
    },
    sendCase: function(){
      if (this.isLoading) return;
      this.isLoading = true;

      if(this.isVideo){
        const params = new FormData();
        params.append('file', this.masks.file);
        params.append('masks', 
          JSON.stringify(this.masks.masks)
        );
        params.append('case', JSON.stringify({
          to: this.caseData.to,
          answerers: this.caseData.requestedAnswerer,
          title: this.caseData.title,
          detail: this.caseData.detail,
          image: this.attachedImage
        }));
        this.axios.post(
          `/api/case/video`,
          params,
          {
            headers: {
              'content-type': 'multipart/form-data',
            },
          }
        ).then((response) => {
          if(response.data.result == 'ok') {
            this.$router.push(`/case/created/${response.data.id}`);
          } else {
            alert('サーバにエラーが発生したため、動画アップロードに失敗しました。');
          }
        }).catch(error => {
          alert('サーバにエラーが発生したため、動画アップロードに失敗しました。');
          console.log(error);
        }).finally(() => {
          this.isLoading = false;
        });
        return;
      }
      this.axios.post('/api/case', {
        to: this.caseData.to,
        answerers: this.caseData.requestedAnswerer,
        title: this.caseData.title,
        detail: this.caseData.detail,
        image: this.attachedImage
      }).then(response => {
        this.$router.push(`/case/created/${response.data.id}`);
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.isLoading = false;
      });
    },
    setImage: function(image){
      this.attachedImage = image;
      this.isVideo = false;
    },
    setVideo: function({thumbnail, masks}){
      this.attachedImage = thumbnail;
      this.isVideo = true;
      this.masks = masks;
    },
    clearFile: function(){
      this.selectedFile = null;
      this.$refs.fileInput.value = null;
    },
  }
}
</script>

<style lang="scss" scoped>
.mask_canvas{
  position: absolute; 
  top: 0; 
  left: 0; 
  width: 100%; 
  height: 100%;
}
</style>