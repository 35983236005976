<template>
  <v-container fluid>
    <v-row align="center" justify="center" dense>
      <v-col md="6">
        <v-tabs
          v-model="currentTabIndex"
          dense
        >
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab
            v-for="tab in tabs"
            :key="tab.value"
          >
            {{ tab.label }}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <template v-if="currentTab.value === 'hosptial'">
      <v-row align="center" justify="center">
        <v-col md="6">
          <v-select
            v-model="targetHospitals"
            dense
            label="施設"
            class="text-no-wrap"
            :items="hospitalItems"
            item-text="shortName"
            item-value="id"
            multiple
          ></v-select>
        </v-col>
      </v-row>
      <v-row dense align="center" justify="center" v-if="targetHospitals && targetHospitals.length > 0">
        <v-col dense md="6">
          <v-select
            dense
            v-for="hospital in targetHospitals"
            :key="hospital"
            v-model="targetDepartments[hospital]"
            :label="`${getHopsitalShortName(hospital)} 所属部署`"
            class="text-no-wrap"
            :items="departmentItems.filter(i => i.hospitalId == hospital)"
            item-text="name"
            item-value="id"
            multiple
            @change="updateDepartments"
          ></v-select>
        </v-col>
      </v-row>
    </template>
    <template v-if="currentTab.value==='worker'">
      <v-row dense align="center" justify="center">
        <v-col md="6" dense>
          <v-row class="mt-1" justify="end">
            <v-btn @click="checkAllWorkers">全選択</v-btn>
            <v-btn class="ml-5" @click="uncheckAllWorkers">全解除</v-btn>
          </v-row>
          <v-combobox
            v-model="targetWorkers"
            :items="allianceUsers"
            label="医師"
            item-text="fullName"
            item-value="id"
            multiple
            chips
          ></v-combobox>
        </v-col>
      </v-row>
    </template>
    <v-row dense align="center" justify="center">
      <v-col md="6" dense>
        <v-row dense>
          <v-col cols="6">
            <DateSelector
              v-model="dateFrom"
              label="開始日"
            />
          </v-col>
          <v-col cols="6">
            <DateSelector
              v-model="dateTo"
              label="終了日"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row dense align="center" justify="center">
      <v-col md="6" dense>
        <v-select
          v-model="targetDays"
          dense
          label="曜日"
          class="text-no-wrap"
          :items="dayOptions"
          item-text="label"
          item-value="value"
          multiple
        ></v-select>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row dense align="center" justify="center">
      <v-col md="6" dense>
        <v-row dense justify="end">
          <v-btn class="mt-5" small @click="downloadWorkingTime">
            xlsxダウンロード
            <v-icon small>mdi-download</v-icon>
          </v-btn>
        </v-row>
        <v-row dense>
          <v-col cols="6">
            <PieChart
              height="auto"
              :chart-data="chartData"
              :options="chartOptions"
            />
          </v-col>
          <v-col cols="6">
            <PieChart
              height="auto"
              :chart-data="subChartData"
              :options="subChartOptions"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    
    <v-row align="center" justify="center">
      <v-col md="6" dense>
        <v-row dense class="font-weight-bold">
          <v-col cols="4">大カテゴリ</v-col>
          <v-col cols="4">中カテゴリ</v-col>
          <v-col cols="4" class="text-right">利用時間</v-col>
        </v-row>
        <v-row dense v-for="task in sortedFilteringWokingTime" :key="task.id" :class="getTaskColor(task.id)">
          <v-col cols="4">{{ getTaskName(task.id) }}</v-col>
          <v-col cols="4">{{ getSubTaskName(task.id) }}</v-col>
          <v-col cols="4" class="text-right">{{ getTimeStr(task.time) }}</v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="mt-10" dense align="center" justify="center">
      <v-col md="6" dense>
         <v-row dense class="font-weight-bold">
          <v-col cols="8">勤務形態</v-col>
          <v-col cols="4" class="text-right">利用回数</v-col>
        </v-row>
        <v-row dense v-for="arrangement in sortedWokingArrangements" :key="arrangement.id">
          <v-col cols="8">{{ getWorkingArrangementName(arrangement.id) }}</v-col>
          <v-col cols="4" class="text-right">{{ arrangement.time }}</v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="mt-10" dense align="center" justify="center">
      <v-col md="6" dense>
         <v-row dense class="font-weight-bold">
          <v-col cols="8">病棟外来業務（外勤含む）の内訳</v-col>
          <v-col cols="4" class="text-right">利用時間</v-col>
        </v-row>
        <v-row dense v-for="officeWorking in sortedOfficeWokings" :key="officeWorking.id">
          <v-col cols="8">{{ getOfficeWorkingName(officeWorking.id) }}</v-col>
          <v-col cols="4" class="text-right">{{ getTimeStr(officeWorking.time) }}</v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- TODO: 一旦非表示に -->
    <!-- <template v-if="currentTab.value==='worker'">
      <v-row class="mt-5" align="center" justify="center">
        <v-col md="6" dense>
          <v-row dense class="font-weight-bold">
            個人別勤務時間
          </v-row>
          <v-row dense v-for="worker in targetWorkers" :key="worker.id">
            <v-col cols="4">{{ worker.fullName }}</v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-divider class="my-5"></v-divider>
      </v-row>
      <v-row class="mt-5" align="center" justify="center">
        <v-col md="6" dense>
          <v-row dense class="font-weight-bold">
            個人別今月の残勤務時間
          </v-row>
          <v-row dense v-for="worker in targetWorkers" :key="worker.id">
            <v-col cols="4">{{ worker.fullName }}</v-col>
            <v-col cols="8">XX時間XX分</v-col>
          </v-row>
        </v-col>
      </v-row>
    </template> -->
  </v-container>
</template>

<script>
import { mapState } from "vuex";

import { getDateStr } from '@js/utils/StringUtil.js';

import DateSelector from '@components/inputs/DateSelector.vue';
import PieChart from "@components/parts/charts/PieChart.vue";

import { saveAs } from "file-saver";

export default {
  components: {
    DateSelector,
    PieChart,
	},
  data: () => ({
    tabs: [
      {value: 'hosptial', label: '施設サマリー'},
      {value: 'worker', label: '個人サマリー'},
    ],
    currentTabIndex: 0,
    targetHospitals: [],
    targetDepartments: {},
    targetDays: [],
    targetWorkers: [],
    dateFrom: null,
    dateTo: null,
    dayOptions: [
      {value: 0, label: "月"},
      {value: 1, label: "火"},
      {value: 2, label: "水"},
      {value: 3, label: "木"},
      {value: 4, label: "金"},
      {value: 5, label: "土"},
      {value: 6, label: "日"},
    ],

    chartData: {
      datasets: [],
      labels: [],
    },
    chartOptions: {
      responsive: true,
      responsiveAnimationDuration: 0,
      maintainAspectRatio: false,
      layout: {
        padding: 0
      },
      title: {
        display: true,
        position: "top",
        fontSize: 12,
        padding: 10,
        text: "大カテゴリ",
      },
      legend: {
        display: false,
        position: "bottom",
      },
      tooltips: {
        display: true,
      }
    },
    subChartData: {
      datasets: [],
      labels: [],
    },
    subChartOptions: {
      responsive: true,
      responsiveAnimationDuration: 0,
      maintainAspectRatio: false,
      layout: {
        padding: 0
      },
      title: {
        display: true,
        position: "top",
        fontSize: 12,
        padding: 10,
        text: "中カテゴリ",
      },
      legend: {
        display: false,
        position: "bottom",
      },
      tooltips: {
        display: true,
      }
    },
    subSubChartData: {
      datasets: [],
      labels: [],
    },
    summary: {
      tasks: [],
      workingArrangements: [],
      officeWorkings: [],
    },
    subSubChartOptions: {
      responsive: true,
      responsiveAnimationDuration: 0,
      maintainAspectRatio: false,
      layout: {
        padding: 0
      },
      title: {
        display: true,
        position: "top",
        fontSize: 12,
        padding: 10,
        text: "小カテゴリ",
      },
      legend: {
        display: false,
        position: "bottom",
      },
      tooltips: {
        display: true,
      }
    },
  }),
  computed: {
    ...mapState([
      'user',
      'hospitalItems',
      'departmentItems',
      'allianceUsers',
      'taskCategoryItems',
      'taskSubCategoryItems',
      'taskSubSubCategoryItems',
      'workingArrangements',
      'officeWorkings',
    ]),
    getHopsitalShortName: function(){
      return (id) => this.hospitalItems.find(i => i.id === id)?.shortName
    },
    currentTab: function(){
      return this.tabs[this.currentTabIndex];
    },
    getTaskName: function(){
      return subTaskId => this.taskCategoryItems.find(_i => _i.id === this.getSubTask(subTaskId)?.parent)?.caption
    },
    getTaskColor: function(){
      return subTaskId => this.taskCategoryItems.find(_i => _i.id === this.getSubTask(subTaskId)?.parent)?.color + '--text'
    },
    getSubTask: function(){
      return subTaskId => this.taskSubCategoryItems.find(i => i.id === subTaskId)
    },
    getSubTaskName: function(){
      return subTaskId => this.getSubTask(subTaskId)?.caption
    },
    getSubSubTask: function(){
      return subSubTaskId => this.taskSubSubCategoryItems.find(i => i.id === subSubTaskId)
    },
    getSubSubTaskName: function(){
      return subSubTaskId => this.getSubSubTask(subSubTaskId)?.caption
    },
    getTimeStr: function(){
      return time => {
        let hour = parseInt(time/60);
        let minues = parseInt((time - hour*60)%60);
        return hour > 0 ? `${hour}時間${minues}分` : `${minues}分`;
      }
    },
    // 病院と部署のどちらもwatchするために設定
    getHospitalDepartmentInfo: function() {
      return [this.hospitalItems, this.departmentItems];
    },
    // 検索条件をまとめてwatchするために設定
    // hospitalはdepartmentのON/OFFが必要になるので、個別管理
    getSearchCondition: function() {
      return [this.dateFrom, this.dateTo, this.targetDays, this.targetWorkers];
    },
    sortedFilteringWokingTime: function(){
      let rtn = [...this.summary.tasks];
      rtn.sort((a, b) => a.time > b.time ? -1 : 1);
      return rtn;
    },
    getWorkingArrangementName: function(){
      return workingArrangementId => this.workingArrangements.find(i => i.id === workingArrangementId).title
    },
    sortedWokingArrangements: function(){
      let rtn = [...this.summary.workingArrangements];
      rtn.sort((a, b) => a.time > b.time ? -1 : 1);
      return rtn;
    },
    getOfficeWorkingName: function(){
      return officeWorkingId => this.officeWorkings.find(i => i.id === officeWorkingId).title
    },
    sortedOfficeWokings: function(){
      let rtn = [...this.summary.officeWorkings];
      rtn.sort((a, b) => a.time > b.time ? -1 : 1);
      return rtn;
    },
  },
  watch: {
    // 初期値として、全ての施設情報のチェックをONに
    getHospitalDepartmentInfo: function(newVal){
      if (newVal) {
        this.checkAllHosptails();
      }
    },
    getSearchCondition: function(newVal, oldVal) {
      if(newVal !== oldVal){
        this.getAttendanceSummaryUnderCondition();
      }
    },
    targetHospitals: function(newVal, oldVal) {
      if(newVal !== oldVal){
        let addedId  = newVal.filter(i => oldVal.indexOf(i) == -1);
        let deletedId  = oldVal.filter(i => newVal.indexOf(i) == -1);
        if(addedId.length > 0) this.checkAllDepartments(addedId[0]);
        if(deletedId.length > 0) this.targetDepartments[deletedId] = [];
        this.getAttendanceSummaryUnderCondition();
      }
    },
    allianceUsers: function(newVal) {
      if(newVal){
        this.targetWorkers = [this.allianceUsers.find(i => i.id === this.user.id)]
      }
    },
    currentTabIndex: function(newVal, oldVal) {
      if(newVal !== oldVal){
        this.getAttendanceSummaryUnderCondition();
      }
    },
  },
  mounted: function(){
    this.targetDays = this.dayOptions.map(o => o.value)
    let dateFrom = new Date();
    dateFrom.setDate(1);
    this.dateFrom = getDateStr(dateFrom);
    
    let dateTo = new Date();
    dateTo.setMonth(dateTo.getMonth() + 1);
    dateTo.setDate(0);
    this.dateTo = getDateStr(dateTo);
    this.checkAllHosptails();
    this.targetWorkers = [this.allianceUsers.find(i => i.id === this.user.id)]
  },
  methods: {
    checkAllHosptails: function() {
      this.targetHospitals = this.hospitalItems.map(element => {
        this.checkAllDepartments(element.id)
        return element.id
      });
    },
    checkAllDepartments: function(hospitalId) {
      this.targetDepartments[hospitalId] = []
      this.departmentItems.forEach(element =>{
        if (element.hospitalId === hospitalId) {
          this.targetDepartments[hospitalId].push(element.id);
        }
      });
    },
    checkAllWorkers: function() {
      this.targetWorkers = this.allianceUsers
    },
    uncheckAllWorkers: function() {
      this.targetWorkers = [];
    },
    getAttendanceSummaryUnderCondition: function() {
      let conditions = this.createConditionInfo()
      let endpoint = "/api/attendance_hospital_summary"
      if (this.currentTab.value=="worker") {
        endpoint = "/api/attendance_user_summary";
      }

      this.axios.post(endpoint, conditions).then(response => {
        // 勤務形態登録
        this.summary.workingArrangements = this.createTable(
          response.data.workingArrangement, this.workingArrangements
        );
        // 事務作業登録
        this.summary.officeWorkings = this.createTable(
          response.data.officeWorking, this.officeWorkings
        );

        this.summary.tasks = [];
        if(Object.keys(response.data.category).length == 0) {
          this.chartData = {
            datasets: [],
            labels: [],
          };
          this.subChartData = {
            datasets: [],
            labels: [],
          };
          this.subSubChartData = {
            datasets: [],
            labels: [],
          };
          return;
        }
        // 大カテゴリ
        this.chartData = this.createGraph(
          response.data.category, this.taskCategoryItems, true
        );
        // 中カテゴリ
        this.subChartData = this.createGraph(
          response.data.subCategory, this.taskSubCategoryItems
        );

        this.summary.tasks = this.createTable(
          response.data.subCategory, this.taskSubCategoryItems
        );

      }).catch(error => {
        console.log(error);
      });
    },
    createConditionInfo: function() {
      let conditions = {
        dateFrom: Date.parse(this.dateFrom),
        dateTo: Date.parse(this.dateTo),
        days: this.targetDays,
      }
      if (this.currentTab.value=="hosptial") {
        conditions["hospitals"] = this.targetHospitals
        conditions["departments"] = this.targetDepartments
      } else {
        conditions["workers"] = this.targetWorkers.map(element => {
          return element.id
        });
      }
      return conditions;
    },
    createGraph: function(categoryKind, categoryItems, hasCategoryColor=false) {
      let categoryIds = Object.keys(categoryKind);
      let chartDataLabels = [];
      let chartDataValue = [];
      let chartDataColor = [];

      for (let categoryId of categoryIds) {
        let category = this.getCategory(categoryItems, categoryId);
        let idx = chartDataLabels.indexOf(category.caption)
        let value = categoryKind[categoryId]
        if (idx == -1){
          chartDataLabels.push(category.caption);
          chartDataValue.push(value);
          if (hasCategoryColor) {
            chartDataColor.push(category.colorHexadecimal);
          }
          continue;
        }
        chartDataValue[idx] += value;
      }
      chartDataValue = this.getChartDataValue(chartDataValue);

      let chartDataBG = hasCategoryColor ? chartDataColor : this.createChartDataBG(chartDataLabels.length)
      return this.createDataset(chartDataLabels, chartDataValue, chartDataBG);
    },
    createTable: function(categoryWorkingTime, categoryItems) {
      let categoryIds = Object.keys(categoryWorkingTime);
      let chartDataIds = [];
      let chartDataLabels = [];
      let chartDataValue = {};

      for (let categoryId of categoryIds) {
        let category = this.getCategory(categoryItems, categoryId);
        let caption = (category.caption)? category.caption:category.title;
        let idx = chartDataLabels.indexOf(caption);
        let value = categoryWorkingTime[categoryId];
        if (idx == -1){
          chartDataIds.push(categoryId)
          chartDataLabels.push(caption);
          chartDataValue[categoryId] = value;
          continue;
        }
        chartDataValue[chartDataIds[idx]] += value;
      }
      let tableResult = []
      for (let key in chartDataValue) {
        tableResult.push({
          "id": parseInt(key, 10),
          "time": chartDataValue[key],
        });
      }
      return tableResult;
    },
    getCategory: function(categoryKind, id) {
      return categoryKind.find(i => i.id == id)
    },
    getChartDataValue: function(eachValue) {
      let chartDataValueSum = eachValue.reduce((acc, cur) => acc + cur);
      eachValue = eachValue.map(element => {
        return Math.round(element / chartDataValueSum * 1000) / 10
      });
      return eachValue;
    },
    createChartDataBG: function(lengthBG) {
      let chartDataBG = []
      for (let i = 0; i < lengthBG; i++) {
        chartDataBG.push("rgb(" +
          Math.floor(Math.random() * 256) + ", " +
          Math.floor(Math.random() * 256) + ", " +
          Math.floor(Math.random() * 256) + ")"
        );
      }
      return chartDataBG;
    },
    createDataset: function(labels,values, colors) {
      return {
        datasets: [
          {
            backgroundColor: colors,
            borderColor: "#FFFFFF",
            borderWidth: 1,
            data: values,
          }
        ],
        labels: labels,
      }
    },
    updateDepartments: function() {
      this.$forceUpdate();
      this.getAttendanceSummaryUnderCondition();
    },
    downloadWorkingTime: function() {
      let conditions = this.createConditionInfo()
      let endpoint = "/api/download/attendance_hospital_summary/xlsx"
      if (this.currentTab.value=="worker") {
        endpoint = "/api/download/attendance_user_summary/xlsx";
      }

      this.axios.post(
        endpoint, conditions, {responseType: "blob"}
      ).then(response => {
        const blob = new Blob([response.data], {
          type: response.data.type
        });

        let fileName = `勤怠サマリ.xlsx`;
        saveAs(blob, fileName);
      });
    },
  }
}
</script>