<template>
  <v-container fluid>
    <v-row dense align="center" justify="center" class="mt-2">
      <v-col dense cols="5" sm="3" md="2">
        <v-chip 
          :outlined="isCompleted"
          :dark="!isCompleted" 
          :color="isCompleted ? 'black' : 'red'" 
          class="mr-1"
        >
          {{ isCompleted ? '対応済' : '未対応' }}
        </v-chip>
      </v-col>
      <v-col dense cols="7" sm="9" md="6" class="text-right">
        <div class="text-h6 ml-auto font-weight-bold">{{ title }}</div>
      </v-col>
    </v-row>
    <v-row dense align="center" justify="center" class="mt-2" v-if="isPublic || answerers.length == 0">
      <v-col dense cols="5" sm="3" md="2">
        宛先
      </v-col>
      <v-col dense cols="7" sm="9" md="6" class="text-right">
        <div class="text-h6">{{ to }}</div>
      </v-col>
    </v-row>
    <v-row dense align="center" justify="center" class="my-0" v-if="answerers.length > 0 && !isPublic">
      <v-col dense cols="5" sm="3" md="2">
        回答希望医師
      </v-col>
      <v-col dense cols="7" sm="9" md="6" class="text-right">
        <div class="text-h6">{{ requestedAnswerers }}</div>
      </v-col>
    </v-row>
    <v-row dense align="center" justify="center" class="mb-2" v-if="isAnswerer">
      <v-col dense cols="12" md="8">
        <v-row dense align="center" justify="end">
          <span class="mx-3">宛先病院内 {{ isPublic ? '公開' : '非公開' }}</span>
          <v-dialog
            v-model="isCaseOpennessModalOpened"
            width="500"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                {{ isPublic ? '非公開にする' : '公開する' }}
              </v-btn>
            </template>
            <v-card>
              <v-card-text class="text-left pt-5">
                <template v-if="isPublic">
                  非公開にすると、質問者と回答希望医師に指定されている方しか閲覧できなくなります。
                </template>
                <template v-else>
                  公開すると、質問者・回答希望医師の他に、宛先で指定されている病院内のアカウント全てで閲覧可能になります。
                </template>
              </v-card-text>
              <v-card-actions class="justify-center py-5">
                <v-btn class="mx-5" @click.stop="isCaseOpennessModalOpened=false">
                  キャンセル
                </v-btn>
                <v-btn class="mx-5" color="primary" @click.stop="switchCaseOpenness">
                  {{ isPublic ? '非公開にする' : '公開する' }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-col>
    </v-row>
    <v-row dense align="center" justify="center">
      <v-col v-bind="cardColParams">
        <template v-for="(content, index) in contents">
          <v-card 
            class="mb-1"
            :class="{
              'ml-5': !content.isQuestioner,
              'mr-5': content.isQuestioner,
            }"
            :color="content.isPublic ? 'white' : '#aaa'"
            :key="`content-${index}`"
            v-observe-visibility="(isVisible) => isVisible ? readCaseContent(index) : false"
          >
            <v-btn
              v-if="content.user.id === user.id && !content.isQuestioner && !isQuestionerInAlliance"
              small
              color="primary"
              class="mt-1 ml-1"
              @click.stop="openCaseContentOpennessModal(content)"
            >
              <v-icon class="mr-2">
                {{ content.isPublic ? 'mdi-account-voice' : 'mdi-account-voice-off' }}
              </v-icon>
              質問者：{{ content.isPublic ? '公開' : '非公開' }}
            </v-btn>
            <v-card-title 
              :class="{
                'flex-row-reverse': !content.isQuestioner,
                'pt-0': content.user.id === user.id && !content.isQuestioner,
              }"
            >
              <v-icon 
                large
                v-bind="{
                  left: content.isQuestioner,
                  right: !content.isQuestioner,
                }"
              >
                mdi-account-circle
              </v-icon>
              {{ content.user.name }}
            </v-card-title>
            <v-card-subtitle class="text-caption pb-2" :class="{'text-right': !content.isQuestioner}">{{ content.user.affiliation }}</v-card-subtitle>
            <v-card-text class="pb-2">
              <p style="white-space: pre-wrap;" class="text--primary mb-1" v-text="content.content"></p>
              <v-row dense align="end">
                <v-dialog
                  v-if="content.readers.length > 0"
                  v-model="content.isReadersModalOpened"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      dense
                      small
                      class="my-1"
                      v-bind="attrs"
                      v-on="on"
                    >
                      既読 {{ content.readers.length }}
                    </v-btn>
                  </template>
                  <v-card>
                    <v-data-table
                      :items="content.readers"
                      :headers="readersHeaders"
                    >
                      <template v-slot:item.hospitals="props">
                        {{ props.item.hospitals.map(h => h.shortName ? h.shortName : h.name).join(', ') }}
                      </template>
                      <template v-slot:item.telephoneNumber="props">
                        <a :href="`tel:${props.item.telephoneNumber}`">{{ props.item.telephoneNumber }}</a>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-dialog>
                <v-spacer/>
                {{ content.datetime }}
              </v-row>
            </v-card-text>
          </v-card>
          
          <CaseContentImage
            v-if="content.imagePath"
            :case-id="content.caseId"
            :case-content-id="content.caseContentId"
            :is-questioner="content.isQuestioner"
            :image="content.imagePath"
            :key="`content-image-${index}`"
            :has-video="content.hasVideo"
          />
        </template>

        <v-textarea
          class="my-3 label-wrapped"
          dense
          v-model="caseContent.content"
          label="コメントを入力してください。"
          counter
          maxlength="400"
          full-width
        />
        <v-row align="center" justify="center" dense>
          <v-col cols=12 md=8 dense>
            <v-row align="center" justify="center" dense>
              <img
                v-if="attachedImage"
                style="width: 100%; height: auto;"
                :src="attachedImage"
                class="mb-3"
              />
              <v-btn @click="openImageSelection" outlined v-if="isMobile" class="mx-2">
                <v-icon class="mr-2">mdi-camera</v-icon>
                写真を{{ !!attachedImage ? '変更' : '撮る' }}
              </v-btn>
              <v-btn @click="openImageSelection" outlined class="mx-2">
                <v-icon class="mr-2">mdi-image</v-icon>
                画像を{{ !!attachedImage ? '変更' : '追加' }}
              </v-btn>
              <input
                ref="fileInput"
                class="d-none"
                type="file"
                accept="image/*,video/mp4,video/quicktime"
                @change="onFileChanged"
              >
              <v-btn v-if="attachedImage" @click="attachedImage = null" outlined class="mx-2">
                <v-icon class="mr-2">mdi-trash-can</v-icon>
                添付削除
              </v-btn>

              <FileEditor
                ref="fileEditor"
                :file="selectedFile"
                @confirm="setImage"
                @confirm-video="setVideo"
                @close="clearFile"
              />
            </v-row>
          </v-col>
        </v-row>
        <v-row class="text-caption text--gray" align="center" justify="center" dense>
          ※ ファイルの最大容量は32MBです。<br />
          ※ ファイルは投稿1ヶ月後に自動削除されます。
        </v-row>

        <v-row class="mt-5" align="center" justify="center" dense>
          <v-dialog
            v-model="isCaseContentRegistrationModalOpened"
            width="500"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                v-bind="attrs"
                v-on="on"
                :disabled="!isCaseContentSendable"
              >
                コメント送信
              </v-btn>
            </template>
            <v-card>
              <v-card-text class="text-left pt-5">
                <v-row dense>
                  以下の内容でコメントを送信しますか？
                </v-row>
                <v-row dense class="pa-3">
                  {{ caseContent.content }}
                </v-row>
                <v-row dense justify="center" v-if="!isUserQuestioner && !isQuestionerInAlliance">
                  <v-switch
                    v-model="caseContent.isPublic"
                    dense
                    label="アライアンス外に公開"
                    class="pb-0 mb-0"
                  >
                  </v-switch>
                </v-row>
              </v-card-text>
              <v-card-actions class="justify-center pb-5">
                <v-btn class="mx-5" @click="isCaseContentRegistrationModalOpened=false">
                  キャンセル
                </v-btn>
                <v-btn class="mx-5" color="primary" @click="sendCaseContent">
                  送信
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
        <v-row class="my-3" align="center" justify="center" dense>
          <v-btn
            :color="isCompleted ? 'primary' : 'red'" 
            dark 
            @click="switchCaseStatus"
          >
            {{ isCompleted ? '未対応に戻す' : '対応済みにする' }}
          </v-btn>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog
      v-if="currentContent"
      v-model="isCaseContentOpennessModalOpened"
      width="500"
    >
      <v-card>
        <v-card-text class="text-left pt-5">
          <template v-if="currentContent.isPublic">
            非公開にすると、質問者は閲覧できずアライアンス内のみが閲覧可能です。
          </template>
          <template v-else>
            公開すると、質問者も閲覧可能になります。
          </template>
        </v-card-text>
        <v-card-actions class="justify-center py-5">
          <v-btn class="mx-5" @click.stop="isCaseContentOpennessModalOpened=false">
            キャンセル
          </v-btn>
          <v-btn class="mx-5" color="primary" @click.stop="switchCaseContentOpenness(currentContent)">
            {{ currentContent.isPublic ? '非公開にする' : '公開する' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay :value="isLoading">
      <v-row class="mb-12" dense align="center" justify="center">
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-row>
      <v-row class="mx-6" dense justify="center">
        サイズが大きいファイルをアップロードする場合、時間を要する可能性があります。<br />
        アップロードが完了するまで、画面を閉じたり、遷移したりしないでください。
      </v-row>
    </v-overlay>
  </v-container>
</template>

<script>
import isMobile from 'ismobilejs'
import CaseContentImage from '@components/parts/CaseContentImage.vue';
import { mapState } from "vuex";

import FileEditor from '@components/inputs/FileEditor.vue';

export default {
  components: {
    CaseContentImage,
    FileEditor,
	},
  props: {
    caseId: {
      type: Number,
    },
  },
  data: function(){
    return {
      isMobile: false,
      cardColParams: {
        cols: 12,
        sm: 10,
        md: 8,
      },
      currentCase: null,
      caseReaders: [],
      requester: null,
      answerers: [],
      hospitals: [],
      caseContents: [],

      caseContent: {
        content: null,
        isPublic: false,
      },

      isCaseOpennessModalOpened: false,
      isCaseContentOpennessModalOpened: false,
      isCaseContentRegistrationModalOpened: false,

      currentContent: null,

      selectedFile: null,
      attachedImage: null,
      readersHeaders: [
        {text: '所属施設', value: 'hospitals'},
        {text: '名前', value: 'fullName'},
        {text: '電話番号', value: 'telephoneNumber'},
      ],
      isVideo: false,
      isLoading: false,
      masks: [],
    }
  },
  computed: {
    ...mapState([
      'user',
    ]),
    title: function(){
      return this.currentCase?.title;
    },
    isCaseContentSendable: function(){
      return this.caseContent?.content?.length || this.attachedImage != null;
    },
    isUserQuestioner: function(){
      return this.user.id == this.requester?.id;
    },
    isQuestionerInAlliance: function(){
      return this.requester?.isAlliance;
    },
    contents: function(){
      let rtn = [];
      rtn.push({
        isQuestioner: true,
        user: {
          id: this.requester?.id,
          name: this.requester?.fullName,
          affiliation: this.requester?.hospitals?.map(h => h.shortName)?.join(', '),
        },
        content: this.currentCase?.detail,
        isPublic: true,
        imagePath: this.currentCase?.hasImage ? `/api/image/case/${this.currentCase.id}` : null,
        datetime: this.currentCase?.createdTime,
        readers: this.caseReaders,
        isAlreadRead: this.caseReaders.some(u => u.id == this.user.id),
        hasVideo: this.currentCase?.hasVideo,
        caseId: this.currentCase?.id,
      });
      rtn = rtn.concat(
        this.caseContents.map(c => {
          return {
            id: c.id,
            isQuestioner: c.userId == this.requester?.id,
            user: {
              id: c.user?.id,
              name: c.user?.fullName,
              affiliation: c.user?.hospitals?.map(h => h.shortName)?.join(', '),
            },
            content: c?.detail,
            isPublic: c?.isPublic || c.userId == this.requester?.id,
            imagePath: c?.hasImage && !!this.currentCase ? `/api/image/case/${this.currentCase.id}/${c.id}` : null,
            datetime: c?.createdTime,
            readers: c?.readers ?? [],
            isAlreadRead: c?.readers?.some(u => u.id == this.user.id),
            hasVideo: c?.hasVideo,
            caseId: this.currentCase?.id,
            caseContentId: c?.id,
          }
        })
      );
      rtn.sort((a, b) => a.datetime < b.datetime ? -1 : 1);
      return rtn;
    },
    isCompleted: function(){
      return this.currentCase?.isCompleted;
    },
    to: function(){
      let to = [...this.hospitals];
      to.sort((a, b) => a.shortName < b.shortName ? -1 : 1);
      return to.map(h => h.shortName).join(', ');
    },
    requestedAnswerers: function(){
      return this.answerers.map(a => a.fullName).join(', ');
    },
    isAnswerer: function(){
      return this.answerers.some(a => !!a.id && a.id === this.user?.id);
    },
    isPublic: function(){
      return this.currentCase?.isPublic;
    },
  },
  mounted: function(){
    this.isMobile = isMobile.any;
    this.axios.get(`/api/case/${this.caseId}`).then(response => {
      this.currentCase = response.data.case;
      this.hospitals = response.data.hospitals;
      this.requester = response.data.requester;
      this.answerers = response.data.answerers;
      this.caseReaders = response.data.readers;
    }).catch(error => {
      console.log(error);
    });

    this.loadCaseContents();
  },
  methods: {
    switchCaseOpenness: function(){
      this.axios.put(`/api/case_openness`, {
        isPublic: !this.isPublic,
        caseId: this.caseId
      }).then(response => {
        this.currentCase.isPublic = response.data.isPublic;
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.isCaseOpennessModalOpened=false
      });
    },
    loadCaseContents: function(){
      this.axios.get(`/api/case_contents/${this.caseId}`).then(response => {
        this.caseContents = response.data.contents;
      }).catch(error => {
        console.log(error);
      });
    },
    sendCaseContent: function(){
      if (this.isLoading) return;
      this.isLoading = true;
      this.isCaseContentRegistrationModalOpened = false;
      
      if(this.isVideo){
        const params = new FormData();
        params.append('file', this.masks.file);
        params.append('masks', 
          JSON.stringify(this.masks.masks)
        );
        params.append('case_content', JSON.stringify({
          caseId: this.caseId,
          detail: this.caseContent.content,
          isPublic: this.caseContent.isPublic,
          image: this.attachedImage
        }));
        this.axios.post(
          `/api/case_content/video`,
          params,
          {
            headers: {
              'content-type': 'multipart/form-data',
            },
          }
        ).then((response) => {
          if(response.data.result == 'ok') { 
            let content = response.data.content;
            this.caseContents.push(content);
            this.caseContent = {
              content: null,
              isPublic: false,
            };
            this.attachedImage = null;
          } else {
            alert('サーバにエラーが発生したため、動画アップロードに失敗しました。');
          }
        }).catch(error => {
          alert('サーバにエラーが発生したため、アップロードに失敗しました。');
          console.log(error);
        }).finally(() => {
          this.isLoading = false;
          this.isCaseContentRegistrationModalOpened = false;
        });
        return;
      }else{
        this.axios.post('/api/case_content', {
          caseId: this.caseId,
          detail: this.caseContent.content,
          isPublic: this.caseContent.isPublic,
          image: this.attachedImage
        }).then(response => {
          let content = response.data.content;
          this.caseContents.push(content);
          this.caseContent = {
            content: null,
            isPublic: false,
          };
          this.attachedImage = null;
        }).catch(error => {
          console.log(error);
        }).finally(() => {
          this.isLoading = false;
        });
      }
    },
    switchCaseContentOpenness: function(content){
      this.axios.put(`/api/case_content_openness/${content.id}`, {
        isPublic: !content.isPublic,
        caseId: this.caseId,
        contentId: content.id
      }).then(response => {
        content.isPublic = response.data.isPublic;
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.isCaseContentOpennessModalOpened=false
      }); 
    },
    openCaseContentOpennessModal: function(content){
      this.currentContent = content;
      this.isCaseContentOpennessModalOpened = true;
    },
    switchCaseStatus: function(){
      this.axios.put(`/api/case_status`, {
        isCompleted: !this.isCompleted,
        caseId: this.caseId
      }).then(response => {
        this.currentCase.isCompleted = response.data.isCompleted;
      }).catch(error => {
        console.log(error);
      });
    },
    setImage: function(image){
      this.attachedImage = image;
      this.isVideo = false;
    },
    setVideo: function({thumbnail, masks}){
      this.attachedImage = thumbnail;
      this.isVideo = true;
      this.masks = masks;
    },
    clearFile: function(){
      this.selectedFile = null;
      this.$refs.fileInput.value = null;
    },
    onFileChanged: function(e){
      this.selectedFile = e.target.files[0];
      if(this.selectedFile.size > 32000000) {
        this.selectedFile = null;
        alert('ファイルサイズが32MBを超えているため、アップロードできません。')
        return;
      }
      if(this.selectedFile){
        this.$refs.fileEditor.open();
        return;
      }
      this.attachedImage = null;
    },
    openImageSelection: function(){
      if(isMobile.any){
        //TODO カメラを起動
        return;
      }
      this.$refs.fileInput.click()
    },
    readCaseContent: function(index){
      if(!this.currentCase){
        return;
      }

      const content = this.contents[index];
      if(this.contents[index].isAlreadRead){
        return;
      }
      
      if(content.id){
        if(this.user.id == content.user.id){
          return;
        }

        this.axios.post(`/api/case_content/read`, {
          contentId: content.id
        }).then(response => {
          if(response.data.result){
            this.contents[index].isAlreadRead = true;
          }
        });
      }else{
        if(this.user.id == this.currentCase.userId){
          return;
        }

        this.axios.post(`/api/case/read`, {
          caseId: this.currentCase.id
        }).then(response => {
          if(response.data.result){
            this.contents[index].isAlreadRead = true;
          }
        });
      }
    },
  }
}
</script>

<style>
.v-chip {
  pointer-events: none;
}
</style>