<template>
  <v-data-table
    :items="users"
    :loading="isLoading"
    :headers="headers"
  >
    <template v-slot:item.hosptial="props">
      {{ props.item.departments.map(d => getHopsitalShortNameByDepartmentId(d.departmentId)).join(', ') }}
    </template>
    <template v-slot:item.affiliation="props">
      {{ props.item.departments.map(d => getDepartmentName(d.departmentId)).join(', ') }}
    </template>
    <template v-slot:item.availableTime="props">
      {{ props.item.availableTime.slice(5, -3) }}
    </template>
    <template v-slot:item.telephoneNumber="props">
      <a :href="`tel:${props.item.telephoneNumber}`">{{ props.item.telephoneNumber }}</a>
    </template>
    <template v-slot:item.comment="props">
      <p class="ma-0" style="white-space: pre-wrap;" v-text="props.item.comment"></p>
    </template>
  </v-data-table>
</template>

<script>
import { mapState } from "vuex";

export default {
  components: {
  },
  props: {
    users: {
      type: Array,
      required: true,
    },
    isLoading: {
      default: false,
    }
  },
  data: function(){
    return {
      headers: [
        {text: '所属施設', value: 'hosptial'},
        {text: '名前', value: 'fullName'},
//        {text: '所属部署', value: 'affiliation'},
        {text: '終了希望日時', value: 'availableTime'},
        {text: '電話番号', value: 'telephoneNumber'},
        {text: 'コメント', value: 'comment'},
      ]
    }
  },
  computed: {
    ...mapState([
      'hospitalItems',
      'departmentItems',
    ]),
    getHopsitalShortNameByDepartmentId: function(){
      return (departmentId) => this.hospitalItems.find(i => i.id === this.departmentItems.find(i => i.id === departmentId)?.hospitalId)?.shortName
    },
    getDepartmentName: function(){
      return (id) => this.departmentItems.find(i => i.id === id)?.name
    },
  },
}
</script>
