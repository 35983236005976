<template>
  <v-dialog
    v-model="isImageEditorDialogOpened"
    width="500"
    @click:outside="$emit('close')"
  >
    <v-card>
      <v-card-title class="text-h6">
        安全対策のため、以下の注意事項を必ずお守りく下さい。
      </v-card-title>

      <v-card-text>
        <v-row class="mb-2">
          <ol>
            <li>個人情報が入らないようにして下さい</li>
            <li>個人情報はモザイク機能で隠して下さい</li>
          </ol>
        </v-row>
        <v-row class="justify-start my-2 mr-0">
          <v-btn small class="ml-3" @click="clearMask">
            <v-icon>mdi-close-outline</v-icon>
          </v-btn>
          <v-btn-toggle
            v-model="canvasMode"
            class="ml-auto"
          >
            <v-btn small>
              <v-icon>mdi-pen</v-icon>
            </v-btn>
            <v-btn small>
              <v-icon>mdi-eraser</v-icon>
            </v-btn>
          </v-btn-toggle>
        </v-row>
        <div style="position: relative; display: flex;" class="my-3 pa-0">
          <template v-if="isVideo">
            <video 
              ref="video" 
              v-if="selectedFileBlobUrl && !isInvalidVideoType" 
              style="width: 100%; height: 100%;"
              @loadedmetadata="onLoadVideo"
              @timeupdate="onVideoTimeUpdate"
              preload
            >
              <source :src="selectedFileBlobUrl">
              Your browser does not support HTML5 video.
            </video>

            <div v-if="isInvalidVideoType">
              このビデオにブラウザが未対応です<br/>
              マスク機能は使用できません
            </div>
          </template>
          <img v-else ref="image" style="width: 100%; height: 100%;" :src="selectedFileBlobUrl" @load="onLoadImage"/>
          <canvas
            class="mask_canvas"
            ref="imageCanvas"
          />
          <canvas 
            ref="canvas"
            class="mask_canvas"
            v-if="!isInvalidVideoType"
            @mousedown.prevent="dragStart" 
            @touchstart.prevent="dragStart" 
            @mouseup.prevent="dragEnd" 
            @touchend.prevent="dragEnd"
            @mouseout="dragEnd" 
            @mousemove="draw"
            @touchmove="draw"
          ></canvas>
        </div>
        <template v-if="isVideo && player && !isInvalidVideoType">
          <div class="video-seek-container">
            <v-progress-linear 
              class="video-seek-bar"
              v-model="videoSeekPosition"
              height="10"
            ></v-progress-linear>
            <span 
              v-for="(videoMask, maskIndex) in videoMasks"
              :key="maskIndex"
              class="mask-marker"
              :style="{
                left: getMaskMarkerPosition(videoMask)
              }"
            >
            </span>
          </div>
          <v-row class="mt-2 px-3" align="center">
            <v-btn v-if="!isVideoPlaying" fab x-small @click="playVideo">
              <v-icon>mdi-play</v-icon>
            </v-btn>
            <v-btn v-if="isVideoPlaying" fab x-small @click="pauseVideo">
              <v-icon>mdi-pause</v-icon>
            </v-btn>

            <v-btn :disabled="currentMaskIndex < 0" class="ml-auto" small @click="deleteCurrentVideoMask">
              マスクキーフレーム削除
            </v-btn>
          </v-row>
        </template>
      </v-card-text>

      <v-card-actions class="text-center">
        <v-spacer/>
        <v-btn
          color="primary"
          @click="confirm"
        >
          添付する
        </v-btn>
        <v-spacer/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import muxjs from "mux.js";

export default {
  components: {
	},
  props: {
    file: {},
  },
  data: function(){
    return {
      isImageEditorDialogOpened: false,
      canvas: null,
      context: null,
      isDrawing: false,
      canvasWidth: 0,
      canvasHeight: 0,
      canvasMode: 0,
      selectedFileBlobUrl: null,

      isBackgroundInitialized: false,
      scale: 1,
      imageWidth: 0,
      imageHeight: 0,
      isVideo: false,
      player: null,
      videoCurrentTime: 0,
      videoDuration: 0,
      isVideoPlaying: false,

      videoMasks: [],
      isInvalidVideoType: false,
    }
  },
  computed: {
    videoSeekPosition: {
      get: function(){
        return this.videoDuration ? 100.0*this.videoCurrentTime/this.videoDuration : 0;
      },
      set: function(newVal){
        if(!this.player){
          return;
        }
        let currentTime = newVal*this.videoDuration/100;
        this.player.currentTime = currentTime;
        this.videoCurrentTime = currentTime;
      }
    },
    getMaskMarkerPosition: function(){
      return (mask) => {
        return 100.0*(mask.time/this.videoDuration) + "%";
      }
    },
    currentMaskIndex: function(){
      if(!this.isVideo){
        return -1;
      }

      let reversedIndex = [...this.videoMasks].reverse().findIndex(m => m.time <= this.videoCurrentTime);
      return reversedIndex >= 0 ? this.videoMasks.length - reversedIndex - 1 : - 1;
    },
  },
  watch: {
    isImageEditorDialogOpened: {
      handler: function(newVal){
        if(!newVal){
          this.$emit('close')
          this.isBackgroundInitialized = false;
          this.isInvalidVideoType = false;
          this.player?.pause();
        }
      },
      immediate: true,
    },
    currentMaskIndex: function(newVal){
      if(newVal < 0){
        this.context?.clearRect(0, 0, this.canvas.width, this.canvas.height);
        return;
      }
      if(this.videoMasks[newVal].image == null){
        return;
      }
      this.context.putImageData(this.videoMasks[newVal].image, 0, 0);
    },
    file: function(newVal){
      if(!newVal){
        return;
      }

      this.selectedFileBlobUrl = window.URL.createObjectURL(newVal);
      this.isVideo = newVal.type === "video/mp4" || newVal.type === "video/quicktime";
      if(this.isVideo){
        this.videoMasks = [];
        this.$nextTick(() => {
          this.$refs.video.load();
        })
        return;
      }
      /*
      if(newVal.type === "video/quicktime"){
        console.log(newVal);
        const params = new FormData();
        params.append('file', newVal);
        this.axios.post(
          `/api/video/convert`,
          params,
          {
            headers: {
              'content-type': 'multipart/form-data',
            },
          }
        ).then((response) => {
          console.log(response);
        }).catch(error => {
          console.log(error);
        }).finally(() => {
        });
        return;
      }
      */
    },
  },
  methods: {
    onLoadImage: function(){
      if(!this.file){
        return;
      }

      this.$nextTick(() => {
        let image = new Image();
        let reader = new FileReader();
        reader.readAsDataURL(this.file);
        reader.onload = () => {
          image.src = reader.result;
          image.onload = () => {
            this.canvas = this.$refs.canvas;
            this.imageWidth = image.naturalWidth ?? image.width;
            this.imageHeight = image.naturalHeight ?? image.height;

            this.scale = this.imageWidth/this.canvas.clientWidth;
            this.canvasWidth = this.canvas.clientWidth + "px";
            this.canvasHeight = this.canvas.clientHeight + "px";

            this.canvas.width = this.imageWidth;
            this.canvas.height = this.imageHeight;
            this.canvas.getContext('2d').scale(this.scale, this.scale);
            this.$nextTick(() => {
              let canvas = this.$refs.imageCanvas;
              let context = canvas.getContext('2d');
              canvas.width = this.imageWidth;
              canvas.height = this.imageHeight;
              context.scale(this.scale, this.scale);
              context.drawImage(
                image, 
                0, 
                0, 
                this.canvas.clientWidth, 
                this.canvas.clientHeight
              );
            });
          }
        }
      });
    },
    open: function(){
      this.isImageEditorDialogOpened = true;
    },
    draw :function(e) {
      if(!this.canvas){
        return;
      }
      var x = e.touches ? e.touches[0].clientX - this.canvas.getBoundingClientRect().left : e.layerX
      var y = e.touches ? e.touches[0].clientY - this.canvas.getBoundingClientRect().top : e.layerY

      if(!this.isDrawing) {
        return;
      }

      this.context.lineTo(x, y);
      this.context.stroke();
    },
    dragStart:function(e) {
      if(this.isVideo && this.player){
        this.player.pause();

        if(this.player.currentTime != this.videoMasks[this.currentMaskIndex]?.time){
          this.videoMasks.splice(
            this.currentMaskIndex + 1, 
            0, 
            {
              time: this.player.currentTime,
              image: null
            }
          )
        }
      }
      this.context = this.canvas.getContext('2d');
      this.context.lineCap = 'round';
      this.context.lineJoin = 'round';
      this.context.lineWidth = this.canvasMode == 0 ? 15 : 25;
      this.context.strokeStyle = '#000000';
      this.context.globalCompositeOperation = this.canvasMode == 0 ? 'source-over' : 'destination-out';

      this.context.closePath();

      var x = e.touches ? e.touches[0].clientX - this.canvas.getBoundingClientRect().left : e.layerX
      var y = e.touches ? e.touches[0].clientY - this.canvas.getBoundingClientRect().top : e.layerY
      this.context.beginPath();
      this.context.lineTo(x, y);
      this.context.stroke();


      this.isDrawing = true;
    },
    dragEnd: function() {
      this.context?.closePath();
      this.isDrawing = false;

      if(this.isVideo && this.videoMasks[this.currentMaskIndex]){
        this.videoMasks[this.currentMaskIndex].image = this.context.getImageData(0, 0, this.canvas.width, this.canvas.height);
      }
    },
    confirm: async function(){
      let image = await this.getImagefromCanvas(this.$refs.imageCanvas);
      let mask = await this.getImagefromCanvas(this.$refs.canvas);
      
      if(this.isVideo){
        let imageCanvas = this.$refs.imageCanvas;
        if(this.player){
          imageCanvas.width = this.player.clientWidth;
          imageCanvas.height = this.player.clientHeight;
          const ctx = imageCanvas.getContext("2d");
          ctx.drawImage(this.player, 0, 0, imageCanvas.width, imageCanvas.height);
          if(this.currentMaskIndex >= 0){
            ctx.drawImage(mask, 0, 0, imageCanvas.width, imageCanvas.height);
          }        
        }
        let output = await this.getImagefromCanvas(imageCanvas, "image/jpeg");
        let rtn = {
          file: this.file,
          masks: [],
        };
        const ctx = imageCanvas.getContext("2d");
        this.videoMasks.filter(
          m => !!m.image
        ).forEach(async (m) => {
          ctx.clearRect(0, 0, imageCanvas.width, imageCanvas.height);
          ctx.putImageData(m.image, 0, 0);
          let maskImage = await this.getImagefromCanvas(imageCanvas);
          rtn.masks.push({
            time: m.time,
            image: maskImage.src.split(';base64,')[1]
          })
        })

        this.$emit('confirm-video', {
          thumbnail: output.src,
          masks: rtn
        });
      }else{
        const ctx = this.$refs.imageCanvas.getContext("2d");
        ctx.drawImage(image, 0, 0, this.canvas.clientWidth, this.canvas.clientHeight);
        ctx.drawImage(mask, 0, 0, this.canvas.clientWidth, this.canvas.clientHeight);

        let output = await this.getImagefromCanvas(this.$refs.imageCanvas, "image/jpeg");
        this.$emit('confirm', output.src);
      }

      this.isImageEditorDialogOpened = false;
      this.selectedFileBlobUrl = null;
    },
    getImagefromCanvas: function(canvas, format=null){
      if(!canvas){
        return null;
      }
      return new Promise((resolve, reject) => {
        const image = new Image();
        const ctx = canvas.getContext("2d");
        image.onload = () => resolve(image);
        image.onerror = (e) => reject(e);
        image.src = ctx.canvas.toDataURL(format);
      });
    },
    playVideo: function(){
      if(!this.player){
        return;
      }
      this.player.play();
      this.isVideoPlaying = !this.player.paused;
    },
    pauseVideo: function(){
      if(!this.player){
        return;
      }
      this.player.pause();
      this.isVideoPlaying = !this.player.paused;
    },
    onLoadVideo: function(){
      if(!this.file){
        return;
      }
      let reader = new FileReader();
      reader.readAsArrayBuffer(this.file);
      reader.onloadend = (e) => {
        if (e.target.readyState == FileReader.DONE) {
          let array = new Uint8Array(e.target.result);

          let codec = muxjs.mp4.probe.tracks(array).find(t => t.type === 'video')?.codec;
          if(codec){
            this.isInvalidVideoType = this.$refs.video.canPlayType(`${this.file.type}; codecs="${codec}"`).length == 0;
          }
        }

        if(this.isInvalidVideoType){
          return;
        }

        this.player = this.$refs.video;

        let imageCanvas = this.$refs.imageCanvas;
        imageCanvas.getContext("2d").clearRect(0, 0, imageCanvas.clientWidth, imageCanvas.clientHeight);

        this.canvas = this.$refs.canvas;
        
        this.imageWidth = this.player.clientWidth;
        this.imageHeight = this.player.clientHeight;

        this.scale = this.imageWidth/this.canvas.clientWidth;
        this.canvasWidth = this.canvas.clientWidth + "px";
        this.canvasHeight = this.canvas.clientHeight + "px";

        this.canvas.width = this.imageWidth;
        this.canvas.height = this.imageHeight;
        this.canvas.getContext('2d').scale(this.scale, this.scale);

        this.player.play();
      };
    },
    onVideoTimeUpdate: function(){
      if(!this.player){
				return;
			}

      this.videoCurrentTime = this.player.currentTime;
      this.videoDuration = this.player.duration;

      this.isVideoPlaying = !this.player.paused;
    },
    clearMask: function(){
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);

      if(this.isVideo && this.player){
        this.player.pause();
        this.videoMasks.splice(
          this.currentMaskIndex + 1, 
          0, 
          {
            time: this.player.currentTime,
            image: this.context.getImageData(0, 0, this.canvas.width, this.canvas.height)
          }
        )
      }
    },
    deleteCurrentVideoMask: function(){
      if(this.currentMaskIndex < 0){
        return;
      }

      this.videoMasks.splice(this.currentMaskIndex, 1);
    },
  },
}
</script>

<style lang="scss" scoped>
.mask_canvas{
  position: absolute; 
  top: 0; 
  left: 0; 
  width: 100%; 
  height: 100%;
}

.video-seek-container{
  position: relative;
  .video-seek-bar{
    transition: none !important; 
  }
  .mask-marker{
    position: absolute;
    top: 0;
    height: 100%;
    width: 2px;
    background: black;
  }
}
</style>