<template>
  <v-container fluid>
    <v-row dense align="center" justify="center">
      <v-col dense cols="12" sm="12" md="6" class="text-h5 font-weight-bold">
        {{ user.affiliation }} {{ user.familyName }} {{ user.firstName }} さん
      </v-col>
    </v-row>
    <v-row dense class="mb-0" align="center" justify="center">
      <v-col dense :class="overtimeWorkingTextColor" cols="12" sm="12" md="6" ref="overtimetank">
        今月の残業: <span class="text-h4 font-weight-black">{{ Math.ceil(residualWorkingOvertimeRatio) }}%</span>  ({{ hoursWorkingOverTime }} 時間 {{minutesWorkingOverTime}} 分 / 80 時間)
        <!-- html上でクリックした時には、valueに影響を与えないようにするため、v-modelでなくv-bind:valueを使用 -->
        <v-progress-linear
          :value="residualWorkingOvertimeRatio"
          height="25"
          striped
          :color="overtimeWorkingBarColor"
        >
        </v-progress-linear>
      </v-col>
    </v-row>
    <v-row dense class="mb-3" align="center" justify="center">
      <v-col dense cols="12" sm="12" md="6">
        <span class="text-caption">今月の経過時間: {{ elapsedDate }}日 / {{ fullDate }}日</span>
        <v-progress-linear
          color="grey"
          :value=Math.ceil(elapsedDate/fullDate*100)
        ></v-progress-linear>
      </v-col>
    </v-row>

    <v-row dense align="center" justify="center">
      <v-col cols="12" sm="12" md="6">
        <v-divider class="my-1"/>
      </v-col>
    </v-row>
    <v-row dense align="center" justify="center">
      <v-col 
        cols="12" 
        sm="12" 
        md="6" 
        class="text-h6 font-weight-bold"
      >
        勤務形態登録
        <span class="text-subtitle-2 font-weight-normal">「複数選択あり」</span>
      </v-col>
    </v-row>
    <v-row dense align="center" justify="center">
      <v-col cols="12" md="6">
        <v-expansion-panels
          class="pa-0 working-styles-container"
          flat
        >
          <v-expansion-panel>
            <v-expansion-panel-content>
              <WorkingStyleSelector
                class="mt-3"
                v-for="changeableDate in changeableDates"
                :date="changeableDate"
                :key="changeableDate"
                @change="loadMonthlyWorkingTime"
              />
            </v-expansion-panel-content>
            <v-expansion-panel-header class="pr-0 py-0">
              <WorkingStyleSelector
                :date="todayDate"
                @change="loadMonthlyWorkingTime"
              />
            </v-expansion-panel-header>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-row dense align="center" justify="center">
      <v-col cols="12" sm="12" md="6">
        <v-divider class="my-1"/>
      </v-col>
    </v-row>
    <v-row dense align="center" justify="center">
      <v-col 
        cols="12" 
        sm="12" 
        md="6" 
        class="text-h6 font-weight-bold"
      >
        病棟外来業務（外勤含む）の内訳
        <span class="text-subtitle-2 font-weight-normal">＊1日で以下の作業に費やした大まかな時間を入力</span>
      </v-col>
    </v-row>
    <v-row dense align="center" justify="center">
      <v-col cols="12" md="6">
        <v-expansion-panels
          class="pa-0 working-styles-container"
          flat
        >
          <v-expansion-panel>
            <v-expansion-panel-content>
              <OfficeWorksInput
                class="mt-3"
                v-for="changeableDate in changeableDates"
                :date="changeableDate"
                :key="changeableDate"
              />
            </v-expansion-panel-content>
            <v-expansion-panel-header class="pr-0 py-0">
              <OfficeWorksInput
                :date="todayDate"
              />
            </v-expansion-panel-header>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>


    <v-row dense align="center" justify="center">
      <v-col cols="12" sm="12" md="6">
        <v-divider class="my-1"/>
      </v-col>
    </v-row>
    <v-row dense align="center" justify="center">
      <v-col 
        cols="12" 
        sm="12" 
        md="6" 
        class="text-h6 font-weight-bold mb-3"
      >
        勤務内容登録
      </v-col>
    </v-row>
    <v-row dense align="center" justify="center">
      <v-col cols="12" md="6">
        <v-row dense align="center" justify="center">
          <v-col cols="12" sm="5" md="5" class="pt-5">
            <AttendanceCategorySelector
              v-model="taskSubCategory"
              :categories="filteredSubCategoryItems"
            />
          </v-col>
          <v-col cols="6" sm="3" md="3">
            <DateSelector
              v-model="taskDate"
              label="業務実施日"
              hide-details
            />
          </v-col>
          <v-col cols="3" sm="2" md="2">
            <TimeSelector
              v-model="taskStartTime"
              label="開始時間"
              hide-details
              :minutes-step="15"
            />
          </v-col>
          <v-col cols="3" sm="2" md="2">
            <TimeSelector
              v-if="!endTimeIsForced"
              v-model="taskEndTime"
              label="終了時間"
              hide-details
              :minutes-step="15"
            />
            <v-text-field
              v-else
              label="終了時間"
              value="24:00"
              disabled
              prepend-icon="mdi-clock-time-four-outline"
              hide-details
            >
            </v-text-field>
          </v-col>
          <v-col class='d-flex justify-end'>
            <v-checkbox
              v-model="endTimeIsForced"
              class="font-weight-bold mt-0"
              label="終了時間を強制的に『24:00』に。"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row dense align="center" justify="center">
      <v-btn 
        color="primary" 
        @click="registerAttendance"
        :disabled="!isTaskValid"
      >
        登録
      </v-btn>
    </v-row>
    <v-row dense align="center" justify="center">
      <v-col cols="12" sm="6" md="3" >
        <v-alert 
          dense
          type="error"
          class="my-1"
          v-for="(comment, i) in taskValidationComments"
          :key="`validationCommment${i}`"
        >
          {{ comment }}
        </v-alert>
      </v-col>
    </v-row>

    <v-row dense align="center" justify="center">
      <v-col cols="12" sm="12" md="6">
        <v-divider class="my-1"/>
      </v-col>
    </v-row>
    <v-row dense align="center" justify="center">
      <v-col 
        cols="12" 
        sm="12" 
        md="6" 
        class="text-h6 font-weight-bold"
      >
        本日の勤務内容
      </v-col>
    </v-row>
    <v-row 
      v-for="(task, taskIndex) in sortedFilteringTodayTasks"
      :key="`todayTasks-${taskIndex}`"
      dense 
      align="center" 
      justify="center"
    >
      <v-col 
        cols="4" 
        sm="4"
        md="2" 
        class="text-caption d-flex align-center flex-wrap"
        style="gap: 8px; row-gap: 8px;"
      >
        <v-btn rounded x-small @click="deleteTask(task)">
          <v-icon small>mdi-trash-can</v-icon>
        </v-btn>
        <v-btn rounded x-small color="primary px-2" @click="applyTaskInfo(task)">
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
        <div style="line-height: 1">
          {{ task.startTime }}-{{ task.endTime }}
        </div>
      </v-col>
      <v-col cols="8" sm="8" md="4" class="text-caption" :class="getTaskColor(task.categoryId)">
        {{ getTaskLabel(task) }}
      </v-col>
    </v-row>
    <v-row v-if="sortedFilteringTodayTasks.length==0" dense align="center" justify="center" class="mt-5">
      未登録
    </v-row>

    <v-row dense align="center" justify="center">
      <v-col cols="12" sm="12" md="6">
        <v-divider class="my-1"/>
      </v-col>
    </v-row>
    <v-row dense align="center" justify="center">
      <v-col dense cols="12" sm="12" md="6">
        <v-row dense>
          <v-col dense cols="12" class="text-h6 font-weight-bold">
            過去勤務内容の閲覧・修正
            <span class="text-subtitle-2 font-weight-normal"> ※{{ changeableDay }}日前まで変更可能 </span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row dense align="center" justify="center">
      <v-col dense cols="5" sm="4" md="2">
        <DateSelector
          v-model="dateFrom"
          label="開始日"
        />
      </v-col>
      <v-col dense cols="5" sm="4" md="2">
        <DateSelector
          v-model="dateTo"
          label="終了日"
        />
      </v-col>
      <v-col dense cols="2" sm="2" md="2">
        <v-btn small rounded @click="checkHistoricalRecords">
          <v-icon small>mdi-magnify</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row 
      v-for="(task, taskIndex) in tasksInRange"
      :key="`tasksInRange-${taskIndex}`"
      dense 
      align="center" 
      justify="center"
    >
      <v-col 
        cols="4" 
        sm="4" 
        md="2" 
        class="text-caption d-flex align-center flex-wrap"
        style="gap: 8px; row-gap: 8px;"
      >
        <v-btn 
          fab 
          x-small 
          @click="deleteTask(task)"
        >
          <v-icon small>mdi-trash-can</v-icon>
        </v-btn>
        <v-btn :disabled="task.taskDate < changeableDate" rounded x-small color="primary px-2" @click="applyTaskInfo(task)">
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
        <div style="line-height: 1">
          {{task.taskDate.split('-').slice(1).join('/')}}
          {{ task.startTime }}-{{ task.endTime }}
        </div>
      </v-col>
      <v-col cols="8" sm="8" md="4" class="text-caption" :class="getTaskColor(task.categoryId)">
        {{ getTaskLabel(task) }}
      </v-col>
    </v-row>
    <v-row v-if="tasksInRange.length==0" dense align="center" justify="center" class="mt-5">
      <template v-if="isCheckedTasksInRange">検索結果: 入力した勤務はありません。</template>
      <template v-else>検索結果: 未検索</template>
    </v-row>

    <v-dialog
      v-model="isUpdatedTaskDialogOpened"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h6 pb-5 mb-3">
          以下の勤怠情報を更新
        </v-card-title>
        <v-card-text>
          <v-row dense align="center" justify="center">
            <v-col cols="12">
              <AttendanceCategorySelector
                v-model="editingTaskSubCategory"
                :categories="modalFilteredSubCategoryItems"
              />
            </v-col>
            <v-col dense cols="12" sm="4">
              <DateSelector
                v-model="editingTaskDate"
                label="業務実施日"
                hide-details
              />
            </v-col>
            <v-col dense cols="6" sm="4">
              <TimeSelector
                v-model="editingTaskStartTime"
                label="開始時間"
                :minutes-step="15"
                hide-details
              />
            </v-col>
            <v-col dense cols="6" sm="4">
              <TimeSelector
                v-model="editingTaskEndTime"
                label="終了時間"
                :minutes-step="15"
                hide-details
              />
            </v-col>
          </v-row>
          
        </v-card-text>
        <v-card-actions>
          <v-container fluid>
            <v-row align="center" justify="center" dense>
              <v-btn 
                v-if="isNeededToBeChangedTaskDialogOpened" 
                color="primary" 
                @click='tempUpdateAttendance'
                :disabled="!isEditingTaskValid"
                class="mb-4"
              >
                OK（まだ保存されません）
              </v-btn>
              <v-btn 
                v-else 
                color="primary" 
                class="mb-4"
                @click='updateAttendance'
                :disabled="!isEditingTaskValid"
              >
                更新
              </v-btn>
            </v-row>
            <v-alert 
              dense
              type="error"
              class="my-1"
              v-for="(comment, i) in editingTaskValidationComments"
              :key="`editiongTaskValidationCommment${i}`"
            >
              {{ comment }}
            </v-alert>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="isNeededToBeChangedTaskDialogOpened"
      width="500"
    >
      <v-card>
        <v-card-title class="text-subtitle-1">
          以下の勤怠情報に重複がございます。重複しないように時間の調整をお願いします。
        </v-card-title>
        <v-card-text>
          <v-row 
            v-for="(task, taskIndex) in tasksIsNeededToBeChanged"
            :key="`tasksNeededToBeChanged-${taskIndex}`"
            dense 
            align="center" 
            justify="center"
          >
            <v-col 
              class="text-caption d-flex align-center"
              style="gap: 8px; row-gap: 8px;"
            >
              <v-btn rounded x-small color="primary px-2" @click="applyTaskInfo(task, taskIndex)">
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
              <div style="line-height: 1">
                {{ task.taskDate.split('-').slice(1).join('/') }} {{ task.startTime }}-{{ task.endTime }}
              </div>
            </v-col>
            <v-col class="text-caption">
              {{ getTaskLabel(task) }}
            </v-col>
          </v-row>
          <v-row align="center" justify="center" dense>
            <v-btn class="mt-5" color="primary" @click='upsertAttendances'>
              一括更新
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-overlay :value="isLoading">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { getDateStr, getTimeStr } from '@js/utils/StringUtil.js';
import DateSelector from '@components/inputs/DateSelector.vue';
import TimeSelector from '@components/inputs/TimeSelector.vue';
import AttendanceCategorySelector from '@components/inputs/AttendanceCategorySelector.vue';
import WorkingStyleSelector from '@components/inputs/WorkingStyleSelector.vue';
import OfficeWorksInput from '@components/inputs/OfficeWorksInput.vue';

import moment from 'moment';

export default {
  components: {
    DateSelector,
    TimeSelector,
    AttendanceCategorySelector,
    WorkingStyleSelector,
    OfficeWorksInput,
	},
  data: function(){
    return {
      taskCategory: null,
      taskSubCategory: null,
      taskDate: null,
      taskStartTime: null,
      taskEndTime: null,

      editingTaskId: null,
      editingTaskCategory: null,
      editingTaskSubCategory: null,
      editingTaskDate: null,
      editingTaskStartTime: null,
      editingTaskEndTime: null,

      dateFrom: null,
      dateTo: null,

      isUpdatedTaskDialogOpened: false,

      todayDate: null,
      changeableDate: null,
      changeableDay: 6,
      changeableTasks: [],
      tasksInRange: [],
      isCheckedTasksInRange: false,
      endTimeIsForced: false,

      elapsedDate: null,
      fullDate: null,

      isLoading: false,

      lowOvertimeThreashold: 50,
      highOvertimeThreashold: 80,

      isNeededToBeChangedTaskDialogOpened: false,
      tasksIsNeededToBeChanged: [{
        id: 0,
        categoryId: 1,
        subCategoryId: 1,
        taskDate: '2021-11-20',
        startTime: '12:00',
        endTime: '13:00',
      }, {
        id: 42,
        categoryId: 2,
        subCategoryId: 10,
        taskDate: '2021-11-20',
        startTime: '13:00',
        endTime: '14:00',
      }],
      taskOrderNumber: null,
    }
  },
  computed: {
    ...mapState([
      'user',
      'taskCategoryItems',
      'taskSubCategoryItems',
      'hospitalItems',

      'monthlyWorkingTime',
      'totalWorkingOvertime',
      'hoursWorkingOverTime',
      'minutesWorkingOverTime',
      'residualWorkingOvertimeRatio',
    ]),
    changeableDates: function(){
      let rtn = [];
      for(let i=0; i<this.changeableDay; i++){
        rtn.push(moment(this.todayDate).add(-this.changeableDay + i, 'days').format("YYYY-MM-DD"))
      }
      return rtn;
    },
    taskValidationComments: function(){
      let rtn = [];
      if(this.taskCategory == null || this.taskSubCategory == null){
        rtn.push("カテゴリは全て入力してください。");
      }

      if (this.taskDate < this.changeableDate) {
        rtn.push((this.changeableDay + 1) + "日以上前の予定は入力できません。");
      }

      if(this.taskStartTime == this.taskEndTime) {
        rtn.push("開始時間と終了時間を同時間に設定できません。");
      }

      let startTime = moment(this.taskDate + ' ' + this.taskStartTime).toDate();
      let endTime = moment(this.taskDate + ' ' + this.taskEndTime).toDate();
      if (endTime.getTime() - startTime.getTime() < 0) {
        rtn.push("終了時間は開始時間より後に設定してください。");
      }

      return rtn;
    },
    isTaskValid: function(){
      return this.taskValidationComments.length == 0;
    },
    editingTaskValidationComments: function(){
      let rtn = [];

      if(this.editingTaskCategory == null || this.editingTaskSubCategory == null) {
        rtn.push("カテゴリは全て入力してください。");
      }
      if (this.editingTaskDate < this.changeableDate) {
        rtn.push((this.changeableDay+1) + "日以上前の予定は入力できません。");
      }
      if(this.editingTaskStartTime == this.editingTaskEndTime) {
        rtn.push("開始時間と終了時間を同時間に設定できません。");
      }

      let startTime = moment(this.editingTaskDate + ' ' + this.editingTaskStartTime).toDate();
      let endTime = moment(this.editingTaskDate + ' ' + this.editingTaskEndTime).toDate();
      if (endTime.getTime() - startTime.getTime() < 0) {
        rtn.push("終了時間は開始時間より後に設定してください。");
      }

      return rtn;
    },
    isEditingTaskValid: function(){
      return this.editingTaskValidationComments.length == 0;
    },
    taskCategoryItemsEachHospitals: function() {
      let hospitalIds = this.getAffiliatedHospitalIds;
      let arr = [];
      for (let taskCategoryItem of this.taskCategoryItems) {
        if (!(hospitalIds.includes(taskCategoryItem.hospitalId))) continue;
        let caption = taskCategoryItem.caption;
        if(this.getAffiliatedHospitalIds.length > 1) {
          caption += "【";
          caption += this.getHopsitalShortName(taskCategoryItem.hospitalId);
          caption += "】";
        }
        arr.push({
          "id": taskCategoryItem.id,
          "hospitalId": taskCategoryItem.hospitalId,
          "caption": caption,
        });
      }
      return arr;
    },
    taskSubCategoryItemsEachHospitals: function() {
      let hospitalIds = this.getAffiliatedHospitalIds;
      let arr = [];
      for (let taskSubCategoryItem of this.taskSubCategoryItems) {
        if (!(hospitalIds.includes(taskSubCategoryItem.hospitalId))) continue;
        let caption = taskSubCategoryItem.caption;
        if(this.getAffiliatedHospitalIds.length > 1) {
          caption += "【";
          caption += this.getHopsitalShortName(taskSubCategoryItem.hospitalId);
          caption += "】";
        }
        arr.push({
          ...taskSubCategoryItem,
          "caption": caption,
        });
      }
      return arr;
    },
    getHopsitalShortName: function(){
      return (id) => this.hospitalItems.find(i => i.id === id)?.shortName;
    },
    getAffiliatedHospitalIds: function() {
      let affiliated_hospitals_id = [];
      for (let hospital of this.user.hospital) {
        affiliated_hospitals_id.push(hospital.id);
      }
      return affiliated_hospitals_id;
    },
    overtimeWorkingTextColor: function(){
      if(this.residualWorkingOvertimeRatio < this.lowOvertimeThreashold){
        return 'blue--text'
      } else if(this.residualWorkingOvertimeRatio < this.highOvertimeThreashold){
        return 'amber--text'
      }
      return 'red--text'
    },
    overtimeWorkingBarColor: function(){
      if(this.residualWorkingOvertimeRatio < this.lowOvertimeThreashold){
        return 'blue'
      } else if(this.residualWorkingOvertimeRatio < this.highOvertimeThreashold){
        return 'amber'
      }
      return 'red'
    },
    sortedFilteringTodayTasks: function(){
      let rtn = [...this.changeableTasks];
      rtn.sort((a, b) => a.startTime < b.startTime ? -1 : 1);
      let tempDate = this.todayDate
      rtn = rtn.filter(function(value) {
        return value.taskDate == tempDate
      })
      return rtn;
    },
    getTaskColor: function(){
      return TaskCategoryId => this.taskCategoryItems.find(_i => _i.id === TaskCategoryId)?.color + '--text'
    },
    getTaskLabel: function(){
      return (task) => {
        if(!task){
          return "";
        }
        let rtn = "";
        if(this.getAffiliatedHospitalIds.length > 1) {
          rtn += "【";
          rtn += this.getHopsitalShortName(this.taskCategoryItems.find(i => i.id === task.categoryId)?.hospitalId);
          rtn += "】";
        }
        rtn += this.taskCategoryItems.find(i => i.id === task.categoryId)?.caption
        rtn += "/"
        rtn += this.taskSubCategoryItems.find(i => i.id === task.subCategoryId)?.caption
        return rtn;
      };
    },
    filteredSubCategoryItems: function(){
      /*
      if(this.taskCategory) {
        let parent_id = this.taskCategoryItemsEachHospitals.find(i => i.id === this.taskCategory)?.id
        return this.taskSubCategoryItemsEachHospitals.filter(i => i.parent === parent_id)
      }
      */
      return this.taskSubCategoryItemsEachHospitals
    },
    // 【TODO】modal部分、通常と同じような処理なのに、繰り返し書いてるのでリファクタリング必要！！（htmlとwatchパートも同様）
    modalFilteredSubCategoryItems: function(){
      /*
      if(this.editingTaskCategory) {
        let parent_id = this.taskCategoryItemsEachHospitals.find(i => i.id === this.editingTaskCategory)?.id
        return this.taskSubCategoryItemsEachHospitals.filter(i => i.parent === parent_id)
      }
      */
      return this.taskSubCategoryItemsEachHospitals
    },
  },
  watch: {
    taskCategory: function(){
      this.taskSubCategory = this.filteredSubCategoryItems.find(i => i.id === this.taskSubCategory)?.id;
    },
    taskSubCategory: function(newVal){
      let taskSubCategory = this.filteredSubCategoryItems.find(i => i.id === newVal);
      if(!taskSubCategory){
        return;
      }
      this.taskCategory = this.taskCategoryItemsEachHospitals.find(i => i.id === taskSubCategory.parent)?.id;
    },
    editingTaskCategory: function(){
      this.editingTaskSubCategory = this.modalFilteredSubCategoryItems.find(i => i.id === this.editingTaskSubCategory)?.id;

    },
    editingTaskSubCategory: function(newVal){
      let editingTaskSubCategory = this.modalFilteredSubCategoryItems.find(i => i.id === newVal);
      this.editingTaskCategory = this.taskCategoryItemsEachHospitals.find(i => i.id === editingTaskSubCategory.parent)?.id;
    },
  },
  mounted: function(){
    let now = moment();

    now = now.add(-now.minute()%15, 'm');

    let currentDate = now.toDate();
    this.elapsedDate = currentDate.getDate();
    this.fullDate = new Date(currentDate.getFullYear(), currentDate.getMonth()+1, 0).getDate();
    let currentDateStr = getDateStr(currentDate);
    let currentTimeStr = getTimeStr(currentDate);

    this.taskStartTime = currentTimeStr;
    // 終了時間は現時刻の1時間後に設定
    let anHourFromNow = currentDate;
    anHourFromNow.setHours(anHourFromNow.getHours() + 1);
    this.taskEndTime = getTimeStr(anHourFromNow);
    this.taskDate = currentDateStr;
    this.dateFrom = currentDateStr;
    this.dateTo = currentDateStr;
    this.todayDate = currentDateStr;

    // 2日前まで変更可能な設定
    let changeableDate = new Date();
    changeableDate.setDate(changeableDate.getDate() - this.changeableDay);
    let changeableDateStr = getDateStr(changeableDate);
    this.changeableDate = changeableDateStr;
    this.loadAttendance(this.changeableDate, this.taskDate, this.changeableTasks);
    this.loadMonthlyWorkingTime();
  },
  methods: {
    ...mapActions([
      'fetchAttendanceTime'
    ]),
    registerAttendance: function() {
      if(!this.isTaskValid){
        alert(this.taskValidationComments.join('\n'));
        return;
      }

      // 時間帯の重なりがないかどうかをフロントで確認
      this.tasksIsNeededToBeChanged = [];
      this.changeableTasks.forEach(s => {
        if (this.taskDate == s.taskDate && this.taskStartTime < s.endTime && this.taskEndTime > s.startTime) {
          this.tasksIsNeededToBeChanged.push(s);
        }
      });
      if(this.tasksIsNeededToBeChanged.length > 0) {
        this.tasksIsNeededToBeChanged.push({
          id: 0,
          taskDate: this.taskDate,
          startTime: this.taskStartTime,
          endTime: this.taskEndTime,
          categoryId: this.taskCategory,
          subCategoryId: this.taskSubCategory,
        });
        this.isNeededToBeChangedTaskDialogOpened = true;
        return;
      }

      let attendanceRecord = {
        id: 0,
        categoryId: this.taskCategoryItems.find(i => i.id === this.taskCategory)?.id,
        subCategoryId: this.taskSubCategoryItems.find(i => i.id === this.taskSubCategory)?.id,
        startTime: this.taskDate + ' ' + this.taskStartTime,
        endTime: this.endTimeIsForced? this.taskDate + ' 23:59' : this.taskDate + ' ' + this.taskEndTime
      }
      this.axios.post('/api/attendance', attendanceRecord).then(response => {
        if(response.data.result === 'ok'){
          this.reloadAttendance(this.taskDate);
          this.taskCategory = null;
          this.taskSubCategory = null;
          this.taskStartTime = this.taskEndTime;

          let anHourFromTaskStart = moment(this.taskDate + " " + this.taskStartTime).toDate();
          anHourFromTaskStart.setHours(anHourFromTaskStart.getHours() + 1);
          this.taskEndTime = getTimeStr(anHourFromTaskStart);
          this.endTimeIsForced = false;
          
          this.loadMonthlyWorkingTime();
        } else if(response.data.result === 'cannot update'){
          response.data.attendances.forEach(s => {
            this.isNeededToBeChangedTaskDialogOpened = true;
            this.updateTasks(s, this.tasksIsNeededToBeChanged);
          });
        }
      }).catch(error => {
        console.log(error);
        alert('保存に失敗しました。暫くたっても同様のエラーが出る場合、管理者にお問い合わせください。');
      });
    },
    updateAttendance: function() {
      if(!this.isEditingTaskValid){
        alert(this.editingTaskValidationComments.join('\n'));
        return;
      }

      // 時間帯の重なりがないかどうかをフロントで確認
      this.tasksIsNeededToBeChanged = [];
      this.changeableTasks.forEach(s => {
        if (this.editingTaskId != s.id
            && this.editingTaskDate == s.taskDate
            && this.editingTaskStartTime < s.endTime
            && this.editingTaskEndTime > s.startTime)
        {
          this.tasksIsNeededToBeChanged.push(s);
        }
      });
      if(this.tasksIsNeededToBeChanged.length > 0) {
        this.tasksIsNeededToBeChanged.push({
          id: this.editingTaskId,
          taskDate: this.editingTaskDate,
          startTime: this.editingTaskStartTime,
          endTime: this.editingTaskEndTime,
          categoryId: this.editingTaskCategory,
          subCategoryId: this.editingTaskSubCategory,
        });
        this.isUpdatedTaskDialogOpened = false;
        this.isNeededToBeChangedTaskDialogOpened = true;
        return;
      }

      let attendanceRecord = {
        id: this.editingTaskId,
        categoryId: this.taskCategoryItems.find(i => i.id === this.editingTaskCategory)?.id,
        subCategoryId: this.taskSubCategoryItems.find(i => i.id === this.editingTaskSubCategory)?.id,
        startTime: this.editingTaskDate + ' ' + this.editingTaskStartTime,
        endTime: this.editingTaskDate + ' ' + this.editingTaskEndTime,
      }
      this.axios.put('/api/attendance/', attendanceRecord).then(response => {
        if(response.data.result === 'ok'){
          this.updateDisplayedInfo();
        } else if(response.data.result === 'cannot update'){
          this.tasksIsNeededToBeChanged = [];
          response.data.attendances.forEach(s => {
            this.isUpdatedTaskDialogOpened = false;
            this.isNeededToBeChangedTaskDialogOpened = true;
            this.updateTasks(s, this.tasksIsNeededToBeChanged);
          });
        }
      }).catch(error => {
        console.log(error);
        alert('保存に失敗しました。暫くたっても同様のエラーが出る場合、管理者にお問い合わせください。');
      });
    },
    upsertAttendances: function() {
      let attendanceRecords = [];
      for(let i = 0; i < this.tasksIsNeededToBeChanged.length; i++) {
        attendanceRecords.push(
          {
            id: this.tasksIsNeededToBeChanged[i].id,
            categoryId: this.tasksIsNeededToBeChanged[i].categoryId,
            subCategoryId: this.tasksIsNeededToBeChanged[i].subCategoryId,
            startTime: this.tasksIsNeededToBeChanged[i].taskDate + ' ' + this.tasksIsNeededToBeChanged[i].startTime,
            endTime: this.tasksIsNeededToBeChanged[i].taskDate + ' ' + this.tasksIsNeededToBeChanged[i].endTime,
          }
        )
        // 時間の重なりチェック
        for(let j = i+1; j < this.tasksIsNeededToBeChanged.length; j++) {
          if(this.tasksIsNeededToBeChanged[i].startTime < this.tasksIsNeededToBeChanged[j].endTime
              && this.tasksIsNeededToBeChanged[i].endTime > this.tasksIsNeededToBeChanged[j].startTime) {
            alert('登録時間に重なりがないように、登録してください。');
            return;
          }
        }
      }
      this.axios.post('/api/attendances', attendanceRecords).then(response => {
        this.updateDisplayedInfo();
        this.tasksIsNeededToBeChanged = [];
        if(response.data.result === 'ok') {
          this.isNeededToBeChangedTaskDialogOpened = false;
        }
        else if(response.data.result === 'cannot update'){
          response.data.attendances.forEach(s => {
            this.updateTasks(s, this.tasksIsNeededToBeChanged);
          });
        }
      }).catch(error => {
        console.log(error);
        alert('保存に失敗しました。暫くたっても同様のエラーが出る場合、管理者にお問い合わせください。');
      });
    },
    updateDisplayedInfo: function() {
      this.changeableTasks = [];
      this.loadAttendance(this.todayDate, this.todayDate, this.changeableTasks);
      if(this.isCheckedTasksInRange) {
        this.tasksInRange = [];
        this.loadAttendance(this.dateFrom, this.dateTo, this.tasksInRange);
      }
      this.isUpdatedTaskDialogOpened = false;
      this.loadMonthlyWorkingTime();
    },
    tempUpdateAttendance: function() {
      if(this.editingTaskValidationComments.length > 0){
        alert(this.editingTaskValidationComments.join('\n'));
        return;
      }

      this.tasksIsNeededToBeChanged[this.taskOrderNumber] = {
        id: this.editingTaskId,
        categoryId: this.editingTaskCategory,
        subCategoryId: this.editingTaskSubCategory,
        taskDate: this.editingTaskDate,
        startTime: this.editingTaskStartTime,
        endTime: this.editingTaskEndTime,
      }

      this.isUpdatedTaskDialogOpened = false;
    },
    reloadAttendance: function(date) {
      this.changeableTasks = [];
      this.loadAttendance(this.todayDate, this.todayDate, this.changeableTasks);
      if(this.dateFrom <= date && date <= this.dateTo && this.isCheckedTasksInRange) {
        this.tasksInRange = [];
        this.loadAttendance(this.dateFrom, this.dateTo, this.tasksInRange);
      }
    },
    loadAttendance: function(startDate, endDate, dest) {
      this.isLoading = true;
      this.axios.get('/api/attendance/' + startDate + '/' + endDate).then(response => {
        response.data.attendances.forEach(s => {
          this.updateTasks(s, dest)
        });
        this.$forceUpdate();
      }).catch(error => {
        console.log(error);
        alert('勤務状況の取得に失敗しました。暫くたっても同様のエラーが出る場合、管理者にお問い合わせください。')
      }).finally(() => {
        this.isLoading = false;
      });
    },
    loadMonthlyWorkingTime: function() {
      this.fetchAttendanceTime();
    },
    updateTasks: function(record, dest) {
      let _startDate = moment(record.startTime).toDate();
      let _endDate = moment(record.endTime).toDate();

      dest.push({
        id: record.id,
        taskDate: getDateStr(_startDate),
        startTime: getTimeStr(_startDate),
        endTime: moment(record.endTime).minute() == 59 ?  "24:00" : getTimeStr(_endDate),
        categoryId: record.categoryId,
        subCategoryId: record.subCategoryId,
      });
    },
    checkHistoricalRecords: function() {
      if(this.dateFrom > this.dateTo) {
        alert('終了日は開始日より後に設定してください。');
        return;
      }
      this.tasksInRange = [];
      this.isCheckedTasksInRange = true;
      this.loadAttendance(this.dateFrom, this.dateTo, this.tasksInRange);
    },
    applyTaskInfo: function(task, orderNumber) {
      this.editingTaskId = task.id;
      this.editingTaskCategory = this.taskCategoryItems.find(i => i.id === task.categoryId)?.id;
      this.editingTaskSubCategory = this.taskSubCategoryItems.find(i => i.id === task.subCategoryId)?.id;
      this.editingTaskDate = task.taskDate;
      this.editingTaskStartTime = task.startTime;
      this.editingTaskEndTime = task.endTime;
      this.isUpdatedTaskDialogOpened = true;
      this.taskOrderNumber = orderNumber;
    },
    deleteTask: function(task) {
      this.axios.delete('/api/attendance/'+task.id).then(response => {
        if(response.data.result === 'ok'){
          this.updateDisplayedInfo();
        } else if(response.data.result === 'not user who created'){
          alert("この勤怠登録を行ったユーザと異なるユーザは削除できません。")
        }
      }).catch(error => {
        console.log(error);
        alert('保存に失敗しました。暫くたっても同様のエラーが出る場合、管理者にお問い合わせください。');
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.working-styles-container{
  ::v-deep .v-expansion-panel-header:not(.v-expansion-panel-header--mousedown):focus::before{
    opacity: 0;
  }
}
</style>