<template>
  <v-dialog
    ref="dialog"
    v-model="isModalOpened"
    :return-value.sync="datetime"
    width="920px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :disabled="disabled"
        v-model="datetimeStr"
        :label="label"
        :prepend-icon="noIcon ? 'mdi-calendar' : null"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-card>
      <v-card-title class="primary white--text text-h3 justify-end">
        {{ datetimeStr }}
      </v-card-title>
      <v-card-text class="d-flex">
        <v-date-picker
          no-title
          v-model="date"
        >
        </v-date-picker>
        <v-time-picker
          v-model="time"
          no-title
          format="24hr"
          ref="hourPicker"
          @click:hour="onSelectHour"
        >
        </v-time-picker>
        <v-time-picker
          v-model="time"
          no-title
          format="24hr"
          ref="minutePicker"
          @click:minute="onSelectMinute"
        >
        </v-time-picker>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          color="red"
          @click="isModalOpened = false"
        >
          Cancel
        </v-btn>
        <v-btn
          text
          color="primary"
          @click="confirm"
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { getDatetimeStr } from '@js/utils/StringUtil.js';

export default {
  components: {
	},
  props: {
    value: {},
    label: {},
    noIcon: {
      default: false,
    },
    disabled: {
      default: false,
    },
  },
  data: function(){
    return {
      isModalOpened: false,
    }
  },
  computed: {
    datetime: {
      get() {
        return this.value ?? getDatetimeStr(new Date());
      },
      set(newVal) {
        if (this.value !== newVal){
          this.$emit('input', newVal.split('/').join('-'));
        }
      }
    },
    datetimeStr: {
      get() {
        return this.datetime.slice(0, -3);
      },
      set(newVal) {
        if (this.value !== newVal){
          this.datetime = newVal + ":00";
        }
      }
    },
    date: {
      get() {
        return this.datetimeStr.slice(0, 10).split('/').join('-')
      },
      set(newVal) {
        if (this.value !== newVal){
          this.datetimeStr = newVal.split('-').join('/') + " " + this.time;
        }
      }
    },
    time: {
      get() {
        return this.datetimeStr.slice(-5)
      },
      set(newVal) {
        if (this.value !== newVal){
          this.datetimeStr = this.date.split('-').join('/') + " " + newVal;
        }
      }
    }
  },
  watch: {
    isModalOpened: function(newVal){
      if(newVal){
        this.$nextTick(() => {
          //1: hour, 2: minute, 3: second
          this.$refs.hourPicker.selecting = 1;
          this.$refs.minutePicker.selecting = 2;
        });
      }
    },
    immediate: true
  },
  mounted: function(){
  },
  methods: {
    onSelectHour: function(){
      this.$nextTick(() => {
        this.$refs.hourPicker.selectingHour = true;
      });
    },
    onSelectMinute: function(){
      this.$nextTick(() => {
        this.$refs.minutePicker.selectingMinute = true;
      });
    },
    confirm: function(){
      this.$refs.dialog.save(this.datetime);
      this.$emit('change')
    },
  }
}
</script>