<template>
  <v-container fluid>
    <v-row align="center" justify="center" dense>
      <v-col md="3">
        <v-text-field dense class="mt-5" v-model="accountId" label="メールアドレス" />
      </v-col>
    </v-row>
    <v-row align="center" justify="center" dense>
      <v-col md="3">
        <v-text-field dense @keydown.enter="login" type="password" v-model="password" label="パスワード" />
      </v-col>
    </v-row>
    <v-row align="center" justify="center" dense>
      <v-btn 
        outlined
        color="primary"
        text 
        @click.stop="login" 
        :disabled="(!password || password.length == 0) || (!accountId || accountId.length == 0)"
      >ログイン</v-btn>
    </v-row>
    <v-row align="center" justify="center">
      <v-dialog
        v-model="isAccountRegistrationModalOpened"
        width="500"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="text-decoration-underline mt-8"
            v-bind="attrs"
            v-on="on"
            depressed
            color="white"
          >
            > 新規登録はこちら
          </v-btn>
        </template>

        <v-card>
          <v-card-title dense class="grey lighten-2">
          </v-card-title>
          <v-card-text class="text-center">
            <div class="text-left mt-3">
              新規会員登録には、管理者の承認が必要です。承認有無に関しては、メールにてご連絡いたします。<br/>
              また登録承認にあたり、お電話にて確認する場合がございますので、ご了承ください。
            </div>
            <v-form ref="form" lazy-validation>
              <v-text-field class="mt-5" v-model="registrationData.mailAddress" label="メールアドレス" :rules="[required, emailValidation]"/>
              <v-text-field type="password" v-model="registrationData.password" label="パスワード" :rules="[required, passwordValidation]"/>
              <v-text-field type="password" v-model="registrationData.passwordForConfirmation" label="パスワード（確認用）" :rules="[required, passwordForConfirmationValidation]"/>
              <v-row>
                <v-col cols="6">
                  <v-text-field v-model="registrationData.familyName" label="氏" :rules="[required]"/>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="registrationData.firstName" label="名" :rules="[required]"/>
                </v-col>
              </v-row>
              <v-radio-group
                v-model="registrationData.isAlliance"
                row
              >
                <v-radio
                  label="アライアンス内"
                  :value="true"
                ></v-radio>
                <v-radio
                  label="アライアンス外"
                  :value="false"
                ></v-radio>
              </v-radio-group>
              <template v-if="registrationData.isAlliance">
                <v-select
                  v-model="registrationData.hospital"
                  label="所属施設名"
                  class="text-no-wrap"
                  :items="hospitalItems"
                  item-text="name"
                  item-value="id"
                  :rules="[required, notEmpty]"
                  multiple
                ></v-select>
                <template v-if="registrationData.hospital && registrationData.hospital.length > 0">
                  <v-select
                    v-for="hospital in registrationData.hospital"
                    :key="hospital"
                    v-model="registrationData.department[hospital]"
                    :label="`${getHopsitalShortName(hospital)} 所属部署`"
                    class="text-no-wrap"
                    :items="departmentItems.filter(i => i.hospitalId == hospital)"
                    item-text="name"
                    item-value="id"
                    :rules="[required]"
                  ></v-select>
                </template>
              </template>
              <template v-else>
                <v-text-field v-model="registrationData.hospital" label="所属施設名" :rules="[required]"/>
                <v-text-field v-model="registrationData.department" label="所属部署" :rules="[required]"/>
              </template>
              <v-text-field v-model="registrationData.telephoneNumber" label="電話番号" :rules="[required]"/>
            </v-form>
            <v-btn 
              color="primary" 
              @click.stop="registerAccount"
              :disabled="!isRegistrationValid"
            >登録依頼</v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row align="center" justify="center">
      <v-dialog
        v-model="isPasswordReissuanceModalOpened"
        width="500"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="text-decoration-underline mt-1"
            v-bind="attrs"
            v-on="on"
            depressed
            color="white"
          >
            > パスワードを忘れた方はこちら
          </v-btn>
        </template>

        <v-card>
          <v-card-title dense class="grey lighten-2">
          </v-card-title>
          <v-card-text>
            <p>
              以下のボックスに登録されているメールアドレスを入力して「送信」ボタンをクリックしてください。<br />
              システムが新しいパスワードを設定して、登録されているメールアドレスに送信します。
            </p>
            <p>
              再設定を行うと、現在のパスワードは利用できなくなります。<br />
              再設定されたパスワードの有効期限は、<span class="red--text">15分間</span>です。<br />
              パスワードが無効になってしまった場合は、再度本画面から再設定してください。
            </p>
            <v-form ref="forgettingPassword" lazy-validation>
              <v-text-field
                class="mt-5"
                v-model="registrationData.mailAddress"
                label="メールアドレス"
                :rules="[required, emailValidation]" />
              <v-card-text class="text-center">
                <v-btn 
                  outlined
                  color="primary"
                  text 
                  @click.stop="reissuePassword"
                  :disabled="!isForgetingPasswordValid"
                >送信</v-btn>
              </v-card-text>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>

    <v-dialog
      v-model="isUpdatedPasswordDialogOpened"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h6">
          以下からパスワード更新をしてください。
        </v-card-title>
        <v-form ref="resetPasswordForm" lazy-validation>
          <v-card-text>
            <v-row align="center" justify="center">
              <v-col md="6">
                <v-text-field 
                  dense 
                  type="password" 
                  v-model="resetPasswordData.oldPassword" 
                  label="現在のパスワード" 
                  :rules="[required, passwordValidation]"
                />
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-col md="6">
                <v-text-field 
                  dense 
                  type="password" 
                  v-model="resetPasswordData.newPassword" 
                  label="新しいパスワード" 
                  :rules="[required, passwordValidation]"
                />
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-col md="6">
                <v-text-field 
                  dense 
                  type="password" 
                  v-model="resetPasswordData.passwordForConfirmation" 
                  label="新しいパスワード（確認用）" 
                  :rules="[required, resetPasswordForConfirmationValidation]"
                />
              </v-col>
            </v-row>
            <v-row align="center" justify="center" dense>
              <v-btn 
                color="primary" 
                @click.stop="resetAccountPassword"
                :disabled="!isResettingPasswordValid"
              >再設定</v-btn>
            </v-row>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>

    <v-overlay :value="isLoading">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: 'App',
  components: {
  },
  data: () => ({
    accountId: null,
    password: null,
    resetPasswordData: {
      userId: null,
      oldPassword: null,
      newPassword: null,
      passwordForConfirmation: null,
    },
    isAccountRegistrationModalOpened: false,
    isPasswordReissuanceModalOpened: false,
    isUpdatedPasswordDialogOpened: false,
    registrationData: {
      mailAddress: null,
      password: null,
      passwordForConfirmation: null,
      familyName: null,
      firstName: null,
      hospital: null,
      department: {},
      isAlliance: true,
      telephoneNumber: null,
    },

    required: value => !!value || "※必須",
    notEmpty: value => !value || value.length > 0 || "※必須",
    emailValidation: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || '※メールアドレスが正しくありません',
    invalidDomainValidation: v => !v || !v.endsWith('@gmail.com') || '※フリーメールは登録できません',
    passwordValidation: v => !v || /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d]{8,100}$/.test(v) || '※半角英小文字・大文字・数字それぞれ1文字以上含む8文字以上',

    isRegistrationValid: false,
    isForgetingPasswordValid: false,
    isResettingPasswordValid: false,
    isLoading: false,
  }),
  computed: {
    ...mapState([
      'hospitalItems',
      'departmentItems',
    ]),
    getHopsitalShortName: function(){
      return (id) => this.hospitalItems.find(i => i.id === id)?.shortName
    },
    passwordForConfirmationValidation: function(){
      return (value) => this.registrationData.password === value || '※パスワードが一致しません';
    },
    resetPasswordForConfirmationValidation: function(){
      return (value) => this.resetPasswordData.newPassword === value || '※パスワードが一致しません';
    },
  },
  watch:{
    registrationData: {
      handler: function(){
        this.$nextTick(() => {
          this.isRegistrationValid = this.$refs.form?.validate();
        });
      },
      deep: true,
    },
    'registrationData.mailAddress': function(){
      this.$nextTick(() => {
        this.isForgetingPasswordValid = this.$refs.forgettingPassword?.validate();
      });
    },
    'registrationData.isAlliance': function(newVal){
      this.registrationData = {
        ...this.registrationData,
        hospital: null,
        department: newVal ? {} : null,
        telephoneNumber: null,
      }

      this.$nextTick(() => {
        this.$refs.form.validate();
      });
    },
    'registrationData.hospital': function(){
      this.$nextTick(() => {
        this.$refs.form.validate();
      });
    },
    resetPasswordData: {
      handler: function(){
        this.$nextTick(() => {
          this.isResettingPasswordValid = this.$refs.resetPasswordForm?.validate();
        });
      },
      deep: true,
    },
    isAccountRegistrationModalOpened: function(newVal){
      if(newVal){
        this.$nextTick(() => {
          this.$refs.form.validate();
        });
      }
    }
  },
  mounted() {
  },
  methods: {
    ...mapActions([
      'setUser',
      'setHospitalItems',
      'setOfficeWorkings',
      'setWorkingArrangements',
    ]),
    registerAccount: function(){
      this.isLoading = true;
      this.axios.post(`/api/account_request`, this.registrationData).then((response) => {
        if(response.data.result === 'ok'){
          alert('アカウント作成依頼が完了しました。\n\n承認完了しましたら、メールにてご連絡差し上げますので、暫くお待ち下さい。');
          return;
        }
      }).catch(error => {
        let message = "アカウント依頼に失敗しました。";
        if(error.response.status == 409){
          message = error.response?.data?.detail ?? message;
        }
        alert(message);
      }).finally(() => {
        this.isLoading = false;
      });
      this.isAccountRegistrationModalOpened = false;
    },
    reissuePassword: function(){
      this.isLoading = true;
      this.axios.post(`/api/reissue_password`, {
        mailAddress: this.registrationData.mailAddress,
      }).then(response => {
        if(response.data.result === 'not_found'){
          alert('該当するアカウントは存在しません。\nアカウント作成画面から新規登録してください。');
          return;
        }
        if(response.data.result === 'duplicated'){
          alert('アカウントが重複して存在しています。\n管理者にお問い合わせください。');
          return;
        }
        if(response.data.result === 'ok'){
          alert('入力頂いたメールアドレスに一時パスワードをお送りしました。\n一時パスワードでログインし、パスワードを変更してください。');
          this.isPasswordReissuanceModalOpened = false;
          return;
        }
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.isLoading = false;
      });
    },
    login: function(){
      this.isLoading = true;
      this.axios.post(`/api/login`, {
        mailAddress: this.accountId,
        password: this.password,
      }).then(response => {
        if(response.data.result === 'not_found'){
          alert('該当するアカウントは存在しません。\nアカウント作成画面から新規登録してください。');
          return;
        }
        if(response.data.result === 'duplicated'){
          alert('アカウントが重複して存在しています。\n管理者にお問い合わせください。');
          return;
        }
        if(response.data.result === 'deleted'){
          alert('アカウントが削除されています。\n管理者にお問い合わせください。');
          return;
        }
        if(response.data.result === 'paused'){
          alert('アカウント利用が一時停止されています。\n管理者にお問い合わせください。');
          return;
        }
        if(response.data.result === 'reset_password') {
          this.isUpdatedPasswordDialogOpened = true;
          this.resetPasswordData.userId = response.data.userId;
          return;
        }
        if(response.data.result === 'no_password'){
          alert('一時パスワードを発行した後、15分以上が経過もしくは一時パスワードが異なるため、パスワードが無効になっています。\n「パスワードを忘れた方はこちら」から再設定してください。');
          return;
        }
        if(response.data.result === 'incorrect'){
          alert('パスワードが正しくありません');
          return;
        }
        if(response.data.result === 'ok'){
          this.setUser({
            ...response.data.user,
            role: response.data.role,
            hospital: response.data.hospital,
            department: response.data.department,
          })

          this.$cookies.set('token', response.data.access_token);
          this.axios.defaults.headers.common['Authorization'] = "Bearer " + response.data.access_token;

          this.axios.get('/api/office_workings').then(response => {
            this.setOfficeWorkings(response.data.officeWorkings);
          }).catch(error => {
            console.log(error);
          })

          this.axios.get('/api/working_arrangements').then(response => {
            this.setWorkingArrangements(response.data.workingArrangements);
          }).catch(error => {
            console.log(error);
          })
        }
      }).catch(error => {
        console.log(error);
        alert('ログインに失敗しました');
      }).finally(() => {
        this.isLoading = false;
      });
    },
    resetAccountPassword: function() {
      this.isLoading = true;
      this.axios.post(`/api/reset_password`, {
        userId: this.resetPasswordData.userId,
        oldPassword: this.resetPasswordData.oldPassword,
        newPassword: this.resetPasswordData.newPassword,
      }).then(response => {
        if(response.data.result === 'no_invalid'){
          alert('一時パスワードを発行した後、15分以上が経過もしくは一時パスワードが異なるため、パスワードが無効になっています。\n「パスワードを忘れた方はこちら」から再設定してください。');
          return;
        }
        if(response.data.result === 'ok'){
          this.password = this.resetPasswordData.newPassword;
          this.login();
        }
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.isLoading = false;
      });
    },
  }
};


</script>