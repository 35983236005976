<template>
  <v-dialog
    ref="dialog"
    v-model="isImageModalOpened"
  >
    <v-icon size=2.5em color="white" class="justify-end" @click="closeImageModal">mdi-close-thick</v-icon>
    <template v-slot:activator="{ on, attrs }">
      <v-img
        class="mb-1"
        :class="{
          'ml-3': isQuestioner,
          'mr-3': !isQuestioner,
        }"
        contain
        :src="src"
        :max-height="maxHeight"
        :position="position"
        v-bind="attrs"
        v-on="on"
      >
      </v-img>
    </template>
    <video
      v-if="videoUrl"
      :src="videoUrl"
      controls
      autoplay
    />
    <v-img
      v-else
      contain
      :src="src"
      max-height="90vh"
      @click="isImageModalOpened=false"
    >
    </v-img>
  </v-dialog>
</template>

<script>
export default {
  components: {
	},
  props: {
    image: {},
    isQuestioner: {},
    hasVideo: {
      default: false,
    },
    caseId: {},
    caseContentId: {},
  },
  data: function(){
    return {
      src: null,
      isImageModalOpened: false,
      videoUrl: null,
    }
  },
  watch: {
    isImageModalOpened: function(newVal){
      if(newVal && this.hasVideo){
        let url = this.caseContentId ? `/api/case_content/${this.caseContentId}/video_path` : `/api/case/${this.caseId}/video_path`;
        this.axios.get(url).then(response => {
          if(response.data.result !== 'ok'){
            alert(response.data.message);
            this.isImageModalOpened = false;
            return;
          }

          this.videoUrl = response.data.url;
        }).catch(error => {
          console.log(error);
        })
      }else{
        this.videoUrl = null;
      }
    },
  },
  computed: {
    maxHeight: function(){
      return this.$vuetify.breakpoint.mobile ? 150 : 250;
    },
    position: function(){
      return this.isQuestioner ? 'left center' : 'right center';
    },
  },
  mounted: function(){
    this.axios.get(this.image).then(response => {
      this.src = response.data;
    }).catch(error => {
      console.log(error);
    });
  },
  methods: {
    closeImageModal: function() {
      this.isImageModalOpened = false;
    }
  },
}
</script>
