<template>
  <div>
    <v-chip
      label
      outlined
      small
      color="primary"
    >
      {{ date }}
    </v-chip>
    <v-row dense class="d-flex align-center flex-wrap">
      <v-checkbox
        v-for="workingArrangement in filteredWorkingArrangements"
        :key="workingArrangement.id"
        @click.stop
        class="mx-5"
        :label="workingArrangement.title"
        v-model="attendanceMap[workingArrangement.id]"
        hide-details
        dense
        @change="switchAttendance(workingArrangement, $event)"
      ></v-checkbox>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    date: {
      required: true,
    }
  },
  data: function(){
    return {
      attendanceMap: {
      },
    }
  },
  computed: {
    ...mapState([
      'user',
      'hospitalItems',
      'workingArrangements',
    ]),
    filteredWorkingArrangements: function() {
      return this.workingArrangementsEachHospitals;
    },
    workingArrangementsEachHospitals: function() {
      let hospitalIds = this.getAffiliatedHospitalIds;
      let arr = [];
      for (let workingArrangement of this.workingArrangements) {
        if (!(hospitalIds.includes(workingArrangement.hospitalId))) continue;
        let title = workingArrangement.title;
        if(this.getAffiliatedHospitalIds.length > 1) {
          title += "【";
          title += this.getHopsitalShortName(workingArrangement.hospitalId);
          title += "】";
        }
        arr.push({
          "id": workingArrangement.id,
          "hospitalId": workingArrangement.hospitalId,
          "title": title,
        });
      }
      return arr;
    },
    getAffiliatedHospitalIds: function() {
      let affiliated_hospitals_id = [];
      for (let hospital of this.user.hospital) {
        affiliated_hospitals_id.push(hospital.id);
      }
      return affiliated_hospitals_id;
    },
    getHopsitalShortName: function(){
      return (id) => this.hospitalItems.find(i => i.id === id)?.shortName;
    },
  },
  watch: {
    date: {
      handler(newVal) {
        if(!newVal){
          return;
        }

        this.axios.get(`/api/working_arrangement_attendances/${newVal}`).then(response => {
          let attendanceMap = {};
          response.data.attendances.forEach(a => {
            attendanceMap[a.workingArrangementId] = true;
          })
          this.attendanceMap = attendanceMap;
        })
      },
      immediate: true
    }
  },
  mounted: function(){
  },
  methods: {
    switchAttendance: function(workingArrangement, newVal){
      if(newVal){
        this.axios.post(`/api/working_arrangement_attendance/${workingArrangement.id}/${this.date}`).then(() => {
          this.$emit('change');
        }).catch(error => {
          console.log(error);
          alert('業務形態更新に失敗しました');
        })
      }else{
        this.axios.delete(`/api/working_arrangement_attendance/${workingArrangement.id}/${this.date}`).then(() => {
          this.$emit('change');
        }).catch(error => {
          console.log(error);
          alert('業務形態更新に失敗しました');
        });
      }
      
    },
  }
  
}
</script>
