<template>
  <v-container fluid>
    <v-row v-if="user.isAlliance" justify="center" dense>
      <v-col v-bind="contentColParams">
        <div class="font-weight-bold text-h6">
          <span>
            現在の空床状況
            <v-btn @click="openBedStatusDefinitionHelp" class="ml-1" x-small depressed fab>
              <v-icon small dark>mdi-help-circle-outline</v-icon>
            </v-btn>
          </span>
          <span class="ml-5 font-weight-normal subtitle-2" style="white-space: nowrap;">
            取得時刻： {{ bedStatusesFetchTimeStr.slice(5) }}
            <v-btn @click="loadBedStatused" class="ml-1" x-small depressed fab>
              <v-icon small dark>mdi-refresh</v-icon>
            </v-btn>
          </span>
        </div>
        <v-row dense align="center" justify="start" class="mt-1">
          <v-col 
            v-for="hospitalItem in hospitalItemsForBedStatus" 
            :key="hospitalItem.value"
            cols=12
            md=12
            lg=6
          >
            <v-row dense >
              <v-col dense class="text-right py-0 my-0 subtitle-2">
                {{ hospitalItem.userName }}
                {{ hospitalItem.updatedDatetime ? hospitalItem.updatedDatetime.slice(5) : '' }}
              </v-col>
            </v-row>
            <v-row dense class="mt-0 mb-0" align="center">
              <v-col dense cols="6" class="pl-5 title">
                {{ hospitalItem.shortName }}
              </v-col>
              <v-col dense cols="6">
                <v-row dense justify="end" align="center">
                  <template v-if="isUserInHosptial(hospitalItem.id)">
                    <v-btn
                      dense
                      small
                      color="primary"
                      @click="updateBedStatus(hospitalItem)"
                    >
                      現状維持
                    </v-btn>
                    <v-select
                      dense
                      v-model="hospitalItem.bedStatus"
                      style='width: min-content; flex: initial;'
                      class="ml-5 mt-2"
                      @change="updateBedStatus(hospitalItem)"
                      item-text="label"
                      item-value="value"
                      :items="bedsStatusItems"
                    />
                  </template>
                  <template v-else>
                    <span class="text-h6">
                      {{ getBedsStatusLabel(hospitalItem.bedStatus) }}
                    </span>
                  </template>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="user.isAlliance" justify="center" dense>
      <v-col v-bind="contentColParams">
        <div class="font-weight-bold text-h6">
          緊急対応可能状況
        </div>
        <v-row dense align="start" justify="start" >
          <v-col cols=12 lg=6>
            <v-row dense class="mt-0 ml-3">
              <v-btn
                outlined
                @click="openAvailableUsersDialog"
              >
                現在の「緊急対応可能」医師リスト
              </v-btn>
            </v-row>
          </v-col>
          <v-col cols=12 lg=6>
            <v-row dense align="center" justify="start" class="mt-1 ml-1">
              {{ user.familyName }}さんの状況
            <!-- </v-row>
            <v-row dense align="center" justify="start" class="mb-3 ml-3"> -->
              <v-col dense>
                <v-chip 
                  class="subtitle-1"
                  :color="isInAvailableTime ? 'green' : 'red'"
                  dark
                >
                  <v-icon left>
                    {{ isInAvailableTime ? 'mdi-thumb-up' : 'mdi-phone-off' }}
                  </v-icon>
                  {{ isInAvailableTime ? '可能' : '不可' }}
                </v-chip>
                <span class="ml-5 subtitle-2">
                  <template v-if="isInAvailableTime">
                    ～{{ availableOriginalDateStr }}
                  </template>
                </span>
              </v-col>
              <v-dialog
                v-model="isAvailableDialogOpened"
                width="500"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small 
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                  >変更</v-btn>
                </template>
                <v-card>
                  <v-card-title class="text-h6">
                  </v-card-title>
                  <v-card-text>
                    <template v-if="isInAvailableTime">
                      <v-row dense justify="center">
                        <v-btn color="primary" @click="setAvailableTimeDisable">
                          取消し
                        </v-btn>
                      </v-row>
                      <v-divider class="my-5"/>
                    </template>
                    <v-row dense align="center">
                      <v-col dense cols="12" sm="auto">終了希望日時</v-col>
                      <v-col dense>
                        <DateSelector
                          v-model="availableDate"
                        />
                      </v-col>
                      <v-col dense>
                        <v-dialog
                          ref="availableTimeDialog"
                          v-model="isAvailableTimeSelectorModalOpened"
                          :return-value.sync="availableTime"
                          persistent
                          width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="availableTime"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              @click="isAvailableTimeSelectorModalOpened=true"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="isAvailableTimeSelectorModalOpened"
                            v-model="availableTime"
                            full-width
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="red"
                              @click="isAvailableTimeSelectorModalOpened=false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.availableTimeDialog.save(availableTime)"
                            >
                              OK
                            </v-btn>
                          </v-time-picker>
                        </v-dialog>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col dense cols="12">コメント</v-col>
                      <v-textarea
                        class="my-3 label-wrapped"
                        dense
                        v-model="availableOriginalComment"
                        label="ここに記載したコメントは「緊急対応可能」リストに記載されます。"
                        counter
                        maxlength="400"
                        full-width
                      />
                    </v-row>
                    <v-row dense justify="center">
                      <v-btn color="primary" @click="upsertAvailableTime">
                        {{ isInAvailableTime ? '内容更新' : '今から行けます！' }}
                      </v-btn>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center" dense>
      <v-col v-bind="cardColParams" dense v-if="user.role.isDoctor">
        <v-card dense>
          <v-toolbar dark flat dense class="font-weight-bold text-h6">
            <router-link to="/cases" class="text-decoration-none white--text">
              症例掲示板
              <v-icon class="ml-2">mdi-open-in-new</v-icon>
            </router-link>
          </v-toolbar>
          <v-card-text dense class="text-center text-subtitle-1">
            <template v-if="user.isAlliance">
              未対応件数
              <span class="red--text text-h5">
                {{ uncompletedCases.length }}
              </span>
              件
            </template>
            <template v-else>
              <v-row dense justify="center">
                <v-col dense>
                  投稿数
                  <span class="red--text text-h5">
                    {{ cases.length }}
                  </span>
                  件
                </v-col>
              </v-row>
              <v-row dense v-if="latestCase" justify="center">
                <v-col dense>
                  最新投稿
                  <span class="ml-3">ID</span>
                  <span class="red--text text-h5">
                    {{ latestCase.id }}
                  </span>
                  <span class="ml-3">タイトル</span>
                  <span class="red--text text-h5">
                    {{ latestCase.title }}
                  </span>
                </v-col>
              </v-row>
            </template>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col v-bind="cardColParams" v-if="user.isAlliance">
        <v-card dense class="pb-3">
          <v-toolbar dark flat dense class="font-weight-bold text-h6">
            <router-link to="/schedules" class="text-decoration-none white--text">
              スケジュール管理
              <v-icon class="ml-2">mdi-open-in-new</v-icon>
            </router-link>
          </v-toolbar>
          <v-card-subtitle class="py-3">
            本日の予定  
          </v-card-subtitle>
          <v-card-text 
            dense 
            class="text-subtitle-1 py-0 d-flex"
            v-for="(schedule, index) in schedules"
            :key="index"
          >
            <span>{{ schedule.from }} - {{ schedule.to }}</span>
            <span class="ml-auto">{{ schedule.caption }}</span>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center" dense>
      <v-col v-if="user.role.isDoctor && user.isAlliance" v-bind="cardColParams" dense>
        <v-card dense>
          <v-toolbar dark flat dense class="font-weight-bold text-h6">
            <router-link to="/attendances" class="text-decoration-none white--text">
              勤怠管理
              <v-icon class="ml-2">mdi-open-in-new</v-icon>
            </router-link>
          </v-toolbar>
          <v-card-text dense class="text-center text-subtitle-1">
            今月の残業時間
            <span class="red--text text-h5">
              {{ hoursWorkingOverTime }}h{{ minutesWorkingOverTime }}m / 80h
            </span>
          </v-card-text>
        </v-card>
        <v-card dense v-if="user.role.isAdmin" class="mt-2">
          <v-toolbar dark flat dense class="font-weight-bold text-h6">
            <router-link to="/users" class="text-decoration-none white--text">
              ユーザ管理
              <v-icon class="ml-2">mdi-open-in-new</v-icon>
            </router-link>
          </v-toolbar>
          <v-container fluid>
            <v-row class="d-flex align-center" dense>
              <v-col cols="8">新規登録依頼件数</v-col>
              <v-col cols="2" class="red--text text-h5 text-right">
                {{ accountRequests.new }}
              </v-col>
              <v-col cols="2">件</v-col>
            </v-row>
            <v-row class="d-flex align-center" dense>
              <v-col cols="8">更新依頼件数</v-col>
              <v-col cols="2" class="red--text text-h5 text-right">
                {{ accountRequests.update }}
              </v-col>
              <v-col cols="2">件</v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
      <v-col v-bind="cardColParams" dense v-if="user.role.isManager">
        <v-card dense>
          <v-toolbar dark flat dense class="font-weight-bold text-h6">
            <router-link to="/attendance/summary" class="text-decoration-none white--text">
              勤怠サマリー
              <v-icon class="ml-2">mdi-open-in-new</v-icon>
            </router-link>
          </v-toolbar>
          <v-row dense>
            <v-col cols="6" dense>
              <PieChart
                :height="300"
                :chart-data="chartData"
                :options="chartOptions"
              />
            </v-col>
            <v-col dense>
              <v-row dense class="my-3">内訳</v-row>
              <v-row v-for="task in taskBreakdown" :key="task.categoryName" dense class="pl-3">
                <v-col dense cols="7">{{task.categoryName}}</v-col>
                <v-col dense cols="5">{{task.percentage}}%</v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center" dense>
      <v-btn
        class="text-decoration-underline mt-5"
        depressed
        color="white"
        to="/account"
      >
        > 登録情報の変更
      </v-btn>
    </v-row>

    <v-dialog
      v-model="isBedStatusHelpDialogOpened"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h6">
          空床状況: 記号の定義
        </v-card-title>
        <v-card-text>
          ◎：回路組んでしまった、などどうしても症例がほしいとき<br />
          〇：空床あり（即時対応可）<br />
          △：空床あり（即時対応困難、準緊急で対応可）<br />
          ✕ ：空床なし （対応困難）
        </v-card-text>
        <v-card-text class="text-center">
          <v-btn 
            outlined
            color="primary"
            text 
            @click.stop="closeBedStatusDefinitionHelp"
          >閉じる</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="isAvailableUsersDialogOpened"
    >
      <v-card>
        <AvailableUserTable
          :users="availableUsers"
          :isLoading="isAvailableUsersLoading"
        />
      </v-card>
    </v-dialog>

    <v-overlay :value="isLoading">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import { getDateStr, getDatetimeStr, getTimeStr } from '@js/utils/StringUtil.js';
import AvailableUserTable from "@components/parts/tables/AvailableUserTable.vue";
import PieChart from "@components/parts/charts/PieChart.vue";
import DateSelector from '@components/inputs/DateSelector.vue';

import moment from 'moment';

export default {
  components: {
    AvailableUserTable,
    PieChart,
    DateSelector,
  },
  data: function(){
    return {
      cardColParams: {
        cols: 12,
        sm: 6,
        md: 5,
        lg: 4
      },
      contentColParams: {
        cols: 12,
        sm: 12,
        md: 10,
        lg: 8
      },
      urgencyTasks: {
        'emergency': 3,
        'semi-emergency': 1,
        'normal': 4,
      },
      bedStatuses: {
        "st_luke": "empty",
        "toranomon": "few",
        "tokyo_bay": "plenty",
      },
      accountRequests: {
        "new": 0,
        "update": 0,
      },
      taskBreakdown: [],
      chartData: {},
      chartOptions: {
        responsive: true,
        responsiveAnimationDuration: 0,
        maintainAspectRatio: false,
        layout: {
          padding: 0
        },
        title: {
          display: true,
          position: "top",
          fontSize: 12,
          padding: 10,
          text: "今月の総勤務時間比率",
        },
        legend: {
          display: true,
          position: "bottom",
        },
        tooltips: {
          display: true,
        }
      },
      schedules: [],
      
      bedStatusesFetchTime: new Date(),
      isAvailableDialogOpened: false,
      isBedStatusHelpDialogOpened: false,

      isAvailableDateSelectorModalOpened: false,
      availableDate: null,

      isAvailableTimeSelectorModalOpened: false,
      availableTime: null,
      availableComment: null,
      
      availableOriginalDate: null,
      availableOriginalComment: null,
      now: new Date(),

      isAvailableUsersDialogOpened: false,

      isAvailableUsersLoading: false,
      availableUsers: [],

      isLoading: false,

      cases: [],
    }
  },
  computed: {
    ...mapState([
      'facilityItems',
      'hospitalItems',
      'bedsStatusItems',
      'user',
      'taskCategoryItems',

      'monthlyWorkingTime',
      'totalWorkingOvertime',
      'hoursWorkingOverTime',
      'minutesWorkingOverTime',
      'residualWorkingOvertimeRatio',
    ]),
    ...mapGetters([
      'validMenus',
    ]),
    isUserInHosptial: function(){
      return hospitalId => this.user?.hospital?.some(i => i.id == hospitalId)
    },
    hospitalItemsForBedStatus: function(){
      return this.hospitalItems.filter(h => h.hasBedStatus)
    },
    getBedsStatusLabel: function(){
      return val => this.bedsStatusItems.find(i => i.value === val)?.label
    },
    bedStatusesFetchTimeStr: function(){
      return getDatetimeStr(this.bedStatusesFetchTime);
    },
    isInAvailableTime: function(){
      if(!this.availableOriginalDate){
        return false;
      }

      return this.now < this.availableOriginalDate;
    },
    availableOriginalDateStr: function(){
      return getDatetimeStr(this.availableOriginalDate).slice(5);
    },
    uncompletedCases: function(){
      return this.cases.filter(c => !c.isCompleted);
    },
    latestCase: function(){
      let cases = [...this.cases];
      cases.sort((a, b) => a.createdTime < b.createdTime ? 1 : -1);
      return cases[0];
    },
  },
  watch: {
    hospitalItems: function(newVal, oldVal){
      if(newVal?.length !== oldVal?.length){
        this.loadBedStatused();
      }
    },
    availableOriginalDate: {
      handler: function(newVal){
        let date = moment(newVal).toDate();
        if(!newVal){
          date = new Date();
          date.setHours(7);
          date.setMinutes(0);
          date.setDate(date.getDate() + 1);
        }
        this.availableDate = getDateStr(date);
        this.availableTime = getTimeStr(date);
      },
      immediate: true,
    },
    availableOriginalComment: function(newVal){
      this.availableComment = newVal;
    },
    taskCategoryItems: {
      handler: function(newVal){
        if(newVal.length == 0){
          return;
        }
        this.loadAttendanceSummary();
      },
      immediate: true,
    },
  },
  destroyed: function(){
  },
  mounted: function(){
    this.loadCases();
    if(!this.user.isAlliance) {
      return;
    }
    this.loadBedStatused();
    this.loadAvailableTime();
    this.loadMonthlyWorkingTime();
    this.loadTodaysSchedule();
    this.loadRequestUserCounts();
  },
  methods: {
    ...mapActions([
      'fetchAttendanceTime',
    ]),
    updateBedStatus: function(hospital){
      this.axios.post('/api/bed_status', {
        hospitalId: hospital.id,
        value: hospital.bedStatus
      }).then(() => {
        hospital.userName = `${this.user?.familyName ?? ''} ${this.user?.firstName ?? ''}`;
        hospital.updatedDatetime = getDatetimeStr(new Date());
        this.$forceUpdate();
      }).catch(error => {
        console.log(error);
      });
    },
    openBedStatusDefinitionHelp: function() {
      this.isBedStatusHelpDialogOpened = true;
    },
    closeBedStatusDefinitionHelp: function() {
      this.isBedStatusHelpDialogOpened = false;
    },
    loadAvailableTime: function(){
      this.axios.get('/api/available_time').then(response => {
        let till = response.data.availableTime?.till;
        this.availableOriginalDate = till ? moment(till).toDate() : null;
        this.availableOriginalComment = response.data.availableTime?.comment;
      }).catch(error => {
        console.log(error);
      });
    },
    loadBedStatused: function(){
      this.axios.get('/api/bed_statuses').then(response => {
        response.data.statuses.forEach(s => {
          let hospital = this.hospitalItems.find(h => h.id === s.hospitalId);
          if(!hospital){
            return;
          }
          hospital.bedStatus = s.status;
          hospital.userName = `${s.user?.familyName ?? ''} ${s.user?.firstName ?? ''}`;
          hospital.updatedDatetime = getDatetimeStr(moment(s.createdTime).toDate());
        });
        this.$forceUpdate();
        this.bedStatusesFetchTime = new Date();
      }).catch(error => {
        console.log(error);
      })
    },
    loadMonthlyWorkingTime: function() {
      this.fetchAttendanceTime();
    },
    loadRequestUserCounts: function() {
      this.axios.get("/api/requests_user_counts").then(response => {
        this.accountRequests = response.data.accountRequests;
      }).catch(error => {
        console.log(error);
      });
    },
    upsertAvailableTime: function(){
      this.axios.post('/api/available_time', {
        till: `${this.availableDate} ${this.availableTime}:00`,
        comment: this.availableComment
      }).then(() => {
        let till = new Date();
        let dateParams = this.availableDate.split('-');
        till.setFullYear(parseInt(dateParams[0]));
        till.setDate(1);
        till.setMonth(parseInt(dateParams[1]) - 1);
        till.setDate(parseInt(dateParams[2]));

        let timeParams = this.availableTime.split(':');
        till.setHours(parseInt(timeParams[0]));
        till.setMinutes(parseInt(timeParams[1]));
        till.setSeconds(0);

        this.availableOriginalDate = till;
        this.availableOriginalComment = this.availableComment;
      }).catch(error => {
        console.log(error);
      })

      this.isAvailableDialogOpened = false;
    },
    setAvailableTimeDisable: function(){
      let now = new Date();

      this.axios.post('/api/available_time', {
        till: getDatetimeStr(now).split('/').join('-') + ':00',
        comment: this.availableOriginalComment
      }).then(() => {
        this.availableOriginalDate = null;
        this.availableOriginalComment = null
      }).catch(error => {
        console.log(error);
      })
      this.isAvailableDialogOpened = false;
    },
    openAvailableUsersDialog: function(){
      this.isAvailableUsersLoading = true;
      this.axios.get('/api/available_users').then(response => {
        this.availableUsers = response.data.users;
        this.isAvailableUsersDialogOpened = true;
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.isAvailableUsersLoading = false;
      })
    },
    loadTodaysSchedule: function(){
      this.axios.get(`/api/schedules/today`).then(response => {
        this.schedules = response.data.schedules.map(s => {
          let caption = '';

          if(s.scheduleType === 'facility'){
            let facility = this.facilityItems.find(i => i.id == s.facilityId);
            let hospital = this.hospitalItems.find(i => i.id == s.hospitalId);
            let title = facility?.isTitleNecessary ? ' ' + s.title : ''
            caption = `${hospital?.shortName} ${facility?.label}${title ?? ''}`;
          }

          if(s.scheduleType === 'individual'){
            caption = s.title;
          }

          return {
            caption: caption,
            from: s.startTime.slice(-8, -3),
            to: s.endTime.slice(-8, -3),            
          }
        });
      }).catch(error => {
        console.log(error);
      })
    },
    loadAttendanceSummary: function() {
      this.axios.get('/api/attendance_summary').then(response => {
        // 大カテゴリ
        this.chartData = this.createGraph(
          response.data.category, this.taskCategoryItems
        );
      }).catch(error => {
        console.log(error);
      });
    },
    createGraph: function(categoryKind, categoryItems) {
      let categoryIds = Object.keys(categoryKind);
      let chartDataLabels = [];
      let chartDataValue = [];
      let chartDataColor = [];

      for (let categoryId of categoryIds) {
        let category = this.getCategory(categoryItems, categoryId);
        let idx = chartDataLabels.indexOf(category.caption)
        let value = categoryKind[categoryId]
        if (idx == -1){
          chartDataLabels.push(category.caption);
          chartDataValue.push(value);
          chartDataColor.push(category.colorHexadecimal);
          continue;
        }
        chartDataValue[idx] += value;
      }
      chartDataValue = this.getChartDataValue(chartDataValue);

      this.taskBreakdown = chartDataLabels.map(function(elem, idx) {
        return {
          "categoryName": elem,
          "percentage": chartDataValue[idx]
        }
      });
      return this.createDataset(chartDataLabels, chartDataValue, chartDataColor);
    },
    getCategory: function(categoryKind, id) {
      return categoryKind.find(i => i.id == id)
    },
    getChartDataValue: function(eachValue) {
      let chartDataValueSum = eachValue.reduce((acc, cur) => acc + cur);
      eachValue = eachValue.map(element => {
        return Math.round(element / chartDataValueSum * 1000) / 10
      });
      return eachValue;
    },
    createDataset: function(labels,values, colors) {
      return {
        datasets: [
          {
            backgroundColor: colors,
            borderColor: "#FFFFFF",
            borderWidth: 1,
            data: values,
          }
        ],
        labels: labels,
      }
    },
    loadCases: function(){
      this.axios.get(`/api/cases`).then(response => {
        this.cases = response.data.cases;
      }).catch(error => {
        console.log(error);
      })
    },
  }
}
</script>

<style scoped>
</style>