<script>
import { Pie, mixins } from "vue-chartjs";

export default {
  mixins: [
    Pie, 
    mixins.reactiveProp,
  ],
  props: {
    chartData: {},
    options: {},
  },
  mounted: function(){
    if (this.chartData) {
      this.renderChart(this.chartData, this.options);
    }
  },
  watch: {
    chartData: function(){
      if (this.chartData) {
        this.renderChart(this.chartData, this.options);
      }
    }
  }
}
</script>