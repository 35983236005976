import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import './registerServiceWorker'
import { createRouter } from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCookies from 'vue-cookies'
import VueObserveVisibility from 'vue-observe-visibility'

import 'vue2-timepicker/dist/VueTimepicker.css'

import moment from 'moment';

Vue.config.productionTip = false
Vue.use(Vuex);
Vue.use(VueCookies)
Vue.use(VueObserveVisibility)
Vue.$cookies.config('10y')

const myAxios = axios.create();
if(process.env.NODE_ENV === 'localhost'){
  myAxios.defaults.baseURL = "http://localhost";
}
Vue.use(VueAxios, myAxios);

const store = new Vuex.Store({
  state: {
    user: null,
    menus: [
      { title: 'ホーム', icon: 'mdi-home', url: '/'},
      { title: '症例掲示板', icon: 'mdi-clipboard-text', url: '/cases', role: 'isDoctor'},
      { title: 'スケジュール管理', icon: 'mdi-calendar', url: '/schedules', isAlliance: true},
      { title: '勤怠管理', icon: 'mdi-timetable', url: '/attendances', role: 'isDoctor', isAlliance: true},
      { title: 'ユーザ管理', icon: 'mdi-account-group', url: '/users', role: 'isAdmin', isAlliance: true},
      { title: '勤怠サマリー', icon: 'mdi-chart-pie', url: '/attendance/summary', role: 'isManager', isAlliance: true},
    ],
    hospitalItems: [],
    facilityItems: [
      {id: 1, label: "施設全体", isTitleNecessary: true, value: "all"},
      //value === 'operating_room'でタイトルに手術名を使うか否かを制御しているため要注意
      {id: 2, label: "手術室", isTitleNecessary: true, value: "operating_room"},
      {id: 3, label: "当直・オンコール", isTitleNecessary: false, hasTitleParticipants: true, value: "on_call"},
      {id: 4, label: "昼外来", isTitleNecessary: false, hasTitleParticipants: true, value: "outpatient"},
      {id: 5, label: "昼病棟", isTitleNecessary: false, hasTitleParticipants: true, value: "inpatient"},
    ],
    departmentItems: [],

    taskCategoryItems: [],
    taskSubCategoryItems: [],
    taskSubSubCategoryItems: [],
    bedsStatusItems: [],
    allianceUsers: [],

    officeWorkings: [],
    workingArrangements: [],

    
    monthlyWorkingTime: {},
    totalWorkingOvertime: 0,
    hoursWorkingOverTime: 0,
    minutesWorkingOverTime: 0,
    residualWorkingOvertimeRatio: 0,
  },
  getters: {
    validMenus(state) {
      let user = state.user;
      if(!user || !user.role){
        return [];
      }
      return state.menus.filter(m => {
        let isRoleMatched = !('role' in m) || (user.role && user.role[m.role]);
        let isAllianceMatched = !m.isAlliance || user.isAlliance;
        return isRoleMatched && isAllianceMatched;
      });
    },
  },
  mutations: {
    setUser(state, val) {
      state.user = val;
    },
    setAllianceUsers(state, val) {
      state.allianceUsers = val;
    },
    setHospitalItems(state, val) {
      state.hospitalItems = val;
    },
    setBedsStatusItems(state, val) {
      state.bedsStatusItems = val;
    },
    setDepartmentItems(state, val){
      state.departmentItems = val;
    },
    setCategoryItems(state, val) {
      state.taskCategoryItems = val
    },
    setSubCategoryItems(state, val) {
      state.taskSubCategoryItems = val
    },
    setSubSubCategoryItems(state, val) {
      state.taskSubSubCategoryItems = val
    },

    setOfficeWorkings(state, val){
      state.officeWorkings = val
    },
    setWorkingArrangements(state, val){
      state.workingArrangements = val
    },

    fetchAttendanceTime(state){
      let today = new Date();
      let thisYear = today.getFullYear();
      let thisMonth = today.getMonth()+1;
      let totalWorkingOvertime = 0;

      const REGULAR_WORKING_TIME_PER_DAY = 600;
      const REGULAR_OVER_WORKING_TIME_PER_MONTH = 4800;
      axios.get('https://holidays-jp.github.io/api/v1/date.json').then(response => {
        let jpHolidays = response.data;
        Vue.axios.get('/api/daily_attendance_time/' + thisYear +  '/' + thisMonth).then(response => {
          state.monthlyWorkingTime = response.data.attendances;
          Object.keys(state.monthlyWorkingTime).map(key => {
            // 曜日を取得
            let thisDay = moment(key).toDate().getDay()

            // 祝日の場合、全労働時間を残業時間にカウント
            if(key in jpHolidays) {
              totalWorkingOvertime += state.monthlyWorkingTime[key];
            }
            // 土曜か日曜ならそのまま全労働時間を残業時間にカウント
            else if(thisDay == 0 || thisDay == 6) {
              totalWorkingOvertime += state.monthlyWorkingTime[key];
            }
            else if(state.monthlyWorkingTime[key] > REGULAR_WORKING_TIME_PER_DAY) {
              totalWorkingOvertime += state.monthlyWorkingTime[key] - REGULAR_WORKING_TIME_PER_DAY;
            }
          });
          state.totalWorkingOvertime = totalWorkingOvertime;
          state.hoursWorkingOverTime = Math.floor(totalWorkingOvertime / 60);
          state.minutesWorkingOverTime = totalWorkingOvertime % 60;
          state.residualWorkingOvertimeRatio = totalWorkingOvertime / REGULAR_OVER_WORKING_TIME_PER_MONTH * 100
        }).catch(error => {
          console.log(error);
        })
      }).catch(error => {
        alert('祝日の取得に失敗しました。')
        console.log(error);
      });
    },
  },
  actions: {
    setUser({ commit }, val) {
      commit('setUser', val);
    },
    setAllianceUsers({ commit }, val) {
      commit('setAllianceUsers', val);
    },
    setHospitalItems({ commit }, val) {
      commit('setHospitalItems', val);
    },
    setBedsStatusItems({ commit }, val) {
      commit('setBedsStatusItems', val);
    },
    setDepartmentItems({ commit }, val) {
      commit('setDepartmentItems', val);
    },
    setCategoryItems({ commit }, val) {
      commit('setCategoryItems', val);
    },
    setSubCategoryItems({ commit }, val) {
      commit('setSubCategoryItems', val);
    },
    setSubSubCategoryItems({ commit }, val) {
      commit('setSubSubCategoryItems', val);
    },
    setOfficeWorkings({ commit }, val) {
      commit('setOfficeWorkings', val);
    },
    setWorkingArrangements({ commit }, val) {
      commit('setWorkingArrangements', val);
    },
    fetchAttendanceTime({ commit }) {
      commit('fetchAttendanceTime');
    },
  },
});

const router = createRouter();

new Vue({
  vuetify,
  router,
  render: h => h(App),
  store
}).$mount('#app')
