<template>
  <v-dialog
    ref="dialog"
    v-model="isModalOpened"
    :return-value.sync="date"
    width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="date"
        :label="label"
        :dense="dense != null"
        :hide-details="hideDetails != null"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      no-title
      scrollable
    >
      <v-spacer></v-spacer>
      <v-btn
        text
        color="red"
        @click="isModalOpened = false"
      >
        Cancel
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="$refs.dialog.save(date)"
      >
        OK
      </v-btn>
    </v-date-picker>
  </v-dialog>
</template>

<script>

export default {
  components: {
	},
  props: {
    value: {},
    label: {},
    dense: {
      default: null,
    },
    hideDetails: {
      default: null,
    },
  },
  data: function(){
    return {
      isModalOpened: false,
    }
  },
  computed: {
    date: {
      get() {
        return this.value;
      },
      set(newVal) {
        if (this.value !== newVal){
          this.$emit('input', newVal);
        }
      }
    }
  },
  watch: {
  },
  mounted: function(){
  },
  methods: {
  }
}
</script>