<template>
  <div>
    <v-chip
      label
      outlined
      small
      color="primary"
    >
      {{ date }}
    </v-chip>
    <v-row dense>
      <v-col class="d-flex align-center flex-wrap">
        <div 
          v-for="officeWorking in fileteredOfficeWorkings"
          :key="officeWorking.id"
          class="time-picker-container d-flex align-center mx-5 mt-2"
        >
          <div @click.stop>
            <vue-timepicker
              class="time-picker"
              v-model="attendanceMap[officeWorking.id]"
              :minute-interval="15"
              @close="upsertOfficeWorkingAttendance(officeWorking, $event)"
            />
          </div>
          <div class="ml-3 text-subtitle-2">{{ officeWorking.title }}</div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import VueTimepicker from 'vue2-timepicker'

export default {
  components: {
    VueTimepicker,
  },
  props: {
    date: {
      required: true,
    }
  },
  data: function(){
    return {
      attendanceMap: {
      },
    }
  },
  computed: {
    ...mapState([
      'user',
      'hospitalItems',
      'officeWorkings',
    ]),
    fileteredOfficeWorkings: function() {
      return this.officeWorkingEachHospitals;
    },
    officeWorkingEachHospitals: function() {
      let hospitalIds = this.getAffiliatedHospitalIds;
      let arr = [];
      for (let officeWorking of this.officeWorkings) {
        if (!(hospitalIds.includes(officeWorking.hospitalId))) continue;
        let title = officeWorking.title;
        if(this.getAffiliatedHospitalIds.length > 1) {
          title += "【";
          title += this.getHopsitalShortName(officeWorking.hospitalId);
          title += "】";
        }
        arr.push({
          "id": officeWorking.id,
          "hospitalId": officeWorking.hospitalId,
          "title": title,
        });
      }
      return arr;
    },
    getAffiliatedHospitalIds: function() {
      let affiliated_hospitals_id = [];
      for (let hospital of this.user.hospital) {
        affiliated_hospitals_id.push(hospital.id);
      }
      return affiliated_hospitals_id;
    },
    getHopsitalShortName: function(){
      return (id) => this.hospitalItems.find(i => i.id === id)?.shortName;
    },
  },
  watch: {
    date: {
      handler(newVal) {
        if(!newVal){
          return;
        }

        this.axios.get(`/api/office_working_attendances/${newVal}`).then(response => {
          let attendanceMap = {};
          response.data.attendances.forEach(a => {
            attendanceMap[a.officeWorkingId] = {
              HH: ("0" + Math.floor(a.minutes/60)).slice(-2),
              mm: ("0" + a.minutes%60).slice(-2)
            }
          })
          this.attendanceMap = attendanceMap;
        })
      },
      immediate: true
    }
  },
  mounted: function(){
  },
  methods: {
    upsertOfficeWorkingAttendance: function(officeWorking){
      let hours = parseInt(this.attendanceMap[officeWorking.id].HH);
      if(isNaN(hours)){
        this.attendanceMap[officeWorking.id].HH = '00';
        hours = 0;
      }

      let minutes = parseInt(this.attendanceMap[officeWorking.id].mm);
      if(isNaN(minutes)){
        this.attendanceMap[officeWorking.id].mm = '00';
        minutes = 0;
      }

      this.axios.post(`/api/office_working_attendance`, {
        date: this.date,
        officeWorkingId: officeWorking.id,
        minutes: minutes + hours*60,
      }).then(() => {

      }).catch(error => {
        console.log(error);
        alert('事務作業時間の更新に失敗しました');
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.time-picker-container{
  .time-picker{
    width: 80px;
    ::v-deep input.display-time{
      width: 100%;
    }
  } 
}
</style>