import Vue from 'vue';
import Router from 'vue-router';

import Top from '@pages/Top.vue';

import Cases from '@pages/Cases.vue';
import CaseCreated from '@pages/CaseCreated.vue';
import CaseRegistration from '@pages/CaseRegistration.vue';
import CaseDetail from '@pages/CaseDetail.vue';

//import Attendances from '@pages/Attendances.vue';
import SimpleAttendances from '@pages/SimpleAttendances.vue';
import Schedules from '@pages/Schedules.vue';

import AttendancesSummary from '@pages/AttendancesSummary.vue';
import Account from '@pages/Account.vue';
import UserManagement from '@pages/UserManagement.vue';


Vue.use(Router);

const createRouter = () => {
	return new Router({
		mode: 'history',
		routes: [
			{ 
				path: '/', 
				component: Top,
				meta: {
					isKeepPage: true,
				}
			},
			{ 
				path: '/cases', 
				component: Cases,
				meta: {
					title: '症例掲示板一覧',
					isKeepPage: true,
				}
			},
			{ 
				path: '/case/registration', 
				component: CaseRegistration,
				meta: {
					title: '症例掲示板投稿',
					isKeepPage: true,
				}
			},
			{
				path: '/case/created/:caseId', 
				component: CaseCreated,
				meta: {
					title: '症例投稿完了',
					isKeepPage: true,
				},
				props: (route) => {
          const caseId = Number.parseInt(route.params.caseId);
          if (Number.isNaN(caseId)) {
            return 0
          }
          return { caseId }
				},
			},
			{ 
				path: '/case/detail/:caseId', 
				component: CaseDetail,
				meta: {
					title: '症例掲示板詳細',
					isKeepPage: true,
				},
        props: (route) => {
          const caseId = Number.parseInt(route.params.caseId);
          if (Number.isNaN(caseId)) {
            return 0
          }
          return { caseId }
				}
			},
			{ 
				path: '/attendances', 
				component: SimpleAttendances,
				meta: {
					title: '勤怠管理',
					isKeepPage: true,
				}
			},
			{ 
				path: '/attendance/summary', 
				component: AttendancesSummary,
				meta: {
					title: '勤怠サマリー',
					isKeepPage: true,
				}
			},
			{ 
				path: '/schedules', 
				component: Schedules,
				meta: {
					title: 'スケジュール管理',
					isKeepPage: true,
				}
			},
			{ 
				path: '/account', 
				component: Account,
				meta: {
					title: '登録情報の変更',
					isKeepPage: true,
				}
			},
			{ 
				path: '/users', 
				component: UserManagement,
				meta: {
					title: 'ユーザ管理',
					isKeepPage: true,
				}
			},
		],
	});
};

export { createRouter };