<template>
  <v-container fluid>
    <v-row align="center" justify="center" dense>
      <v-col>
        <v-tabs
          v-model="currentTabIndex"
          dense
        >
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab
            v-for="tab in tabs"
            :key="tab.value"
          >
            {{ tab.label }}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-data-table
      v-if="currentTab.value === 'requests'"
      dense
      :loading="isRequestsLoading"
      :headers="requestsHeaders"
      :items="filteredRequests"
      :page.sync="currentPage"
      :itemsPerPage="itemsPerPage"
      class="elevation-1"
      @click:row="openRequestDetail"
    >
      <template v-slot:body.prepend>
        <tr v-if="!$vuetify.breakpoint.mobile">
          <td v-for="header in requestsHeaders" :key="header.value">
            <template v-if="header.value === 'userId'">
              <v-select
                v-model="filterParamsForRequests['userId']"
                :hint="header.text"
                dense
                class="text-no-wrap"
                :items="[{label: '', value: null}, {label: '変更', value: true}, {label: '新規', value: false}]"
                item-text="label"
                item-value="value"
                persistent-hint
              ></v-select>
            </template>
            <template v-else-if="header.filterable">
              <v-text-field
                dense
                v-model="filterParamsForRequests[header.value]"
                :hint="header.text"
                single-line
                persistent-hint
              ></v-text-field>
            </template>
          </td>
        </tr>
      </template>
      <template v-slot:item.userId="props">
        {{ isUsers(props.item.userId) ? '変更' : '新規' }}
      </template>
      <template v-slot:item.name="props">
        {{ props.item.familyName }} {{ props.item.firstName }}
      </template>
      <template v-slot:item.detail.hosptial="props">
        {{ props.item.isAlliance ? props.item.detail.hospital.map(h => getHopsitalShortName(h)).join(", ") : props.item.detail.hospital }}
      </template>
      <template v-slot:item.affiliation="props">
        {{ props.item.isAlliance ? Object.keys(props.item.detail.department).map(h => getDepartmentName(props.item.detail.department[h])).join(', ') : props.item.detail.department }}
      </template>
      <template v-slot:item.createdTime="props">
        {{ getElapsedTimeLabel(props.item.createdTime) }}
      </template>
    </v-data-table>

    <v-data-table
      v-if="currentTab.value === 'users'"
      dense
      :loading="isUsersLoading"
      :headers="customizedUsersHeaders"
      :items="filteredUsers"
      :page.sync="currentPage"
      :itemsPerPage="itemsPerPage"
      class="elevation-1"
      @click:row="openUserDetail"
    >
      <template v-slot:body.prepend>
        <tr v-if="!$vuetify.breakpoint.mobile">
          <td v-for="header in customizedUsersHeaders" :key="header.value">
            <template v-if="header.value === 'isAlliance'">
              <v-select
                v-model="filterParamsForUsers['isAlliance']"
                :hint="header.text"
                dense
                class="text-no-wrap"
                :items="[{label: '', value:null}, {label: '内', value:true}, {label: '外', value:false}]"
                item-text="label"
                item-value="value"
                persistent-hint
              ></v-select>
            </template>
            <template v-else-if="header.value === 'roleId'">
              <v-select
                v-model="filterParamsForUsers['roleId']"
                :hint="header.text"
                dense
                class="text-no-wrap"
                :items="[{id: null, label: ''}, {id: 1, label: '管理者'}, {id: 2, label: 'マネージャー'}, {id: 3, label: '一般'}]"
                item-text="label"
                item-value="id"
                persistent-hint
              ></v-select>
            </template>
            <template v-else-if="hospitalItems.some(i => `requester${i.id}` === header.value)">
              <v-select
                v-model="filterParamsForUsers[header.value]"
                dense
                :items="[{label: '', value:null}, {label: 'True', value:true}, {label: 'False', value:false}]"
                item-text="label"
                item-value="value"
                persistent-hint
              ></v-select>
            </template>
            <template v-else-if="header.filterable">
              <v-text-field
                dense
                v-model="filterParamsForUsers[header.value]"
                :hint="header.text"
                single-line
                persistent-hint
              ></v-text-field>
            </template>
          </td>
        </tr>
      </template>
      <template v-slot:item.menu="props">
        <v-btn 
          fab
          :disabled="props.item.isTitleSecret && props.item.userId != user.id"
          depressed 
          x-small 
          class="mb-1"
          @click="deleteUserConfirmation(props.item)"
        >
          <v-icon small>mdi-trash-can</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.hosptial="props">
        <template v-if="props.item.isAlliance">
          {{ props.item.departments.map(d => getHopsitalShortNameByDepartmentId(d.departmentId)).join(', ') }}
        </template>
        <template v-else>
          {{ props.item.departments.map(d => getOutsideHopsitalNameByDepartmentId(d.departmentId)).join(', ') }}
        </template>
      </template>
      <template v-slot:item.affiliation="props">
        <template v-if="props.item.isAlliance">
          {{ props.item.departments.map(d => getDepartmentName(d.departmentId)).join(', ') }}
        </template>
        <template v-else>
          {{ props.item.departments.map(d => getOutsideDepartmentName(d.departmentId)).join(', ') }}
        </template>
      </template>
      <template v-slot:item.isAlliance="props">
        {{ props.item.isAlliance ? '内' : '外' }}
      </template>
      <template v-slot:item.roleId="props">
        <v-select
          v-model="props.item.roleId"
          dense
          class="text-no-wrap"
          :items="roles"
          item-text="name"
          item-value="id"
          @change="updateRoleId(props.item.id, $event)"
        ></v-select>
      </template>
      <template v-slot:item.isPaused="props">
        <v-switch
          v-model="props.item.isPaused"
          @change="updateUserPause(props.item.id, $event)"
          small
          dense
          inset
        ></v-switch>
      </template>
      <template
        v-for="hospitalItem in hospitalItems"
        v-slot:[`item.requester${hospitalItem.id}`]="props"
      >
        <v-switch
          v-if="props.item.isAlliance && isDepartmentsInHospital(props.item.departments.map(d => d.departmentId), hospitalItem.id)"
          :key="hospitalItem.id"
          :input-value="isResponder(props.item.id, hospitalItem.id)"
          @change="updateResponder(props.item.id, hospitalItem.id, $event)"
          small
          dense
          inset
        ></v-switch>
      </template>
    </v-data-table>

    <v-dialog
      v-model="isRequestApprovalDialogOpened"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h6">
          以下のアカウントを承認しますか？
        </v-card-title>
        <v-card-text v-if="selectedRequest">
          <v-form ref="form" lazy-validation>
            <v-row dense>
              <v-col cols="5">メールアドレス</v-col>
              <v-col cols="7" class="font-weight-bold">{{ selectedRequest.mailAddress }}</v-col>
            </v-row>
            <v-row dense>
              <v-col cols="5">名前</v-col>
              <v-col cols="7" class="font-weight-bold">{{ selectedRequest.familyName }} {{ selectedRequest.firstName }}</v-col>
            </v-row>
            <v-row dense>
              <v-col cols="5">電話番号</v-col>
              <v-col cols="7" class="font-weight-bold">
                <a :href="`tel:${selectedRequest.telephoneNumber}`">
                  {{ selectedRequest.telephoneNumber }}
                </a>
              </v-col>
            </v-row>
            <v-row v-if="isUsers(selectedRequest.userID)" dense>
              <v-col cols="5">備考</v-col>
              <v-col cols="7" class="font-weight-bold">{{ selectedRequest.note }}</v-col>
            </v-row>
            <v-row dense>
              <v-col cols="5">所属施設名</v-col>
              <v-col cols="7" class="font-weight-bold">
                <v-select
                  v-if="selectedRequest.isAlliance"
                  v-model="selectedRequest.detail.hospital"
                  dense
                  class="text-no-wrap"
                  :items="hospitalItems"
                  item-text="shortName"
                  item-value="id"
                  :rules="[required, notEmpty]"
                  multiple
                ></v-select>
                <template v-else>
                  {{ selectedRequest.detail.hospital }}
                </template>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="5">所属部署</v-col>
              <v-col cols="7" class="font-weight-bold">
                <template v-if="selectedRequest.isAlliance">
                  <v-select
                    v-for="hospital in selectedRequest.detail.hospital"
                    :key="hospital"
                    v-model="selectedRequest.detail.department[hospital]"
                    :label="getHopsitalShortName(hospital)"
                    dense
                    class="text-no-wrap"
                    :items="departmentItems.filter(i => i.hospitalId == hospital)"
                    item-text="name"
                    item-value="id"
                    :rules="[required]"
                  ></v-select>
                </template>
                <template v-else>
                  {{ selectedRequest.detail.department }}
                </template>
              </v-col>
            </v-row>
            <v-row dense v-if="selectedRequest.isAlliance && !isUsers(selectedRequest.userId)">
              <v-col cols="5">権限</v-col>
              <v-col cols="7" class="font-weight-bold">
                <v-select
                  v-model="selectedRequest.roleId"
                  dense
                  class="text-no-wrap"
                  :items="roles"
                  item-text="name"
                  item-value="id"
                  :rules="[required]"
                ></v-select>
              </v-col>
            </v-row>
            <v-row v-if="!isUsers(selectedRequest.userId)" dense>
              <v-col cols="5">登録者への承認メール本文</v-col>
              <v-col cols="7">
                <v-textarea v-model="mailText" rows="7">
                </v-textarea>
              </v-col>
            </v-row>
            <v-row align="center" justify="center" dense>
              <v-btn color="red" class="mr-3" dark @click.stop="deleteRequest">
                棄却
              </v-btn>
              <v-btn v-if="isUsers(selectedRequest.userId)" color="primary" :disabled="!isValid" @click.stop="approveUpdateRequest">
                更新
              </v-btn>
              <v-btn v-else color="primary" :disabled="!isValid" @click.stop="approveRequest">
                登録
              </v-btn>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="isDeletedDialogOpened"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h6">
          以下のアカウントを削除しますか？
        </v-card-title>
        <v-card-text>
          <v-row align="center" justify="center" dense>
            {{deletedUser.familyName}} {{deletedUser.firstName}} さん
          </v-row>
          <v-row align="center" justify="center" dense>
            <v-btn color="red" dark @click.stop="deleteUser">
              削除
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import _ from 'lodash'
import moment from 'moment';

export default {
  components: {
	},
  data: function(){
    return {
      currentTabIndex: 0,
      tabs: [
        {value: 'requests', label: '依頼一覧'},
        {value: 'users', label: 'ユーザ一覧'},
      ],
      requests: [],
      requestsHeaders: [
        {
          text: '依頼内容', 
          value: 'userId',
          filterable: true,
          filter: value => this.filterParamsForRequests['userId'] == null || value == this.filterParamsForRequests['userId'],
          width: 120,
        },
        {
          text: '名前', 
          value: 'fullName',
          filterable: true,
          filter: value => !this.filterParamsForRequests['fullName']?.length || value.indexOf(this.filterParamsForRequests['fullName']) >= 0,
        },
        {
          text: '所属施設', 
          value: 'detail.hosptial',
          filterable: true,
          sortable: false,
        },
        {
          text: '所属部署', 
          value: 'affiliation',
          filterable: true,
          sortable: false,
        },
        {text: '依頼経過時間', value: 'createdTime'},
      ],

      users: [],
      usersHeaders: [
        {text: '', value: 'menu', class: 'px-0', width: 0},
        {
          text: '名前', 
          value: 'fullName',
          filterable: true,
          filter: value => !this.filterParamsForUsers['fullName']?.length || value.indexOf(this.filterParamsForUsers['fullName']) >= 0,
        },
        {
          text: 'アライアンス', 
          value: 'isAlliance',
          filterable: true,
          filter: value => this.filterParamsForUsers['isAlliance'] == null || value == this.filterParamsForUsers['isAlliance'],
          width: 140,
        },
        {
          text: '所属施設', 
          value: 'hosptial', 
          sortable: false,
          filterable: true,
        },
        {
          text: '所属部署', 
          value: 'affiliation', 
          sortable: false,
          filterable: true,
        },
        {
          text: '権限', 
          value: 'roleId',
          filterable: true,
          filter: value => this.filterParamsForUsers['roleId'] == null || value == this.filterParamsForUsers['roleId'],
          width: 140,
        },
        {
          text: '一時停止', 
          value: 'isPaused',
          filterable: true,
          filter: value => this.filterParamsForUsers['isPaused'] == null || value == this.filterParamsForUsers['isPaused'],
          width: 80,
        },
      ],
      isRequestsLoading: false,
      isUsersLoading: false,
      isDeletedDialogOpened: false,
      deletedUser: {},
      currentPage: 1,
      itemsPerPage: -1,

      isRequestApprovalDialogOpened: false,
      selectedRequest: null,

      required: value => !!value || "※必須",
      notEmpty: value => !value || value.length > 0 || "※必須",
      isValid: false,

      outsideHospitals: [],
      outsideDepartments: [],

      roles: [],
      responders: [],

      mailText: '本サイトの会員登録が完了いたしました。\n以下のURLよりアクセスし、HEART ALLIANCE APPをご利用ください。\n\nhttps://heartalliance-app.com',
      currentDate: new Date(),

      filterParamsForRequests: {},
      filterParamsForUsers: {},
    }
  },
  computed: {
    ...mapState([
      'hospitalItems',
      'departmentItems',
    ]),
    getElapsedTime: function(){
      return (updateDatetime) => this.currentDate ? this.currentDate - moment(updateDatetime).toDate() : null;
    },
    getElapsedTimeLabel: function(){
      return (updateDatetime) => {
        let elapsedTime = this.getElapsedTime(updateDatetime)
        if(!elapsedTime){
          return null;
        }

        if(elapsedTime > 60*60*24*1000){
          return parseInt(elapsedTime/(60*60*24*1000)) + "d"
        }
        let elapsedHour = parseInt(elapsedTime/(60*60*1000));
        let elapsedMinutes = parseInt((elapsedTime - elapsedHour*60*60*1000)/(60*1000));
        return elapsedHour > 0 ? `${elapsedHour}h ${elapsedMinutes}m` : `${elapsedMinutes}m`;
      };
    },
    getHopsitalShortName: function(){
      return (id) => this.hospitalItems.find(i => i.id === id)?.shortName
    },
    isDepartmentsInHospital: function(){
      return (departmentIds, hospitalId) => departmentIds.some(id => this.isDepartmentInHospital(id, hospitalId));
    },
    isDepartmentInHospital: function(){
      return (departmentId, hospitalId) => {
        let department = this.departmentItems.find(i => i.id === departmentId);
        let hospital = this.hospitalItems.find(i => i.id === department?.hospitalId);
        return hospital?.id == hospitalId;
      };
    },
    getHopsitalIdByDepartmentId: function(){
      return (departmentId) => this.hospitalItems.find(i => i.id === this.departmentItems.find(i => i.id === departmentId)?.hospitalId)?.id
    },
    getHopsitalShortNameByDepartmentId: function(){
      return (departmentId) => this.hospitalItems.find(i => i.id === this.departmentItems.find(i => i.id === departmentId)?.hospitalId)?.shortName
    },
    getDepartmentName: function(){
      return (id) => this.departmentItems.find(i => i.id === id)?.name
    },
    getOutsideHopsitalNameByDepartmentId: function(){
      return (departmentId) => this.outsideHospitals.find(i => i.id === this.outsideDepartments.find(i => i.id === departmentId)?.hospitalId)?.name
    },
    getOutsideDepartmentName: function(){
      return (id) => this.outsideDepartments.find(i => i.id === id)?.name
    },
    getRoleName: function(){
      return (id) => this.roles.find(i => i.id === id)?.name
    },

    isUsers: function(){
      return (userId) => this.users.some(i => i.id === userId)
    },
    currentTab: function(){
      return this.tabs[this.currentTabIndex];
    },
    filteredRequests: function(){
      let rtn = [...this.requests];
      if(this.filterParamsForRequests['detail.hosptial']?.length){
        const query = this.filterParamsForRequests['detail.hosptial'];
        rtn = rtn.filter(r => {
          let hospital = r.isAlliance ? r.detail.hospital.map(h => this.getHopsitalShortName(h)).join(", ") : r.detail.hospital
          return hospital.indexOf(query) >= 0;
        })
      }

      if(this.filterParamsForRequests['affiliation']?.length){
        const query = this.filterParamsForRequests['affiliation'];
        rtn = rtn.filter(r => {
          let affiliation = r.isAlliance ? Object.keys(r.detail.department).map(h => this.getDepartmentName(r.detail.department[h])).join(', ') : r.detail.department;
          return affiliation.indexOf(query) >= 0;
        })
      }

      return rtn;
    },
    filteredUsers: function(){
      let rtn = [...this.users];
      
      if(this.filterParamsForUsers['hosptial']?.length){
        const query = this.filterParamsForUsers['hosptial'];
        rtn = rtn.filter(u => {
          let hospital = u.isAlliance 
            ? u.departments.map(d => this.getHopsitalShortNameByDepartmentId(d.departmentId)).join(", ") 
            : u.departments.map(d => this.getOutsideHopsitalNameByDepartmentId(d.departmentId)).join(', ')
          return hospital.indexOf(query) >= 0;
        })
      }

      if(this.filterParamsForUsers['affiliation']?.length){
        const query = this.filterParamsForUsers['affiliation'];
        rtn = rtn.filter(u => {
          let affiliation = u.isAlliance 
            ? u.departments.map(d => this.getDepartmentName(d.departmentId)).join(', ')
            : u.departments.map(d => this.getOutsideDepartmentName(d.departmentId)).join(', ');
          return affiliation.indexOf(query) >= 0;
        })
      }

      this.hospitalItems.forEach(h => {
        let key = 'requester' + h.id;
        const query = this.filterParamsForUsers[key];
        if(query == null){
          return;
        }
        rtn = rtn.filter(u => u.isAlliance && this.isDepartmentsInHospital(u.departments.map(d => d.departmentId), h.id));
        rtn = rtn.filter(u => query == this.isResponder(u.id, h.id));
      });

      return rtn;
    },

    customizedUsersHeaders: function(){
      return [
        ...this.usersHeaders,
        ...this.hospitalItems.map(h => {
          return {
            text: h.shortName + "質問対応者",
            value: 'requester' + h.id,
            width: 100,
            sortable: false,
            filterable: true,
          }
        })
      ]
    },
    isResponder: function(){
      return (userId, hospitalId) => this.responders.some(r => r.userId == userId && r.hospitalId == hospitalId);
    }
  },
  watch: {
    isRequestApprovalDialogOpened: function(newVal){
      if(newVal){
        this.$nextTick(() => {
          this.isValid = this.$refs.form.validate();
        });
      }
    },
    selectedRequest: {
      handler: function(){
        this.$nextTick(() => {
          this.isValid = this.$refs.form?.validate();
        });
      },
      deep: true,
    }
  },
  mounted: function(){
    this.currentDate = new Date();
    this.isRequestsLoading = true;
    this.axios.get('/api/account_requests').then(response => {
      this.requests = response.data.requests;
    }).catch(error => {
      console.log(error);
    }).finally(() => {
      this.isRequestsLoading = false;
    })
    
    this.loadUsers();

    this.axios.get('/api/roles').then(response => {
      this.roles = response.data.roles;
    }).catch(error => {
      console.log(error);
    }).finally(() => {
    })

    this.axios.get('/api/responders').then(response => {
      this.responders = response.data.responders;
    }).catch(error => {
      console.log(error);
    }).finally(() => {
    })

    this.axios.get('/api/hospitals/outside').then(response => {
      this.outsideHospitals = response.data.hospitals;
    }).catch(error => {
      console.log(error);
    });

    this.axios.get('/api/departments/outside').then(response => {
      this.outsideDepartments = response.data.departments;
    }).catch(error => {
      console.log(error);
    });
  },
  methods: {
    ...mapActions([
    ]),
    openRequestDetail: function(requetData){
      this.selectedRequest = _.cloneDeep(requetData);
      this.$set(this.selectedRequest, 'roleId', null);
      this.isRequestApprovalDialogOpened = true;
    },
    openUserDetail: function(){
    },
    loadUsers: function(){
      this.isUsersLoading = true;
      this.axios.get('/api/users').then(response => {
        this.users = response.data.users;
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.isUsersLoading = false;
      })
    },
    approveRequest: function(){
      this.selectedRequest["mailText"] = this.mailText
      this.axios.post('/api/approve_request', this.selectedRequest).then(() => {
        this.requests = this.requests.filter(r => r.id !== this.selectedRequest.id);
        this.loadUsers();
        this.isRequestApprovalDialogOpened = false;
        alert("メールを送信し、会員登録が完了しました。");
      }).catch(error => {
        console.log(error);
      })
    },
    approveUpdateRequest: function(){
      this.selectedRequest["mailText"] = this.mailText
      this.axios.post('/api/approve_update_request', this.selectedRequest).then(() => {
        this.requests = this.requests.filter(r => r.id !== this.selectedRequest.id);
        this.loadUsers();
        this.isRequestApprovalDialogOpened = false;
        alert("メールを送信し、会員更新が完了しました。");
      }).catch(error => {
        console.log(error);
      })
    },
    deleteRequest: function(){
      let deletedRequest = {
        id: this.selectedRequest.id,
      };
      this.axios.delete('/api/request', {
          data: deletedRequest
      }).then(() => {
        this.requests = this.requests.filter(r => r.id !== this.selectedRequest.id);
        this.loadUsers();
        this.isRequestApprovalDialogOpened = false;
        alert("依頼を棄却しました。メール送信していないため、必要に応じてユーザにご連絡ください。");
      }).catch(error => {
        console.log(error);
      })
    },
    updateResponder: function(userId, hospitalId, newVal){
      if(newVal){
        this.axios.post('/api/responder', {
          userId: userId,
          hospitalId: hospitalId,
        }).then(() => {
          this.responders.push({
            userId: userId,
            hospitalId: hospitalId
          });
        }).catch(error => {
          console.log(error);
        });
      }else{
        this.axios.delete('/api/responder', {
          data: {
            userId: userId,
            hospitalId: hospitalId,
          }
        }).then(() => {
          this.responders = this.responders.filter(r => r.userId != userId || r.hospitalId != hospitalId)
        }).catch(error => {
          console.log(error);
        });
      }
    },
    updateRoleId: function(userId, roleId){
      if(roleId){
        this.axios.post('/api/role', {
          userId: userId,
          roleId: roleId,
        }).then((response) => {
          if(response.data.result != "ok") {
            alert("更新に失敗しました。何度も失敗する場合、システム管理者にご連絡ください。")
          }
        }).catch(error => {
          console.log(error);
        });
      }
    },
    updateUserPause: function(userId, isPaused){
      this.axios.put('/api/user_pause', {
        userId: userId,
        isPaused: isPaused,
      }).then((response) => {
        if(response.data.result != "ok") {
          alert("更新に失敗しました。何度も失敗する場合、システム管理者にご連絡ください。")
        }
      }).catch(error => {
        console.log(error);
      });
    },
    deleteUserConfirmation: function(user) {
      this.deletedUser = user;
      this.isDeletedDialogOpened = true;      
    },
    deleteUser: function() {
      this.axios.post('/api/delete_user', {
        userId: this.deletedUser.id,
      }).then((response) => {
        if(response.data.result != "ok") {
          alert("更新に失敗しました。何度も失敗する場合、システム管理者にご連絡ください。")
          return;
        }
        this.loadUsers();
        this.isDeletedDialogOpened = false;
      }).catch(error => {
        console.log(error);
      });

      if(!this.deletedUser.isAlliance) return;
      for (let department of this.deletedUser.departments) {
        this.axios.delete('/api/responder', {
          data: {
            userId: this.deletedUser.id,
            hospitalId: this.getHopsitalIdByDepartmentId(department.departmentId),
          }
        }).then(() => {}).catch(error => {
          console.log(error);
        });
      }
    },
  }
}
</script>

<style scoped>

</style>