<template>
  <v-container fluid>
    <v-row dense justify="center">
      <v-col dense class="text-center">
        症例ID
        <span class="title"> {{ caseId }} </span>
        で投稿が完了いたしました。
      </v-col>
    </v-row>
    <v-row dense class="mt-5" justify="center" align-content="center">
        <v-btn class="mx-3" dark to="/cases">一覧に戻る</v-btn>
        <v-btn class="mx-3" dark :to="`/case/detail/${caseId}`">詳細へ</v-btn>
    </v-row>
  </v-container>
</template>

<script>
export default {
  components: {
	},
  props: {
    caseId: {
      type: Number,
    }
  },
  data: function(){
    return {
    }
  },
  mounted: function(){
  },
  methods: {
  }
}
</script>