<template>
  <v-container fluid>
    <template v-if="$vuetify.breakpoint.mobile">
      <v-btn 
        color="primary"
        @click="openRegistrationModal({date: focusedDay})"
        fab
        small
        fixed
        right
        bottom
      ><v-icon>mdi-plus</v-icon></v-btn>
      <v-row dense align="start" justify="center">
        <v-col dense>
          <v-sheet height="calc(100vh - 120px)">  
            <v-toolbar dense flat>
              <v-toolbar-title>
                {{ calendarTitle }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn fab small depressed outlined class="mr-3" @click="isScheduleFilterModalOpened = true">
                <v-icon>mdi-filter</v-icon>
              </v-btn>
              <v-menu
                bottom
                right
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    color="grey darken-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span>{{ calendarType }}</span>
                    <v-icon right>
                      mdi-menu-down
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="calendarType = 'day'">
                    <v-list-item-title>DAY</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="calendarType = '4day'">
                    <v-list-item-title>4DAY</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="calendarType = 'week'">
                    <v-list-item-title>WEEK</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="calendarType = 'month'">
                    <v-list-item-title>MONTH</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
            <v-calendar
              v-touch="{
                right: () => $refs.calendar.prev(),
                left: () => $refs.calendar.next(),
              }"
              ref="calendar"
              class="label-wrapped"
              v-model="focusedDay"
              :weekdays="weekday"
              :type="calendarType"
              :events="events"
              event-overlap-mode="column"
              @click:date="viewDay"
              @click:event="openScheduleEditModal"
              @click:time="openRegistrationModal"
            >
              <template v-slot:event="{ event }">
                <span class="pl-1 event-name">
                  {{ event.name }}
                </span><br/>
                <span class="pl-1 event-name">
                  {{ event.participantsName }}
                </span><br/>
                <span class="pl-1 event-name" v-if="false">
                  {{ event.start.slice(-5) }}～{{ event.end.slice(-5) }}
                </span>
              </template>
            </v-calendar>
          </v-sheet>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-row>
        <v-col cols="8">
          <v-row dense>
            <v-btn @click="createNewSchedule">新規追加</v-btn>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn 
                  class="ml-5"
                  @click="saveSchedules"
                  :color="isBulkUpdateEnable ? 'primary' : 'grey lighten-2'"
                  :class="{
                    'grey--text': !isBulkUpdateEnable
                  }"
                  :depressed="!isBulkUpdateEnable"
                  v-bind="attrs"
                  v-on="on"
                >一括保存</v-btn>
              </template>
              <span v-if="invalidSchedules.length > 0">
                不適なスケジュールがあります。内容をご確認ください。
              </span>
              <span v-if="updatingSchedules.length == 0">
                変更されたスケジュールがありません。
              </span>
              <v-spacer/>
            </v-tooltip>
            <v-spacer />
            <v-btn @click="downloadXlsx" fab small depressed class="ml-auto">
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </v-row>
          
          <v-tabs
            v-model="currentTabIndex"
            dense
            class="mt-1"
          >
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab
              v-for="tab in tabs"
              :key="tab.value"
              :class="tab.class"
            >
              {{ tab.label }}
            </v-tab>
          </v-tabs>
          <v-data-table
            :items="filteredSchedulesForTable"
            :headers="schedulesHeaders"
            class="schedule_table"
            :item-class="scheduleRowClasses"
            ref="scheduleTable"
          >
            <template v-slot:body.prepend>
              <tr dense>
                <td dense v-for="header in schedulesHeaders" :key="header.value">
                  <template v-if="header.value === 'scheduleType'">
                    <v-select
                      v-model="filterParams['scheduleType']"
                      dense
                      :items="[{label:'', value: null}, ...typeItems]"
                      item-text="label"
                      item-value="value"
                      :hint="header.text"
                      persistent-hint
                    ></v-select>
                  </template>
                  <template v-else-if="header.value === 'participants'">
                    <v-combobox
                      v-model="filterParams['participants']"
                      class="text-no-wrap"
                      :items="allianceUserIds"
                      dense
                      multiple
                      chips
                      :hint="header.text"
                      persistent-hint
                    >
                      <template v-slot:selection="data">
                        <v-chip>
                          {{ getAllianceUserName(data.item) }}
                        </v-chip>
                      </template>
                      <template v-slot:item="{ item }">
                        {{ getAllianceUserName(item) }}
                      </template>
                    </v-combobox>
                  </template>
                  <template v-else-if="header.value === 'detail'">
                    <v-row dense>
                      <v-col dense>
                        <v-select
                          v-model="filterParams['hospitalId']"
                          dense
                          :items="[{shortName: '', id: null}, ...hospitalItems]"
                          item-text="shortName"
                          item-value="id"
                          hint="病院"
                          persistent-hint
                        ></v-select>
                      </v-col>
                      <v-col dense> 
                        <v-select
                          v-model="filterParams['facilityId']"
                          dense
                          :items="[{label: '', id: null}, ...facilityItems]"
                          item-text="label"
                          item-value="id"
                          hint="施設項目"
                          persistent-hint
                        ></v-select>
                      </v-col>
                      <v-col dense> 
                        <v-text-field
                          v-model="filterParams['title']"
                          dense
                          hint="タイトル"
                          single-line
                          persistent-hint
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </template>
                  <template v-else-if="header.filterable">
                    <v-text-field
                      dense
                      v-model="filterParams[header.value]"
                      :hint="header.text"
                      single-line
                      persistent-hint
                    ></v-text-field>
                  </template>
                </td>
              </tr>
            </template>
            <template v-slot:item.menu="props">
              <div class="d-flex flex-column">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-overlay
                      :absolute="true"
                      :value="props.item.isTitleSecret && props.item.userId != user.id"
                      v-bind="attrs"
                      v-on="on"
                      :opacity="0.5"
                      class="align-end"
                    >
                      タイトル非公開のスケジュールは作成者以外編集できません。
                    </v-overlay>
                  </template>
                </v-tooltip>
                <v-btn 
                  fab
                  :disabled="props.item.isTitleSecret && props.item.userId != user.id"
                  depressed 
                  x-small 
                  class="mb-1"
                  @click="switchScheduleDeleted(props.item)"
                >
                  <v-icon small>mdi-trash-can</v-icon>
                </v-btn>
                <v-btn 
                  :disabled="props.item.isTitleSecret && props.item.userId != user.id"
                  fab 
                  depressed 
                  x-small
                  @click="duplicateSchedule(props.item)"
                >
                  <v-icon small>mdi-content-copy</v-icon>
                </v-btn>
              </div>
            </template>
            <template v-slot:item.scheduleType="props">
              <v-select
                dense
                :disabled="props.item.isTitleSecret && props.item.userId != user.id"
                v-model="props.item.scheduleType"
                class="text-no-wrap min-height"
                :items="typeItems"
                item-text="label"
                item-value="value"
                @change="setScheduleEdited(props.item)"
                :rules="[required]"
              ></v-select>
            </template>
            <template v-slot:item.startTime="props">
              <DateTimeSelector 
                :disabled="props.item.isTitleSecret && props.item.userId != user.id"
                v-model="props.item.startTime"
                @change="setScheduleEdited(props.item)"
              />
            </template>
            <template v-slot:item.endTime="props">
              <DateTimeSelector 
                :disabled="props.item.isTitleSecret && props.item.userId != user.id"
                v-model="props.item.endTime"
                @change="setScheduleEdited(props.item)"

              />
            </template>
            <template v-slot:item.detail="props">
              <template v-if="props.item.scheduleType === 'facility'">
                <div class="d-flex">
                  <v-select
                    :disabled="props.item.isTitleSecret && props.item.userId != user.id"
                    v-model="props.item.hospitalId"
                    label="病院"
                    style="width: 120px"
                    class="text-no-wrap"
                    :items="hospitalItems"
                    item-text="shortName"
                    item-value="id"
                    @change="setScheduleEdited(props.item)"
                  ></v-select>
                  <v-select
                    :disabled="props.item.isTitleSecret && props.item.userId != user.id"
                    v-model="props.item.facilityId"
                    label="施設項目"
                    style="width: 80px"
                    class="text-no-wrap mr-3"
                    :items="facilityItems"
                    item-text="label"
                    item-value="id"
                    @change="setScheduleEdited(props.item)"
                  ></v-select>
                  <v-select
                    v-if="isTitleNecessary(props.item.facilityId)"
                    :disabled="props.item.isTitleSecret && props.item.userId != user.id"
                    v-model="props.item.isTitleSecret"
                    label="タイトル公開"
                    :items="[
                      {label: '公開', value: false},
                      {label: '非公開', value: true},
                    ]"
                    class="mr-3"
                    style="width: 60px;"
                    item-text="label"
                    item-value="value"
                    @change="setScheduleEdited(props.item)"
                  />
                  <template v-if="isUsingSurgeriesForTitle(props.item.facilityId)">
                    <v-combobox
                      :value="titleSurgeries(props.item.title)"
                      @input="updateSurgeriesTitle(props.item, $event); setScheduleEdited(props.item)"
                      class="text-no-wrap"
                      :items="surgeryNames"
                      multiple
                      chips
                      label="タイトル"
                    >
                    </v-combobox>
                    <v-btn 
                      class="mt-3" 
                      fab 
                      x-small 
                      depressed
                      @click="calculateEndTimeBySurgeries(props.item); setScheduleEdited(props.item)"
                    >
                      <v-icon>mdi-calculator</v-icon>
                    </v-btn>
                  </template>
                  <template v-else>
                    <v-text-field 
                      :disabled="props.item.isTitleSecret && props.item.userId != user.id"
                      v-if="isTitleNecessary(props.item.facilityId)"
                      v-model="props.item.title"
                      label="タイトル"
                      @change="setScheduleEdited(props.item)"
                    />
                  </template>
                </div>
              </template>
              <template v-if="props.item.scheduleType === 'individual'">
                <div class="d-flex">
                  <v-select
                    :disabled="props.item.isTitleSecret && props.item.userId != user.id"
                    v-model="props.item.isTitleSecret"
                    label="タイトル公開"
                    :items="[
                      {label: '公開', value: false},
                      {label: '非公開', value: true},
                    ]"
                    class="mr-3"
                    style="width: 60px;"
                    item-text="label"
                    item-value="value"
                    @change="setScheduleEdited(props.item)"
                  />
                  <v-text-field
                    :disabled="props.item.isTitleSecret && props.item.userId != user.id"
                    v-model="props.item.title" 
                    label="タイトル"
                    @change="setScheduleEdited(props.item)"
                  />
                </div>
              </template>
            </template>
            <template v-slot:item.participants="props">
              <v-combobox
                v-model="props.item.participants"
                label="参加者"
                :disabled="props.item.isTitleSecret && props.item.userId != user.id"
                class="text-no-wrap"
                :items="allianceUserIds"
                multiple
                chips
                @change="setScheduleEdited(props.item)"
              >
                <template v-slot:prepend-item>
                  <v-row class="pl-3 pb-2">
                    <v-col>
                      <v-btn
                        @click="selectAllAllianceUsers(props.item)">
                        全選択
                      </v-btn>
                      <v-btn 
                        class="ml-5"
                        @click="dselectAllAllianceUsers(props.item)">
                        全解除
                      </v-btn>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:selection="data">
                  <v-chip>
                    {{ getAllianceUserName(data.item) }}
                  </v-chip>
                </template>
                <template v-slot:item="{ item }">
                  {{ getAllianceUserName(item) }}
                </template>
              </v-combobox>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="4">
          <v-sheet height="calc(100vh - 160px)">  
            <v-toolbar flat>
              <v-btn
                outlined
                class="mr-4"
                color="grey darken-2"
                @click="setToday"
              >
                TODAY
              </v-btn>
              <v-btn
                icon
                class="ma-2"
                @click="$refs.calendar.prev()"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                icon
                class="ma-2"
                @click="$refs.calendar.next()"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
              <v-toolbar-title>
                {{ calendarTitle }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn fab small depressed outlined class="mr-3" @click="isScheduleFilterModalOpened = true">
                <v-icon>mdi-filter</v-icon>
              </v-btn>
              <v-menu
                bottom
                right
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    color="grey darken-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span>{{ calendarType }}</span>
                    <v-icon right>
                      mdi-menu-down
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="calendarType = 'day'">
                    <v-list-item-title>DAY</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="calendarType = '4day'">
                    <v-list-item-title>4DAY</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="calendarType = 'week'">
                    <v-list-item-title>WEEK</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="calendarType = 'month'">
                    <v-list-item-title>MONTH</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
            <v-calendar
              ref="calendar"
              class="label-wrapped"
              v-model="focusedDay"
              :weekdays="weekday"
              :type="calendarType"
              :events="events"
              event-overlap-mode="column"
              @click:event="openScheduleEditModal"
              @click:time="openRegistrationModal"
            >
              <template v-slot:event="{ event }">
                <span class="pl-1 event-name">
                  {{ event.name }}
                </span><br/>
                <span v-if="event.participantsName" class="pl-1 event-name">
                  {{ event.participantsName }}
                </span><br/>
                <span class="pl-1 event-name" v-if="false">
                  {{ event.start.slice(-5) }}～{{ event.end.slice(-5) }}
                </span>
              </template>
            </v-calendar>
          </v-sheet>
        </v-col>
      </v-row>
    </template>
    <v-dialog 
      v-model="isRegistrationModalOpened" 
      width="500"
      ref="registrationDialog"
      :persistent="isWorkerInputOpened"
    >
      <v-card>
        <v-card-text class="text-center">
          <v-form ref="form" class="pt-5" lazy-validation>
            <v-select
              v-model="registrationData.scheduleType"
              label="予定の種類"
              class="text-no-wrap"
              :items="typeItems"
              item-text="label"
              item-value="value"
              :rules="[required]"
            ></v-select>
            <template v-if="registrationData.scheduleType === 'facility'">
              <v-select
                v-model="registrationData.hospital"
                label="病院"
                class="text-no-wrap"
                :items="hospitalItems"
                item-text="name"
                item-value="id"
                :rules="[required]"
              ></v-select>
              <v-select
                v-model="registrationData.facility"
                label="施設項目"
                class="text-no-wrap"
                :items="facilityItems"
                item-text="label"
                item-value="id"
                :rules="[required]"
              ></v-select>
              <template v-if="isUsingSurgeriesForTitle(registrationData.facility)">
                <v-combobox
                  :value="titleSurgeries(registrationData.title)"
                  @input="updateSurgeriesTitle(registrationData, $event)"
                  class="text-no-wrap"
                  :items="surgeryNames"
                  dense
                  multiple
                  chips
                  label="タイトル"
                >
                </v-combobox>
                <v-btn 
                  class="mt-3" 
                  fab 
                  x-small 
                  depressed
                  @click="calculateEndDateTimeBySurgeries(registrationData)"
                >
                  <v-icon>mdi-calculator</v-icon>
                </v-btn>
              </template>
              <template v-else>
                <v-text-field 
                  v-if="isTitleNecessary(registrationData.facility)"
                  dense
                  v-model="registrationData.title"
                  label="タイトル"
                  :rules="[required]"
                />
              </template>
            </template>
            <template v-if="registrationData.scheduleType === 'individual'">
              <v-radio-group
                v-model="registrationData.isTitleSecret"
                row
                dense
              >
                <v-radio
                  label="タイトル公開"
                  :value="false"
                ></v-radio>
                <v-radio
                  label="タイトル非公開"
                  :value="true"
                ></v-radio>
              </v-radio-group>
              <v-text-field 
                class="mt-5" 
                v-model="registrationData.title" 
                label="タイトル"
                :rules="[required]"
              />
            </template>

            <v-row dense align="center" justify="center">
              <v-col dense cols="6">
                <DateSelector
                  v-model="registrationData.startDate"
                  label="開始日"
                />
              </v-col>
              <v-col dense cols="6">
                <TimeSelector
                  v-model="registrationData.startTime"
                  lable="開始時間"
                />
              </v-col>
              <v-col dense cols="6">
                <DateSelector
                  v-model="registrationData.endDate"
                  label="終了日"
                />
              </v-col>
              <v-col dense cols="6">
                <TimeSelector
                  v-model="registrationData.endTime"
                  lable="終了時間"
                />
              </v-col>
            </v-row>

            <v-row class="mt-1" justify="end">
              <v-btn @click="checkAllWorkers">全選択</v-btn>
              <v-btn class="ml-5" @click="uncheckAllWorkers">全解除</v-btn>
            </v-row>
            <v-combobox
              v-model="registrationData.workers"
              label="参加者"
              class="text-no-wrap"
              :items="allianceUsers"
              item-text="fullName"
              :rules="[required, notEmpty]"
              multiple
              chips
              ref="workersInput"
              @focus="setRegistrationDialogClosability(false)"
              @blur="setRegistrationDialogClosability(true)"
            ></v-combobox>

            <v-row dense v-if="registrationData.latestUpdaterId" class="mb-3">
              最終更新者: {{ getAllianceUserName(registrationData.latestUpdaterId) }}
            </v-row>

            <v-row dense align="center" justify="end" v-if="false">
              <span class="mx-4">当日通知</span>
              <v-switch
                v-model="registrationData.isNotificationOn"
                color="primary"
                inset
              />
            </v-row>
          </v-form>

          <v-btn 
            v-if="registrationData.id"
            color="red"
            dark
            class="mx-5"
            @click.stop="deleteSchedule"
          >削除</v-btn>
          
          <v-btn 
            color="primary" 
            class="mx-5"
            @click.stop="upsertSchedule"
            :disabled="!isRegistrationValid"
          >保存</v-btn>
        </v-card-text>
      </v-card>              
    </v-dialog>

    <v-dialog
      ref="dialog"
      v-model="isScheduleFilterModalOpened"
      width="500"
    >
      <v-card>
        <v-container>
          <v-row dense>
            <v-col dense>
              <v-select
                v-model="filterParamsForCalendar['scheduleType']"
                dense
                multiple
                :items="scheduleTypeItemsForFilter"
                item-text="label"
                item-value="value"
                hint="予定の種類/病院"
                persistent-hint
              ></v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col dense>
              <v-select
                v-model="filterParamsForCalendar['facilityId']"
                dense
                :items="facilityItems"
                item-text="label"
                item-value="id"
                hint="施設項目"
                multiple
                persistent-hint
              ></v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col dense>
              <v-text-field
                v-model="filterParamsForCalendar['title']"
                dense
                hint="タイトル"
                single-line
                persistent-hint
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col dense>
              <v-combobox
                v-model="filterParamsForCalendar['participants']"
                class="text-no-wrap"
                :items="allianceUserIds"
                dense
                multiple
                chips
                hint="参加者"
                persistent-hint
              >
                <template v-slot:selection="data">
                  <v-chip>
                    {{ getAllianceUserName(data.item) }}
                  </v-chip>
                </template>
                <template v-slot:item="{ item }">
                  {{ getAllianceUserName(item) }}
                </template>
              </v-combobox>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="isScheduleFilterModalOpened=false"
          >
            閉じる
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      ref="dialog"
      v-model="isUneditableScheduleModalOpened"
      width="500"
    >
      <v-card>
        <v-card-title>
          タイトル非公開のスケジュールは作成者以外編集できません。
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="isUneditableScheduleModalOpened=false"
          >
            閉じる
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import DateSelector from '@components/inputs/DateSelector.vue';
import TimeSelector from '@components/inputs/TimeSelector.vue';
import DateTimeSelector from '@components/inputs/DateTimeSelector.vue';

import { getRandomStr } from '@js/utils/StringUtil.js';

import moment from 'moment';
import { saveAs } from "file-saver";

export default {
  components: {
    DateTimeSelector,
    DateSelector,
    TimeSelector,
	},
  data: function(){
    return {
      isUneditableScheduleModalOpened: false,
      isScheduleFilterModalOpened: false,
      currentTabIndex: 0,
      tabs: [
        {value: 'all', label: '一覧'},
        {value: 'editing', label: '編集中', class: 'green--text'},
        {value: 'new', label: '新規', class: 'blue--text'},
        {value: 'deleted', label: '削除', class: 'gray--text'},
        {value: 'invalid', label: '不適', class: 'red--text'},
      ],
      isRegistrationModalOpened: false,
      selectedHospitals: [],
      weekday: [0, 1, 2, 3, 4, 5, 6],
      weekDayLabels: ['日', '月', '火', '水', '木', '金', '土'],
      calendarType: '4day',
      focusedDay: moment().format('yyyy-MM-DD'),
      schedules: [],
      registrationData: {
        scheduleType: 'facility',
        hospital: null,
        facility: null,
        isTitleSecret: false,
        startDate: null,
        startTime: null,
        endDate: null,
        endTime: null,
        title: null,
        isNotificationOn: false,
        workers: [],
        id: null,
      },
      typeItems: [
        {value: "facility", label: "施設予定"},
        {value: "individual", label: "個人予定"},
      ],

      isDateSelectorModalOpened: false,
      isTaskEndTimeSelectorModalOpened: false,
      isTaskStartTimeSelectorModalOpened: false,

      isRegistrationValid: false,
      required: value => !!value || "※必須",
      notEmpty: value => !value || value.length > 0 || "※必須",

      schedulesHeaders: [
        {text: '', value: 'menu', class: 'px-0', width: 0},
        {
          text: '予定の種類', 
          value: 'scheduleType', 
          width: 110,
          filterable: true,
          filter: value => this.filterParams['scheduleType'] == null || value == this.filterParams['scheduleType'],
        },
        {text: '詳細', value: 'detail'},
        {
          text: '参加者', 
          value: 'participants', 
          width: 280,
          filterable: true,
          filter: value => !this.filterParams['participants']?.length || value.some(i => this.filterParams['participants'].some(_i => _i == i)),
        },
        {text: '開始時間', value: 'startTime', width: 140},
        {text: '終了時間', value: 'endTime', width: 140},
      ],

      isWorkerInputOpened: false,
      filterParams: {},
      filterParamsForCalendar: {},
      surgeries: [],
    }
  },
  computed: {
    ...mapState([
      'user',
      'hospitalItems',
      'facilityItems',
      'bedsStatusItems',
      'allianceUsers',
    ]),
    scheduleTypeItemsForFilter: function(){
      return [
        ...this.typeItems.filter(i => i.value === 'individual'),
        ...this.hospitalItems.map(h => {
          return {
            label: h.shortName,
            value: h.id
          }
        })
      ]
    },
    surgeryNames: function(){
      return this.surgeries.map(s => s.name);
    },
    isBulkUpdateEnable: function(){
      return this.invalidSchedules.length == 0 && this.updatingSchedules.length > 0;
    },
    scheduleRowClasses: function(){
      return (item) => {
        let isDisalbed = item.isTitleSecret && item.userId != this.user.id;
        let rtn = '';
        if(item.isDeleted){
          rtn = 'grey lighten-2'
        }

        if(item.id == null){
          rtn = 'blue lighten-5'
        }

        if(item.isEdited){
          rtn = 'green lighten-5'
        }

        if(isDisalbed){
          rtn += " relative"
        }
        return rtn;
      };
    },
    allianceUserIds: function(){
      return this.allianceUsers.map(u => u.id);
    },
    titleSurgeries: function(){
      return (title) => title?.split(',').map(s => s.trim()) ?? [];
    },
    getAllianceUserName: function(){
      return (id) => this.allianceUsers.find(u => u.id == id)?.fullName;
    },
    getAllianceUserNames: function(){
      return (ids) => ids.map(id => this.getAllianceUserName(id)).join(', ');
    },
    isTitleNecessary: function(){
      return (item) => this.facilityItems.find(i => i.id == item)?.isTitleNecessary;
    },
    isUsingSurgeriesForTitle: function(){
      return (item) => this.facilityItems.find(i => i.id == item)?.value === 'operating_room';
    },
    validAllianceUsers: function(){
      return this.allianceUsers;
    },
    calendarTitle: function(){
      return moment(this.focusedDay).format('yyyy/MM');
    },
    getHospitalShortName: function(){
      return (hospital) => this.hospitalItems.find(i => i.id === hospital)?.shortName
    },
    getFacilityName: function(){
      return (id) => this.facilityItems.find(i => i.id === id)?.label
    },
    getTimeRange: function(){
      return event => `${event.start.split(' ').slice(-1)[0]}-${event.end.split(' ').slice(-1)[0]}`
    },
    getDateStr: function(){
      return date => {
        return `${date.getMonth() + 1}/${date.getDate()}(${this.weekDayLabels[date.getDay()]})`
      }
    },
    events: function(){
      const scheduleTypes = this.filterParamsForCalendar['scheduleType'];
      const facilityIds = this.filterParamsForCalendar['facilityId'];
      const title = this.filterParamsForCalendar['title'];
      const participants = this.filterParamsForCalendar['participants'];
            
      return this.schedules.filter(s => {
        if(scheduleTypes && scheduleTypes.length > 0){
          if(s.scheduleType === 'individual' && scheduleTypes.every(t => s.scheduleType !== t)){
            return false;
          }

          if(s.scheduleType === 'facility' && scheduleTypes.every(t => s.hospitalId !== t)){
            return false;
          }
        }

        if(facilityIds && facilityIds.length > 0){
          if(s.scheduleType !== 'facility'){
            return false;
          }

          if(facilityIds.every(i => i != s.facilityId)){
            return false;
          }
        }

        if(title?.length){
          if(s.scheduleType === 'facility' && !this.isTitleNecessary(s.facilityId)){
            return false;
          }

          if(s.title.indexOf(title) < 0){
            return false;
          }
        }

        if(participants?.length){
          if(!s.participants.some(i => participants.some(_i => _i == i))){
            return false;
          }
        }

        return true;
      }).map(s => {
        let rtn = {};
        const allianceUsers = this.allianceUsers;
        let workersName = s.participants?.map(id => allianceUsers.find(u => u.id == id)?.fullName).filter(n => !!n).join(', ')
        let hasTitleParticipants = false;
        if(s.scheduleType === 'facility'){
          let hospitalItem = this.hospitalItems.find(h => h.id == s.hospitalId);
          let facilityItem = this.facilityItems.find(f => f.id == s.facilityId);
          let facilityName = facilityItem?.label ?? "";
          hasTitleParticipants = facilityItem?.hasTitleParticipants;
          rtn = {
            ...rtn,
            name: `${facilityName}${facilityItem?.isTitleNecessary ? `, ${s.title}` : ''}`,
            participantsName: hasTitleParticipants ? workersName : null,
            color: hospitalItem.color,
          }
        }

        if(s.scheduleType === 'individual'){
          rtn = {
            ...rtn,
            name: s.title,
            color: 'blue-grey darken-4',
          }
        }

        return {
          ...s,
          ...rtn,
          holders: s.participants,
          participantsName: workersName,
          start: `${s.startTime?.slice(0, -3)}`,
          end: `${s.endTime?.slice(0, -3)}`,
          timed: true,
        }
      })
    },
    editingSchedules: function(){
      return this.schedules.filter(s => s.isEdited && !s._id && !s.isDeleted);
    },
    newSchedules: function(){
      return this.schedules.filter(s => s._id && !s.isDeleted);
    },
    deletedSchedules: function(){
      return this.schedules.filter(s => s.isDeleted);
    },
    schedulesForTable: function(){
      let currentTab = this.tabs[this.currentTabIndex];
      switch(currentTab?.value){
        case 'editing':
          return this.editingSchedules;
        case 'new':
          return this.newSchedules;
        case 'deleted':
          return this.deletedSchedules;
        case 'invalid':
          return this.invalidSchedules;
      }
      return this.schedules;
    },
    filteredSchedulesForTable: function(){
      let rtn = [...this.schedulesForTable];
      if(this.filterParams['hospitalId']){
        const hospitalId = this.filterParams['hospitalId'];
        rtn = rtn.filter(i => i.scheduleType === 'facility');
        rtn = rtn.filter(i => i.hospitalId == hospitalId)
      }

      if(this.filterParams['facilityId']){
        const facilityId = this.filterParams['facilityId'];
        rtn = rtn.filter(i => i.scheduleType === 'facility');
        rtn = rtn.filter(i => i.facilityId == facilityId)
      }

      if(this.filterParams['title']?.length){
        const title = this.filterParams['title'];
        rtn = rtn.filter(i => i.scheduleType === 'individual' || this.isTitleNecessary(i.facilityId));
        rtn = rtn.filter(i => i.title.indexOf(title) >= 0);
      }

      return rtn;
    },
    updatingSchedules: function(){
      let rtn = [...this.deletedSchedules];

      rtn = [
        ...rtn, 
        ...this.newSchedules.filter(s => !rtn.some(_s => _s.id ?? _s._id == s.id ?? s._id))
      ];

      rtn = [
        ...rtn, 
        ...this.editingSchedules.filter(s => !rtn.some(_s => _s.id ?? s._id == s.id ?? s._id))
      ];

      return rtn;
    },
    invalidSchedules: function(){
      return this.updatingSchedules.filter(s => !this.isValidSchedule(s));
    },
  },
  watch: {
    filterParamsForCalendar: {
      handler: function(){
      },
      deep: true,
    },
    registrationData: {
      handler: function(){
        this.$nextTick(() => {
          this.isRegistrationValid = this.$refs.form?.validate();
        });
      },
      deep: true,
    },
    isRegistrationModalOpened: function(newVal){
      if(newVal){
        this.$nextTick(() => {
          this.isRegistrationValid = this.$refs.form?.validate();
        })
      }
    },
    updatingSchedules: {
      handler: function(newVal){
        if(newVal.length > 0){
          window.addEventListener("beforeunload", this.beforeUnload);
        }else{
          window.removeEventListener("beforeunload", this.beforeUnload);
        }
      },
      immediate: true
    },
    calendarTitle: {
      handler: function(){
        this.loadSchedules();
      },
      immediate: true,
    },
    calendarType: {
      handler: function(newVal, oldVal){
        if(newVal !== 'month' && (!oldVal || oldVal === 'month')){
          this.$nextTick(() => {
            this.$refs.calendar.scrollToTime({hour: 7, minute: 0});
          });
        }
      },
      immediate: true,
    },
  },
  beforeRouteLeave (to, from , next) {
    const answer = this.updatingSchedules.length > 0
      ? window.confirm('未保存の予定があります。\n保存せずにこのページを閉じますか？')
      : true;
    if (answer) {
      next()
    } else {
      next(false)
    }
  },
  mounted: function(){
    if(this.$vuetify.breakpoint.mobile){
      this.calendarType = 'day';
    }

    this.$set(this.filterParamsForCalendar, "scheduleType", ['individual', ...this.user.hospital.map(h => h.id)]);
    this.axios.get(`/api/surgeries`).then(response => {
      this.surgeries = response.data.surgeries;
    }).catch(error => {
      console.log(error);
    })

    if (this.user.hospital.length == 1) {
      this.registrationData.hospital = this.hospitalItems.find(i => i.id === this.user.hospital[0].id)?.id
    }
  },
  destroyed: function(){
    window.removeEventListener("beforeunload", this.beforeUnload);
  },
  methods: {
    selectAllAllianceUsers: function(item){
      this.$set(item, 'participants', [...this.allianceUserIds]);
      this.setScheduleEdited(item);
    },
    dselectAllAllianceUsers: function(item){
      this.$set(item, 'participants', []);
      this.setScheduleEdited(item);
    },
    beforeUnload: function(e){
      let rtn = this.editingSchedules.length > 0 || this.newSchedules.length > 0 || this.deletedSchedules.length > 0 
        ? "未保存の予定があります。\n保存せずにこのページを閉じますか？"
        : null;
      e.returnValue = rtn;
      return rtn;
    },
    upsertSchedule: function(){
      this.isRegistrationModalOpened = false;
      this.axios.post(`/api/schedule`, {
        id: this.registrationData.id,
        scheduleType: this.registrationData.scheduleType,
        hospitalId: this.registrationData.hospital,
        facilityId: this.registrationData.facility,
        title: this.registrationData.title,
        isTitleSecret: this.registrationData.isTitleSecret,
        startTime: `${this.registrationData.startDate} ${this.registrationData.startTime}`,
        endTime: `${this.registrationData.endDate} ${this.registrationData.endTime}`,
        isNotificationOn: this.registrationData.isNotificationOn,
        participants: this.registrationData.workers,
      }).then(response => {
        this.schedules = this.schedules.filter(s => s.id != this.registrationData.id);
        this.schedules.push(response.data.schedule);
      }).catch(error => {
        console.log(error);
      });      
    },
    deleteSchedule: function(){
      this.isRegistrationModalOpened = false;
      this.axios.delete(`/api/schedule/${this.registrationData.id}`).then(() => {
        this.schedules = this.schedules.filter(s => s.id != this.registrationData.id);
      }).catch(error => {
        console.log(error);
      });
    },
    setToday: function(){
      this.focusedDay = moment().format('yyyy-MM-DD');
    },
    viewDay: function({ date }) {
      this.focusedDay = date;
      this.calendarType = 'day';
    },
    openRegistrationModal: function({ date, time }){
      if(!time){
        let now = new Date();
        time = ("0" + (now.getHours() + 1)).slice(-2) + ":" + "00";
      }
      let startTime = time.slice(0, -1) + "0";

      let start = moment(date + " " + startTime);
      this.registrationData.startDate = start.format('yyyy-MM-DD');
      this.registrationData.startTime = start.format('HH:mm');

      let end = start.add(1, "hours");
      this.registrationData.endDate = end.format('yyyy-MM-DD');
      this.registrationData.endTime = end.format('HH:mm');
      this.registrationData.isNotificationOn = false;

      this.registrationData.id = null;
      this.registrationData.latestUpdaterId = null;

      this.isRegistrationModalOpened = true;
    },
    openScheduleEditModal: function({ event, nativeEvent }){
      nativeEvent.stopPropagation();

      if(event.isTitleSecret && this.user.id != event.userId){
        this.isUneditableScheduleModalOpened = true;
        return;
      }

      let start = moment(event.startTime);
      let end = moment(event.endTime);

      this.registrationData = {
        id: event.id,
        scheduleType: event.scheduleType,
        hospital: event.hospitalId,
        facility: event.facilityId,
        isTitleSecret: event.isTitleSecret,
        startDate: start.format('yyyy-MM-DD'),
        startTime: start.format('HH:mm'),
        endDate: end.format('yyyy-MM-DD'),
        endTime: end.format('HH:mm'),
        title: event.title,
        isNotificationOn: event.isNotificationOn,
        latestUpdaterId: event.latestUpdaterId,
        workers: event.holders?.map(id => this.allianceUsers.find(u => u.id == id)) ?? [],
      };

      this.isRegistrationModalOpened = true;
    },
    loadSchedules: function(){
      this.axios.get(`/api/schedules/month/${this.calendarTitle.split('/').join('-')}`).then(response => {
        const schedules = [...this.updatingSchedules];
        this.schedules = [
          ...schedules, 
          ...response.data.schedules.filter(s => !schedules.some(_s => _s.id == s.id))
        ];
      }).catch(error => {
        console.log(error);
      })
    },
    createNewSchedule: function(){
      let now = moment();
      let _id = getRandomStr(16);
      while(this.schedules.some(s => s._id === _id)){
        _id = getRandomStr(16);
      }
      this.schedules.push({
        ...this.registrationData,
        id: null,
        userId: this.user.id,
        hospitalId: this.user.hospital.length==1 ? this.user.hospital[0].id : null,
        startTime: now.format('yyyy-MM-DD HH:mm:ss'),
        endTime: now.format('yyyy-MM-DD HH:mm:ss'),
        isEdited: true,
        _id: _id,
      });

      this.currentTabIndex = this.tabs.findIndex(t => t.value === 'new');
    },
    duplicateSchedule: function(row){
      let _id = getRandomStr(16);
      while(this.schedules.some(s => s._id === _id)){
        _id = getRandomStr(16);
      }

      this.schedules.push({
        ...this.schedules.find(s => s.id === row.id),
        isEdited: true,
        id: null,
        _id: _id,
      });

      this.currentTabIndex = this.tabs.findIndex(t => t.value === 'new');
    },
    setScheduleEdited: function(row){
      let schedule = this.schedules.find(s => s.id === row.id);
      if(!schedule){
        return;
      }
      this.$set(schedule, 'isEdited', true)
    },
    switchScheduleDeleted: function(row){
      let schedule = this.schedules.find(s => s.id === row.id);
      if(!schedule){
        return;
      }
      this.$set(schedule, 'isDeleted', !schedule.isDeleted);
    },
    setRegistrationDialogClosability: function(closable){
      setTimeout(() => {
        this.isWorkerInputOpened = !closable;
      }, closable ? 500 : 0);
    },
    isValidSchedule: function(schedule){
      if(!schedule.scheduleType){
        return false;
      }

      if(schedule.scheduleType === "facility"){
        if(schedule.hospitalId == null){
          return false;
        }

        if(schedule.facilityId == null){
          return false;
        }

        let facility = this.facilityItems.find(i => i.id == schedule.facilityId);
        if(!facility){
          return false;
        }

        if(facility.isTitleNecessary && (!schedule.title || schedule.title.length == 0)){
          return false;
        }
      }

      if(schedule.scheduleType === "individual"){
        if(!schedule.title || schedule.title.length == 0){
          return false;
        }
      }
      
      if(!schedule.participants?.length){
        return false;
      }

      return true;
    },
    saveSchedules: function(){
      if(this.invalidSchedules.length > 0){
        this.currentTabIndex = this.tabs.findIndex(t => t.value === 'invalid');
        return;
      }

      if(this.updatingSchedules == 0){
        return;
      }

      this.axios.put(`/api/schedules`, {
        deleted: this.deletedSchedules,
        new: this.newSchedules,
        edited: this.editingSchedules,
      }).then(() => {
        this.schedules = [];
        this.loadSchedules();
      }).catch(error => {
        console.log(error);
      })
    },
    updateSurgeriesTitle: function(schedule, newVal){
      schedule.title = newVal.join(', ');
    },
    calculateEndTimeBySurgeries: function(schedule){
      let surgeries = schedule.title.split(',').map(s => s.trim());
      let sumOfMinues = surgeries.reduce((a, c) => {
        let surgery = this.surgeries.find(s => s.name === c);
        return a + (surgery?.minutes ?? 0);
      }, 0);

      schedule.endTime = moment(schedule.startTime).add(sumOfMinues, 'minutes').format('yyyy-MM-DD HH:mm:ss');
    },
    calculateEndDateTimeBySurgeries: function(schedule){
      let surgeries = schedule.title.split(',').map(s => s.trim());
      let sumOfMinues = surgeries.reduce((a, c) => {
        let surgery = this.surgeries.find(s => s.name === c);
        return a + (surgery?.minutes ?? 0);
      }, 0);

      let end = moment(`${schedule.startDate} ${schedule.startTime}`).add(sumOfMinues, 'minutes');
      schedule.endDate = end.format('yyyy-MM-DD');
      schedule.endTime = end.format('HH:mm');
    },
    downloadXlsx: function(){
      this.axios.post(
        '/api/download/schedules/xlsx',
        {
          schedules: this.events.map(e => ({
            ...e,
            hospitalName: this.getHospitalShortName(e.hospitalId),
            facilityName: this.getFacilityName(e.facilityId),
          })),
        },
        {
          responseType: "blob"
        }
      ).then(response => {
        const blob = new Blob([response.data], {
          type: response.data.type
        });
        let months = Array.from(new Set(this.events.map(e => e.start.slice(0, 7)))); 

        months.sort()
        let fileTitle = 'スケジュール_該当データなし'
        if(months.length == 1){
          fileTitle = `スケジュール_${months[0].split('-').join('年')}月`
        }

        if(months.length > 1){
          fileTitle = `スケジュール_${months[0].split('-').join('年')}月-${months[months.length - 1].split('-').join('年')}月`
        }

        let fileName = `${fileTitle}.xlsx`;
        saveAs(blob, fileName);
      });
    },
    checkAllWorkers: function() {
      this.registrationData.workers = this.allianceUsers;
    },
    uncheckAllWorkers: function() {
      this.registrationData.workers = [];
    },
  }
}
</script>

<style lang="scss" scoped>
.schedule_table{
  ::v-deep td{
    padding: 0 4px !important;
  }

  ::v-deep .relative{
    position: relative;
  }
}

</style>