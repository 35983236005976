<template>
  <v-dialog
    ref="dialog"
    v-model="isModalOpened"
    :return-value.sync="time"
    width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="time"
        :label="label"
        prepend-icon="mdi-clock-time-four-outline"
        readonly
        v-bind="attrs"
        v-on="on"
        :dense="dense != null"
        :hide-details="hideDetails != null"
      ></v-text-field>
    </template>
    <v-time-picker
      v-model="time"
      full-width
      :allowed-minutes="allowedStep"
      format="24hr"
    >
      <v-spacer></v-spacer>
      <v-btn
        text
        color="red"
        @click="isModalOpened=false"
      >
        Cancel
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="$refs.dialog.save(time)"
      >
        OK
      </v-btn>
    </v-time-picker>
  </v-dialog>
</template>

<script>

export default {
  components: {
	},
  props: {
    value: {},
    label: {},
    minutesStep: {
      default: 5,
    },
    dense: {
      default: null,
    },
    hideDetails: {
      default: null,
    },
  },
  data: function(){
    return {
      isModalOpened: false,
    }
  },
  computed: {
    time: {
      get() {
        return this.value;
      },
      set(newVal) {
        if (this.value !== newVal){
          this.$emit('input', newVal);
        }
      }
    }
  },
  watch: {
  },
  mounted: function(){
  },
  methods: {
    allowedStep: function(m){
      return m % this.minutesStep === 0;
    } 
  }
}
</script>