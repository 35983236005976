<template>
  <v-container fluid>
    <v-row align="center" justify="center" dense>
      <v-col md="6">
        <v-tabs
          v-model="currentTabIndex"
          dense
        >
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab
            v-for="tab in tabs"
            :key="tab.value"
          >
            {{ tab.label }}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-form ref="form" lazy-validation v-if="currentTab.value === 'basic'">
      <v-row align="center" justify="center" dense>
        <v-col md="6">
          <v-text-field 
            dense 
            class="mt-5" 
            v-model="accountData.mailAddress" 
            label="メールアドレス"
            :rules="[required, emailValidation]"
          />
        </v-col>
      </v-row>
      <v-row align="center" justify="center" dense>
        <v-col md="3">
          <v-text-field v-model="accountData.familyName" label="氏" :rules="[required]"/>
        </v-col>
        <v-col md="3">
          <v-text-field v-model="accountData.firstName" label="名" :rules="[required]"/>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" dense>
        <v-col md="6">
          <v-text-field
            dense
            v-model="accountData.telephoneNumber"
            label="電話番号"
            :rules="[required]"
          />
        </v-col>
      </v-row>
      <v-row align="center" justify="center" dense>
        <v-col md="6">
          <v-radio-group
            v-model="accountData.isAlliance"
            row
          >
            <v-radio
              label="アライアンス内"
              :value="true"
            ></v-radio>
            <v-radio
              label="アライアンス外"
              :value="false"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <template v-if="accountData.isAlliance">
        <v-row align="center" justify="center" dense>
          <v-col md="6">
            <v-select
              v-model="accountData.hospital"
              dense
              label="所属施設名"
              class="text-no-wrap"
              :items="hospitalItems"
              item-text="name"
              item-value="id"
              :rules="[required]"
              multiple
            ></v-select>
          </v-col>
        </v-row>
        <v-row align="center" justify="center" dense v-if="accountData.hospital && accountData.hospital.length > 0">
          <v-col md="6">
            <v-select
              v-for="hospital in accountData.hospital"
              :key="hospital"
              v-model="accountData.department[hospital]"
              :label="`${getHopsitalShortName(hospital)} 所属部署`"
              class="text-no-wrap"
              :items="departmentItems.filter(i => i.hospitalId == hospital)"
              item-text="name"
              item-value="id"
              :rules="[required]"
            ></v-select>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <v-row align="center" justify="center" dense>
          <v-col md="6">
            <v-text-field v-model="accountData.hospital" label="所属施設名" :rules="[required]"/>
          </v-col>
        </v-row>
        <v-row align="center" justify="center" dense>
          <v-col md="6">
            <v-text-field v-model="accountData.department" label="所属部署" :rules="[required]"/>
          </v-col>
        </v-row>
      </template>
      <v-row align="center" justify="center" dense>
        <v-col md="6">
          <v-text-field 
            dense 
            class="mt-5" 
            v-model="accountData.note" 
            label="備考"
          />
        </v-col>
      </v-row>
      <v-row align="center" justify="center" dense>
        <v-btn 
          color="primary" 
          @click.stop="updateAccount"
          :disabled="!isValid"
        >更新依頼</v-btn>
      </v-row>
    </v-form>
    <v-form ref="form" lazy-validation v-if="currentTab.value === 'password'">
      <v-row align="center" justify="center">
        <v-col md="6">
          <v-text-field 
            dense 
            type="password" 
            v-model="accountData.password" 
            label="現在のパスワード" 
            :rules="[required, passwordValidation]"
          />
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col md="6">
          <v-text-field 
            dense 
            type="password" 
            v-model="accountData.newPassword" 
            label="新しいパスワード" 
            :rules="[required, passwordValidation]"
          />
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col md="6">
          <v-text-field 
            dense 
            type="password" 
            v-model="accountData.passwordForConfirmation" 
            label="新しいパスワード（確認用）" 
            :rules="[required, passwordForConfirmationValidation]"
          />
        </v-col>
      </v-row>
      <v-row align="center" justify="center" dense>
        <v-btn 
          color="primary" 
          @click.stop="updateAccountPassword"
          :disabled="!isValid"
        >更新</v-btn>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  components: {
	},
  data: () => ({
    accountData: {
      mailAddress: null,
      password: null,
      newPassword: null,
      passwordForConfirmation: null,
      familyName: null,
      firstName: null,
      hospital: null,
      department: {},
      isAlliance: null,
      telephoneNumber: null,
      note: null,
    },
    currentTabIndex: 0,
    tabs: [
      {value: 'basic', label: '基本情報 (要承認)'},
      {value: 'password', label: 'パスワード'},
    ],

    required: value => !!value || "※必須",
    emailValidation: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || '※メールアドレスが正しくありません',
    invalidDomainValidation: v => !v || !v.endsWith('@gmail.com') || '※フリーメールは登録できません',
    passwordValidation: v => !v || /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d]{8,100}$/.test(v) || '※半角英小文字・大文字・数字それぞれ1文字以上含む8文字以上',

    isValid: false,
    isInitial: true
  }),
  computed: {
    ...mapState([
      'user',
      'hospitalItems',
      'departmentItems',
    ]),
    getHopsitalShortName: function(){
      return (id) => this.hospitalItems.find(i => i.id === id)?.shortName
    },
    currentTab: function(){
      return this.tabs[this.currentTabIndex];
    },
    passwordForConfirmationValidation: function(){
      return (value) => this.accountData.newPassword === value || '※パスワードが一致しません';
    },
  },
  watch: {
    accountData: {
      handler: function(){
        this.$nextTick(() => {
          this.isValid = this.$refs.form?.validate();
        });
      },
      deep: true,
    },
    'accountData.isAlliance': function(newVal){
      if(!this.isInitial) {
        this.accountData = {
          ...this.accountData,
          hospital: null,
          department: newVal ? {} : null,
        }
      }

      this.$nextTick(() => {
        this.isValid = this.$refs.form?.validate();
        this.isInitial = false;
      });
    },
    'accountData.hospital': function(){
      this.$nextTick(() => {
        this.isValid = this.$refs.form?.validate();
      });
    },
    currentTab: function(){
      this.$nextTick(() => {
        this.isValid = this.$refs.form?.validate();
      });
    },
  },
  mounted: function(){
    this.getAccount();
    this.$nextTick(() => {
      this.isValid = this.$refs.form.validate();
    });
  },
  methods: {
    ...mapActions([
    ]),
    getAccount: function() {
      let department = {};

      if(this.user.isAlliance){
        this.user.department.forEach(d => {
          department[d.hospitalId] = d.id;
        });
      }else{
        department = this.user.department.map(d => d.name).join(', ');
      }

      this.accountData = {
        ...this.accountData,
        mailAddress: this.user.mailAddress,
        familyName: this.user.familyName,
        firstName: this.user.firstName,
        isAlliance: this.user.isAlliance,
        hospital: this.user.isAlliance ? this.user.hospital.map(h => h.id) : this.user.hospital.map(h => h.name).join(', '),
        department: department,
        telephoneNumber: this.user.telephoneNumber,
        note: this.user.note,
      }
    },
    updateAccount: function(){
      this.axios.put('/api/account_request', this.accountData).then(response => {
        if(response.data.result === 'ok'){
          alert('ユーザ情報の変更を依頼いたしました。完了しましたらメールにてご連絡します。');
        }
      }).catch(error => {
        console.log(error);
        alert('保存に失敗しました。暫くたっても同様のエラーが出る場合、管理者にお問い合わせください。');
      });
    },
    updateAccountPassword: function(){
      let passwordRecord = {
        oldPassword: this.accountData.password,
        newPassword: this.accountData.newPassword,
      };
      this.axios.put('/api/user_password', passwordRecord).then(response => {
        if(response.data.result === 'ok'){
          alert('パスワードを変更いたしました。');
          this.accountData.password = null;
          this.accountData.newPassword = null;
          this.accountData.passwordForConfirmation = null;
        } else if(response.data.result === 'mismatch password'){
          alert('現在のパスワードが間違えているため、変更できません。');
        }
      }).catch(error => {
        console.log(error);
        alert('保存に失敗しました。暫くたっても同様のエラーが出る場合、管理者にお問い合わせください。');
      });
    },
  }
}
</script>

<style scoped>

</style>