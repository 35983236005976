<template>
  <v-dialog
    ref="dialog"
    v-model="isModalOpened"
    :return-value.sync="categoryId"
    width="480px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        dense
        hide-details
        :value="categoryName"
        :label="label"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-container
      fluid
      class="white"
    > 
      <v-row class="header secondary">
        <v-col cols="3" class="text-caption pa-3">
          <v-combobox
            dark
            dense
            hide-details
            v-model="filterParams['mainCategoryName']"
            :items="mainCategoryNames"
          />
        </v-col>
        <v-col cols="9" class="text-caption pa-3">
          <v-text-field
            dark
            dense
            hide-details
            v-model="filterParams['subCategoryName']"
          />
        </v-col>
      </v-row>
      <v-row
        v-for="item in filteredCategories"
        :key="item.id"
        class="category-row"
        @click="selectCategory(item)"
      > 
        <v-col cols="3" class="text-caption pa-3">
          {{ item.mainCategoryName }}
        </v-col>
        <v-col cols="9" class="text-caption pa-3 d-flex align-center">
          {{ item.caption }}
          <v-tooltip 
            top
            color="secondary"
            v-if="item.description && item.description.length > 0"
          >
            <template v-slot:activator="{ on, attrs }">
              <div @click.stop class="ml-auto">
                <v-icon 
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-information
                </v-icon>
              </div>
            </template>
            <div class="text-h6" >
              {{ item.description }}
            </div>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-container>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  components: {
	},
  props: {
    value: {},
    categories: {
      default: () => [],
    },
    label: {
      default: 'カテゴリ'
    },
    minutesStep: {
      default: 5,
    }
  },
  data: function(){
    return {
      filterParams: {},
      isModalOpened: false,
    }
  },
  computed: {
    ...mapState([
      'taskCategoryItems',
    ]),
    validCategories: function(){
      return this.categories.map(c => ({
        ...c,
        mainCategoryName: this.taskCategoryItems.find(_c => _c.id == c.parent)?.caption,
      }))
    },
    filteredCategories: function(){
      return this.validCategories.filter(c => {
        let rtn = true;
        if(this.filterParams['mainCategoryName']?.length > 0){
          rtn &= c.mainCategoryName?.indexOf(this.filterParams['mainCategoryName']) >= 0;
        }
        if(this.filterParams['subCategoryName']?.length > 0){
          rtn &= c.caption?.indexOf(this.filterParams['subCategoryName']) >= 0;
        }
        return rtn;
      })
    },
    categoryId: {
      get() {
        return this.value;
      },
      set(newVal) {
        if (this.value !== newVal){
          this.$emit('input', newVal);
        }
      }
    },
    categoryName: function(){
      let selectedCategory = this.validCategories.find(c => c.id == this.categoryId);

      return selectedCategory 
        ? `${selectedCategory.mainCategoryName} ${selectedCategory.caption}`
        : null;
    },
    mainCategoryNames: function(){
      return this.validCategories.map(c => c.mainCategoryName);
    },
    subCategoryNames: function(){
      return this.validCategories.map(c => c.caption);
    },
  },
  watch: {
  },
  mounted: function(){
  },
  methods: {
    selectCategory: function(category){
      this.$refs.dialog.save(category.id);
    },
  }
}
</script>

<style lang="scss" scoped>
.header{
  position: sticky;
  top: 0;
  z-index: 1;
}

.category-row{
  border-bottom: lightgrey 1px solid;
  cursor: pointer;
  &:hover{
    background: lightgrey;
  }
}
</style>