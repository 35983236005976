<template>
  <v-app dark v-if="isAuthorized">
    <v-navigation-drawer 
      app 
      color="secondary"
      dark
      v-model="isSideMenuOpened"
    >
      <v-list-item>
        <v-list-item-title></v-list-item-title>
        <v-btn icon @click.stop="isSideMenuOpened=false">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>
      <v-divider />
      <v-list nav v-if="user">
        <template v-for="menu in validMenus">
          <v-list-item 
            :key="menu.title" 
            :to="menu.url"
          >
            <v-list-item-icon v-if="menu.icon">
              <v-icon>{{ menu.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ menu.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>

      <template v-slot:append>
        <v-dialog
          v-if="isAlliance"
          v-model="isAllianceUsersModalOpened"
        >
          <template v-slot:activator="{ on, attrs }">
            <div class="pa-2">
              <v-btn 
                to="/account" 
                block
                v-bind="attrs"
                v-on="on"
              >
                医師リスト
              </v-btn>
            </div>
          </template>
          <v-card>
            <v-data-table
              :items="allianceUsers"
              :headers="allianceUsersHeaders"
            >
              <template v-slot:body.prepend>
                <tr>
                  <td v-for="header in allianceUsersHeaders" :key="header.value">
                    <template v-if="header.filterable">
                      <v-text-field
                        dense
                        v-model="filterParamsForUsers[header.value]"
                        :hint="header.text"
                        single-line
                        persistent-hint
                      ></v-text-field>
                    </template>
                  </td>
                </tr>
              </template>
              <template v-slot:item.hospitalIds="props">
                {{ getHospitalShortnames(props.item.hospitalIds) }}
              </template>
              <template v-slot:item.telephoneNumber="props">
                <a :href="`tel:${props.item.telephoneNumber}`">{{ props.item.telephoneNumber }}</a>
              </template>
            </v-data-table>
          </v-card>
        </v-dialog>

        <div class="pa-2">
          <v-btn to="/account" block>登録情報の変更</v-btn>
        </div>
        <div class="pa-2">
          <v-btn block @click.stop="logout">ログアウト</v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar
      app
      dark
      dense
    >
      <v-app-bar-nav-icon 
        :disabled="!user"
        @click.stop="isSideMenuOpened = !isSideMenuOpened"
      />
      <v-toolbar-title class="pl-1">{{ pageTitle }}</v-toolbar-title>
    </v-app-bar>

    <v-main>
      <router-view ref="page" v-if="user"/>
      <Login v-else/>
    </v-main>

    <v-footer app>
    </v-footer>

    <v-dialog
      ref="dialog"
      v-model="isFirstLoggedInModalOpened"
      persistent
      width="500"
    >
      <v-card>
        <v-card-title class="text-h6">
          Heart Alliance Appの登録ありがとうございます
        </v-card-title>
        <v-card-text>
          本サイトを利用頂くにあたり、「ホーム画面への追加」を推奨いたします。<br>
          方法は以下を参照ください。
          <v-row justify="center" class="mt-5" dense>
            <v-btn
              class="text-decoration-underline mt-1"
              depressed
              color="white"
              href="https://www.cmrc.co.jp/2013/11/2968.html"
            >
              > iPhone (Safariのみ)
            </v-btn>
          </v-row>
          <v-row justify="center" dense>
            <v-btn
              class="text-decoration-underline mt-1"
              depressed
              color="white"
              href="https://www.cmrc.co.jp/2013/11/2968.html"
            >
              > Android
            </v-btn>
          </v-row>

        </v-card-text>
        <v-card-actions class="text-center">
          <v-spacer/>
          <v-btn
            color="primary mb-3"
            @click="isFirstLoggedInModalOpened = false"
          >
            閉じる
          </v-btn>
          <v-spacer/>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import Login from '@pages/Login.vue';
import isMobile from 'ismobilejs'

export default {
  name: 'App',
  components: {
    Login
  },
  data: function(){
    return {
      isSideMenuOpened: false,
      pageTitle: '',
      siteName: 'Heart Alliance App',
      isAuthorized: false,
      isFirstLoggedInModalOpened: false,
      allianceUsersHeaders: [
        {
          text: '所属施設', 
          value: 'hospitalIds',
          sortable: false,
          filterable: true,
          filter: value => !this.filterParamsForUsers['hospitalIds']?.length || this.getHospitalShortnames(value).indexOf(this.filterParamsForUsers['hospitalIds']) >= 0,
        },
        {
          text: '名前', 
          value: 'fullName',
          filterable: true,
          filter: value => !this.filterParamsForUsers['fullName']?.length || value.indexOf(this.filterParamsForUsers['fullName']) >= 0,
        },
        {
          text: '電話番号', 
          value: 'telephoneNumber',
          filterable: true,
          filter: value => !this.filterParamsForUsers['telephoneNumber']?.length || value?.indexOf(this.filterParamsForUsers['telephoneNumber']) >= 0,
        },
      ],
      isAllianceUsersModalOpened: false,
      filterParamsForUsers: {},
    }
  },
  computed: {
    ...mapState([
      'user',
      'allianceUsers',
      'hospitalItems',
    ]),
    ...mapGetters([
      'validMenus',
    ]),
    isAlliance: function(){
      return this.user?.isAlliance;
    },
    getHospitalShortnames: function(){
      return (ids) => {
        return ids.map(id => {
          let hospital = this.hospitalItems.find(h => h.id === id);
          return hospital?.shortName ? hospital.shortName : hospital?.name;
        }).join(', ');
      };
    },
  },
  watch:{
    '$route':{
      handler: function(to) {
        if(to?.meta?.title){
          this.pageTitle = to.meta.title;
        } else {
          this.pageTitle = this.siteName
        }
      },
      immediate: true,
    },
    user: {
      handler: function(newVal, oldVal){
        if(!newVal){
          this.pageTitle = this.siteName;
        }else{
          this.pageTitle = this.$route?.meta?.title ?? this.siteName;

          if(!oldVal){
            this.axios.get('/api/bed_status_items').then(response => {
              this.setBedsStatusItems(response.data.items);
              if(isMobile().any){
                if(!this.$cookies.get("hasLoggedIn")){
                  this.isFirstLoggedInModalOpened = true;
                }
                this.$cookies.set("hasLoggedIn", true);
              }
            }).catch(error => {
              console.log(error);
            });

            if(newVal.isAlliance){
              this.axios.get(`/api/users/alliance`).then(response => {
                this.setAllianceUsers(response.data.users);
              }).catch(error => {
                console.log(error);
              });
            }

            this.axios.get('/api/working_categories').then(response => {
              this.setCategoryItems(response.data.categories);
              this.setSubCategoryItems(response.data.subCategories);
              this.setSubSubCategoryItems(response.data.subSubCategories);
            }).catch(error => {
              console.log(error);
            });
          }
        }
      },
      immediate: true,
    },
    isAuthorized: {
      handler: function(newVal){
        if(newVal){
          this.axios.get('/api/hospitals').then(response => {
            this.setHospitalItems(response.data.hospitals);
          }).catch(error => {
            console.log(error);
          })

          this.axios.get('/api/departments').then(response => {
            this.setDepartmentItems(response.data.departments);
          }).catch(error => {
            console.log(error);
          })
        }
      },
      immediate: true,
    }
  },
  mounted() {
//    this.$cookies.remove("hasLoggedIn")
    let accessToken = this.$cookies.get('token');
    if(accessToken){
      this.axios.defaults.headers.common['Authorization'] = "Bearer " + accessToken;
      this.axios.get('/api/user').then(response => {
        this.setUser({
          ...response.data.user,
          role: response.data.role,
          hospital: response.data.hospital,
          department: response.data.department,
        })

        this.axios.get('/api/office_workings').then(response => {
          this.setOfficeWorkings(response.data.officeWorkings);
        }).catch(error => {
          console.log(error);
        })

        this.axios.get('/api/working_arrangements').then(response => {
          this.setWorkingArrangements(response.data.workingArrangements);
        }).catch(error => {
          console.log(error);
        })
      }).catch(error => {
        console.log(error);
        this.$cookies.remove('token');
      });
    }

    if(process.env.NODE_ENV == 'production' || process.env.NODE_ENV == 'localhost') {
      this.isAuthorized = true;
      return;
    }

    this.isAuthorized = this.$cookies.get('isDevAuthed');
    if(this.isAuthorized){
      return;
    }

    let password = prompt("パスワード");
    this.axios.post('/api/dev_auth', {name: 'dev', password: password}).then(response => {
      this.isAuthorized = response.data.isAuthed;
      if(this.isAuthorized){
        this.$cookies.set('isDevAuthed', this.isAuthorized);
      }
    }).catch(error => {
      console.log(error);
    })
  },
  methods: {
    ...mapActions([
      'setUser',
      'setAllianceUsers',
      'setHospitalItems',
      'setBedsStatusItems',
      'setDepartmentItems',
      'setCategoryItems',
      'setSubCategoryItems',
      'setSubSubCategoryItems',
      'setOfficeWorkings',
      'setWorkingArrangements',
    ]),
    logout: function(){
      this.setUser(null);
      this.$cookies.remove('token');
    },
  }
};
</script>

<style lang="scss">
@import "@style/global.scss";
</style>