export function getDateStr(date) {
    return (
      date.getFullYear() +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date.getDate()).slice(-2)
    );
  }
  
export function getDatetimeStr(date) {
  return (
    date.getFullYear() +
    "/" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "/" +
    ("0" + date.getDate()).slice(-2) +
    " " +
    ("0" + (date.getHours())).slice(-2) +
    ":" +
    ("0" + (date.getMinutes())).slice(-2)
  );
}

export function getTimeStr(date) {
  return (
    "0" + date.getHours()).slice(-2) + 
    ":" + 
    ("0" + date.getMinutes()).slice(-2);
}

export function getRandomStr(length) {
  const CHARS = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let rtn = [];
  for(let i=0; i<length; i++){
    rtn += CHARS[Math.floor(Math.random()*CHARS.length)];
  }
  return rtn;
}